import {api} from './configs/axiosConfigs'
import {defineCancelApiObject} from './configs/axiosUtils'

export const AuthAPI = {
    login: async function (login, password,cancel = false) {
        const response = await api.request({
            url: '/auth/login',
            data: {login: login, password: password},
            method: 'POST',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(AuthAPI)