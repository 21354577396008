import React from 'react'
import {Table, TableCaption, TableContainer, Tbody, Td, Thead, Tr, Th} from '@chakra-ui/react'
import CategoryRowModal from '../CategoryRowModal/CategoryRowModal'
import CategoryRowDelete from './CategoryRowDelete'

const CategoryRowsTable = (props) => {
    const {categoryRows, countingId, fetchCategoryRows} = props

    const categoryByRow = (row) => categoryRows.find(c => c.row_from <= row && c.row_to >= row)
    return (
        <TableContainer>
            <Table variant='striped'>
                <TableCaption>List of the rows categories</TableCaption>
                <Thead>
                    <Tr>
                        <Th> Name </Th>
                        <Th> From </Th>
                        <Th> To </Th>
                        <Th> Count </Th>
                        <Th> Completed </Th>
                        <Th> Actions </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {categoryRows.map(cat => (
                        <Tr key={cat._id}>
                            <Td> {cat.name} </Td>
                            <Td> {cat.row_from} </Td>
                            <Td> {cat.row_to} </Td>
                            <Td> {cat.row_to-cat.row_from+1} </Td>
                            <Td> {cat.rowsCount} </Td>
                            <Td>
                                <CategoryRowModal
                                    fetchCategoryRows={fetchCategoryRows}
                                    id={cat._id}
                                    name={cat.name}
                                    rowFrom={cat.row_from}
                                    rowTo={cat.row_to}
                                    countingId={countingId}
                                    mode={'update'}
                                    categoriesWithRowsInARange={props.categoriesWithRowsInARange}
                                
                                />
                                <CategoryRowDelete
                                    id={cat._id}
                                    fetchCategoryRows={fetchCategoryRows}
                                    countingId={countingId}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default CategoryRowsTable