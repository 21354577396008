import { Card, CardBody, Table, TableCaption, TableContainer, Thead, Tbody, Th, Tr, Td, Input, Stack, Button } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useRowsCounted } from '../../../hooks/useRows/useRowsCounted'
import Pagination from '../../Pagination/Pagination'
import { Captions } from '../Captions'
import { useState } from 'react'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { api } from 'apis/configs/axiosConfigs'


const Filters = ({filtersConfig = [], filters, setFilters }) => { 
    
    return (
        <Tr>
            { filtersConfig.map((filter, idx) => filter.omit ? <Th key={idx}></Th> :
                <Th key={idx}>
                    <Input  
                        id={`filter_${filter.type}`} 
                        placeholder={filter.placeholder} 
                        onChange={(e) => setFilters({...filters, [filter.type]: e.target.value || undefined })} 
                        value={filters ? (filters[filter.type] ?? '') : null}
                        type={filter.type.startsWith('date') ? 'date' : 'text'}
                    />
                </Th>
            )}
                <Th></Th>
        </Tr>
    )
}


export const PlanActual = (props) => {
    const { countingId } = useParams() 
    const [ isFileLoading, setFileLoading ] = useState(false)
    const { rows, totalRows, totalAmount, totalTested, filters, setFilters, handlePageChange } = useRowsCounted({ counting_id: countingId })
    
    {/* מנה צפי כמות שנספרה הפרש סופר מסופון מיקום בחנות */}
        {/* <Th>מנה</Th> */}
        {/* <Th>צפי</Th> */}
        {/* <Th>כמות שנספרה</Th> */}
        {/* <Th>הפרש</Th> */}
        {/* <Th>סופר</Th> */}
        {/* <Th>מסופון</Th> */}
        {/* <Th>מיקום בחנות</Th> */}
    const headerRow = ['Row', 'Estimated', 'Amount', 'Gap', 'User', 'Device', 'Place'] 
    const formatRow = ({name, amount_estimate, user, user_id, device_number, category_row, amount}) => {
        return [
            name,
            amount_estimate,
            rows.countedItems[`${name}_${user_id}_${device_number}`] ?? amount,
            (rows.countedItems[`${name}_${user_id}_${device_number}`] ?? amount) - amount_estimate,
            `${user?.name} ${user?.last_name}`,
            device_number, 
            category_row?.name
        ]
    }

    const downloadFile = async () => {
        setFileLoading(true)
        
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Data')
        worksheet.addRow(headerRow)

        const rows = await fetchRows(filters)
        rows.data.forEach(row => {worksheet.addRow(formatRow(row))})
        worksheet.views = [{state: 'frozen', xSplit: 0, ySplit: 1}]
        workbook.xlsx.writeBuffer().then(buffer => 
            saveAs( new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }), 'plan_actual.xlsx')
        )

        setFileLoading(false)
    }

    const fetchRows = async (filters) => {
        const response = await api.request({
            url: '/rows/all',
            method: 'GET',
            params: filters
        })

        return response.data
    }
       

    //const { items, requestSort, sortConfig } = useSortableData(props.items)
    return (
        <Card>
            <CardBody>
                <Stack direction='row' spacing='4' align='center' justify='end'>
                    <Button onClick={downloadFile}
                        size='sm' colorScheme='teal' mr='1'
                        isLoading={isFileLoading} loadingText='Downloading...'
                        >
                            Export
                        </Button>
                </Stack>
                <TableContainer>
                    <Table variant='striped'>
                        <TableCaption placement={'top'}>
                            <Captions
                                amount={totalAmount}
                                total={totalRows}
                            />
                        </TableCaption>
                        <Thead>
                            <Filters filtersConfig={[
                                    { type: 'name', placeholder: 'Name'},
                                    { type: 'amount_estimate', placeholder: 'Estimate amount'},
                                    { type: 'amount', placeholder: 'Amount', omit: true},
                                    { type: 'gap', placeholder: 'Gap', omit: true},
                                    { type: 'user', placeholder: 'User'},
                                    { type: 'device_number', placeholder: 'Device'},
                                    { type: 'category_row', placeholder: 'Category'}
                                ]}
                                filters={filters} setFilters={setFilters}
                            />
                            <Tr>{headerRow.map((item, idx) => <Th key={idx}>{item}</Th>)}</Tr>
                        </Thead>
                        <Tbody>
                            {rows?.data?.map(item => (
                                <Tr key={item._id}>
                                    {formatRow(item).map((cell, idx) => <Td key={idx}>{cell}</Td>)}
                                </Tr>
                            ))}
                           
                        </Tbody>
                    </Table>
                    <Pagination lastPage={rows.last_page ?? 1} getData={handlePageChange} />
                </TableContainer>
            </CardBody>
        </Card>)
}