import {Button} from '@chakra-ui/react'
import React from 'react'

export const WorkingHourDeleteButton = (props) => {
    const {id, handleDeleteWorkingHour} = props
    return (
        <>
            <Button onClick={()=>{handleDeleteWorkingHour(id)}} size={'sm'} colorScheme={'red'}>Delete</Button>
        </>
    )
}