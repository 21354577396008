import {Td, Tr} from '@chakra-ui/react'
import {DateYMD} from '../../../Date/DateYMD'
import {DateHMS} from '../../../Date/DateHMS'

export const RowsEmptyRow = (props) => {
    const {name, date, userFirstName, userLastName, deviceNumber} = props
    return (
        <Tr>
            <Td>
                {name}
            </Td>
            <Td>
                0
            </Td>
            <Td>
                {userFirstName} {userLastName}
            </Td>
            <Td>
                {deviceNumber}
            </Td>
            <Td>
                <DateHMS date={date}/>
            </Td>
            <Td>
                <DateYMD date={date}/>
            </Td>
        </Tr>
    )
}