import { useEffect } from 'react'
import {Checkbox, Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr, Input} from '@chakra-ui/react'
import ItemsInvalidTableRow from './ItemsInvalidCheckInCatalog/ItemsInvalidTableRow'
import {ItemsTableCaption} from '../../ItemsTableCaption/ItemsTableCaption'
import {ItemsInvalidCheckInCatalog} from '../ItemsInvalidCheckInCatalog'
import {ItemsInvalidDelete} from './ItemsInvalidDelete/ItemsInvalidDelete'
//import { Filters } from 'components/Filters'

const Filters = ({filtersConfig = [], filters, setFilters }) => { 
    
    return (
        <Tr>
            <Th></Th>
            { filtersConfig.map((filter, idx) =>
                <Th key={idx}>
                    <Input  
                        id={`filter_${filter.type}`} 
                        placeholder={filter.placeholder} 
                        onChange={(e) => setFilters({...filters, [filter.type]: e.target.value || undefined })} 
                        value={filters ? (filters[filter.type] ?? '') : null}
                        type={filter.type.startsWith('date') ? 'date' : 'text'}
                    />
                </Th>
            )}
        </Tr>
    )
}

export const ItemsInvalidTable = (props) => {
    const {itemsInvalid, itemsInvalidCount, itemsInvalidSumAmount, itemsInvalidRows, onCheckboxChange,
        checkAllSelected, isCheckingAll, handleSelectAll, selectAll, itemsSelected, refreshTable, isDeletingAll,
        deleteAllSelected, refreshCounting} = props

    return (
        <TableContainer>
            <Table>
                <TableCaption placement={'top'}>
                    <ItemsTableCaption
                        totalAmount={itemsInvalidSumAmount}
                        total={itemsInvalidCount}
                        totalRows={itemsInvalidRows.length}
                    />
                </TableCaption>
                <Thead>
                    
                    <Filters filtersConfig={[
                                { type: 'upc', placeholder: 'UPC' },
                                { type: 'mkt_1', placeholder: 'MKT' },
                                { type: 'row_name', placeholder: 'Row' },
                                { type: 'amount', placeholder: 'Amount'},
                                { type: 'device_number', placeholder: 'Device' },
                                { type: 'user', placeholder: 'Name' },
                            ]} 
                            filters={props.filters} setFilters={ props.setFilters }/>
                    <Tr>
                        <Th>
                            <Checkbox border={'1px'} isChecked={selectAll} onChange={handleSelectAll} />
                        </Th>
                        <Th>UPC</Th><Th>MKT</Th><Th>Row</Th><Th>Amount</Th><Th>Device</Th><Th>Name</Th>
                        <Th>
                            <ItemsInvalidCheckInCatalog isChecking={isCheckingAll} checkAllSelected={checkAllSelected} />
                            <ItemsInvalidDelete isDeleting={isDeletingAll} deleteAllSelected={deleteAllSelected} />
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {itemsInvalid.map(item => (
                        <ItemsInvalidTableRow
                            key={item._id}
                            id={item._id}
                            upc={item.upc}
                            device={item.device_number}
                            row={item.row_name}
                            mkt={item.mkt_1}
                            amount={item.amount}
                            name={`${item?.user.name} ${item?.user.last_name}`}
                            onCheckboxChange={onCheckboxChange}
                            itemsSelected={itemsSelected}
                            selectAll={selectAll}
                            refreshTable={refreshTable}
                            refreshCounting={refreshCounting}
                        />
                    ))}
                </Tbody>
            </Table>

        </TableContainer>
    )
}