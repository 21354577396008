import React from 'react'
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import useForm from '../../../hooks/useForm/useForm'
import {useApiToast} from '../../../hooks/useApi/useApiToast'
import {CompanyModalButton} from './CompanyModalButton/CompanyModalButton'
import {useApi} from '../../../hooks/useApi/useApi'
import {CompanyModalForm} from './CompanyModalForm/CompanyModalForm'

export const CompanyModal = (props) => {
    const {id, mode, name, file, fetchCompanies} = props
    const showToast = useApiToast()
    const {saveCompany, createCompany} = useApi()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {values, handleChange} = useForm({name: name, file: file, id: id})


    const handleSaveCompany = async () => {
        let resp
        if(mode === 'edit') {
            resp = await saveCompany(values)
        } else {
            resp = await createCompany(values)
        }

        showToast(resp)
        console.log('result', resp)
        fetchCompanies()
        onClose()
    }
    return (
        <>
            <CompanyModalButton mode={mode} onOpen={onOpen}/>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Company {name}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <CompanyModalForm
                            values={values}
                            handleChange={handleChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleSaveCompany}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}