import {useEffect, useState} from 'react'
import {formatDateToLocal} from '../../utlis/timeUtils'

export const DateYMD = (props) => {
    const {date} = props
    const [dateShow, setDateShow] = useState()

    useEffect(() => {
        setDateShow(formatDateToLocal(date))
    }, [])
    return (
        <>
            {dateShow}
        </>
    )
}