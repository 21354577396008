import {useEffect, useState} from 'react'
import {UserAPI} from '../../apis/UserAPI'
import useAuthStore from '../../stores/authStore'

export const useUsers = () => {
    const [users, setUsers] = useState([])
    const user = useAuthStore((state) => state.user)
    //console.log('user', user)
    const fetchUsers = async () => {
        const resp = await UserAPI.getAll(user)
        //console.log(resp.users)
        setUsers(resp.users)
    }
    useEffect(() => {
        fetchUsers()
    }, [])

    return {users, fetchUsers}
}
