import { useState } from 'react'
import {Card, CardBody} from '@chakra-ui/react'
import {ItemsInvalidTable} from './ItemsInvalidTable/ItemsInvalidTable'
import {useItemsInvalid} from '../../../../hooks/useItems/useItemsInvalid'

export const ItemsInvalid = (props) => {
    const {countingId, refreshCounting} = props
    const [filters, setFilters] = useState({})
    const {itemsInvalid, itemsInvalidCount, itemsInvalidSumAmount, itemsInvalidRows,
        onCheckboxChange, checkAllSelected, isCheckingAll, selectAll, handleSelectAll, itemsSelected,
        refreshTable, isDeletingAll, deleteAllSelected
    } = useItemsInvalid(countingId, refreshCounting, filters)

    return (
        <Card>
            <CardBody>
                <ItemsInvalidTable
                    itemsInvalidCount={itemsInvalidCount}
                    itemsInvalid={itemsInvalid}
                    itemsInvalidSumAmount={itemsInvalidSumAmount}
                    itemsInvalidRows={itemsInvalidRows}
                    onCheckboxChange={onCheckboxChange}
                    checkAllSelected={checkAllSelected}
                    isCheckingAll={isCheckingAll}
                    selectAll={selectAll}
                    handleSelectAll={handleSelectAll}
                    itemsSelected={itemsSelected}
                    refreshTable={refreshTable}
                    isDeletingAll={isDeletingAll}
                    deleteAllSelected={deleteAllSelected}
                    refreshCounting={refreshCounting}
                    filters = {filters}
                    setFilters = {setFilters}
                />
            </CardBody>
        </Card>
    )
}