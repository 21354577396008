import React, {useState} from 'react'
import {Button, FormLabel, HStack, Input, VStack} from '@chakra-ui/react'
import {CompanyAPI} from '../../../apis/CompanyAPI'

const StoreAdd = (props) => {
    const company = props.company
    const setStores = props.setStores
    const [store, setStore] = useState({name: '', code: ''})
    const handleChange = (event) => {
        const {name, value} = event.target
        setStore({
            ...store,
            [name]: value,
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        console.log(store)
        const resp = await CompanyAPI.addStore(company._id, store)
        setStores(resp.stores)
    }

    return (
        <form onSubmit={handleSubmit}>
            <HStack spacing={4} alignItems='flex-end'>
                <VStack>
                    <FormLabel>Name</FormLabel>
                    <Input value={store.name} onChange={handleChange} name={'name'} type='text'/>
                </VStack>
                <VStack>
                    <FormLabel>Branch</FormLabel>
                    <Input value={store.code} onChange={handleChange} name={'code'} type='text'/>
                </VStack>
                <Button type='submit'>Save</Button>
            </HStack>
        </form>
    )
}

export default StoreAdd