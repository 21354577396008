import {useCallback, useEffect, useState} from 'react'
import {CountedItemAPI} from 'apis/CountedItemAPI'

export const useItemsCounted = (initialFilters) => {
    const [itemsCounted, setItemsCounted] = useState([])
    const [sortConfig, setSortConfig] = useState({sortField: 'amount', sortOrder: 'asc'}) // initial sort config
    const [filters, setFilters] = useState(initialFilters)
    const [loading, setLoading] = useState(false)

    const toggleSortOrder = (field) => {
        setSortConfig(oldConfig => ({
            sortField: field,
            sortOrder: oldConfig && oldConfig.sortField === field && oldConfig.sortOrder === 'asc' ? 'desc' : 'asc'
        }))
    }

    const fetchItemsCounted = useCallback(async () => {
        setLoading(true)
        const resp = await CountedItemAPI.getAll({...filters, ...sortConfig})
        
        if (resp?.data) {
            setItemsCounted(resp)
        }
        setLoading(false)
    }, [filters, sortConfig])

    const getItemsCounted = (newFilters) => {
        setFilters(oldFilters => ({...oldFilters, ...newFilters}))
    }

    useEffect(() => {
        fetchItemsCounted().catch((error) => {
            console.error('Failed to fetch items:', error)
            setLoading(false)
        })
    }, [fetchItemsCounted])

    return {itemsCounted, getItemsCounted, sortConfig, toggleSortOrder, loading, filters}
}
