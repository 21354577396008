import {formatDateToLocal, formatSecondsToTime, formatTimeToLocal} from './timeUtils'
import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export 	const exportWorkingHours = async (workingHours) => {
    console.log('exportWorkingHours', workingHours)
    const filteredWorkingHours = workingHours.map((item) => ({
        name: `${item.user?.name} ${item.user?.last_name}`,
        tz: item.user?.identify,
        date: formatDateToLocal(item.created_at),
        drivingStart: formatTimeToLocal(item.time_driving_to_work_opened),
        workingStart: formatTimeToLocal(item.time_work_opened),
        workingFinish: formatTimeToLocal(item.time_work_closed),
        drivingFinish: formatTimeToLocal(item.time_driving_from_work_closed),
        drivingTime: formatSecondsToTime(item.total_drive_time),
        workingTime: formatSecondsToTime(item.total_work_time),
        totalTime: formatSecondsToTime(item.total_time),
        company: item.counting?.store.company.name,
        shop: item.counting?.store.name,
    }))
    console.log('filteredWorkingHours', filteredWorkingHours)
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Data')
    worksheet.addRow(['Name', 'TZ', 'Date', 'Driving Start', 'Working Start', 'Working Finish',
        'Driving Finish', 'Driving Time', 'Work time', 'Total time', 'Company', 'Shop'])
    filteredWorkingHours.forEach((item) => {
        worksheet.addRow([item.name, item.tz, item.date, item.drivingStart, item.workingStart, item.workingFinish,
            item.drivingFinish, item.drivingTime, item.workingTime, item.totalTime, item.company, item.shop])
    })
    worksheet.columns.forEach(column => {
        let maxColumnLength = 0
        column.eachCell({ includeEmpty: true }, cell => {
            let columnLength = cell.value ? String(cell.value).length : 10
            if (columnLength > maxColumnLength) {
                maxColumnLength = columnLength
            }
        })
        column.width = maxColumnLength < 10 ? 10 : maxColumnLength
    })
// Freeze the header row
    worksheet.views = [
        {state: 'frozen', ySplit: 1},
    ]

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    saveAs(blob, 'WorkingHours.xlsx')
}
