import {useUsers} from '../../hooks/useUsers/useUsers'
import {UsersTable} from './UsersTable/UsersTable'
import {Spinner} from '@chakra-ui/react'

export const Users = () => {
    const {users, fetchUsers} = useUsers()

    return (
        <>
            {!users ? <Spinner/> : <UsersTable users={users} fetchUsers={fetchUsers}/>}
        </>
    )
}
