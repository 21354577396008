import { useEffect, useState } from 'react'
import {formatSecondsToTime} from '../../utlis/timeUtils'

export const DateSecondsToTime = (props) => {
    const { seconds } = props
    const [timeShow, setTimeShow] = useState()

    useEffect(() => {
        setTimeShow(formatSecondsToTime(seconds))
    }, [seconds]) // It's usually a good idea to include props in the dependency array of useEffect

    return <>{timeShow}</>
}
