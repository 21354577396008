import {useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'
import {CompanyAPI} from '../../apis/CompanyAPI'

export const useApi = () => {
    const [counting, setCounting] = useState('')
    const fetchCounting = async (countingId) => {
        try {
            const resp = await CountingAPI.getCounting(countingId)
            setCounting(resp?.counting)
        } catch (err) {
            console.error(err)
        }
    }

    const updateCounting = async (countingId, data) => {
        try {
            const resp = await CountingAPI.update(countingId, data)
            setCounting(prevState => ({...prevState, ...resp?.counting}))
        } catch (err) {
            console.error(err)
        }
    }

    const [devices, setDevices] = useState([])
    const fetchDevices = async (countingId) => {
        const resp = await CountingAPI.getDevices(countingId)
        setDevices(resp.devices)
    }

    const [rowCategories, setRowCategories] = useState([])
    const fetchRowCategories = async (countingId) => {
        const resp = await CountingAPI.getCategoryRows(countingId)
        if (resp?.category_rows) {
            setRowCategories(resp.category_rows)
        }
    }

    const [itemsEdited, setItemsEdited] = useState([])
    const fetchItemsEdited = async (countingId) => {
        const resp = await CountingAPI.getItemsEdited(countingId)
        if (resp?.items_edited) {
            setItemsEdited(resp.items_edited)
        }
    }

    const [rowsEmpty, setRowsEmpty] = useState([])
    const fetchRowsEmpty = async (countingId) => {
        const resp = await CountingAPI.getRowsEmpty(countingId)
        if (resp?.rows_empty) {
            setRowsEmpty(resp.rows_empty)
        }
    }

    const saveCompany = async (company) => {
        console.log('save company', company)
        const resp = await CompanyAPI.update(company.id, company)
        console.log('resp', resp)
        return resp
    }

    const createCompany = async (company) => {
        console.log('create company', company)
        const resp = await CompanyAPI.create(company)
        console.log('resp', resp)
        return resp
    }

    const [companies, setCompanies] = useState([])
    const fetchCompanies = async () => {
        let resp = await CompanyAPI.getAll()
        setCompanies(resp.result)
    }



    return {
        counting,
        fetchCounting,
        updateCounting,
        setCounting,

        devices,
        fetchDevices,

        rowCategories,
        fetchRowCategories,

        itemsEdited,
        fetchItemsEdited,

        rowsEmpty,
        fetchRowsEmpty,

        saveCompany,
        createCompany,
        companies,
        fetchCompanies,


    }
}
