import SignaturePad from 'react-signature-canvas'
import React, { useRef, useState, useEffect } from 'react'
import { Card, CardBody, ButtonGroup,  Button, CardFooter } from '@chakra-ui/react'
import { api } from 'apis/configs/axiosConfigs'
import { useParams } from 'react-router-dom'

export const Signature = (props) => {
    const { signature, setSignature, name } = props
    const sigCanvas = useRef({})
    const { countingId } = useParams()

    const clear = () => {
        sigCanvas.current.clear()
        setSignature(null)
    }

    const save = () => {
        // setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'))
        const { setCounting } = props
        const names = name.split('.')
        const data = names.length == 1 
            ? {[name]: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')} 
            : {[names[0]]: {[names[1]]: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')}} 

        api.request({
            url: `/counting/${countingId}`,
            method: 'PUT',
            data
        }).then(()=> setCounting && setCounting(data))
    }
    useEffect(() => {
        sigCanvas.current.fromDataURL(signature, {width: 160, height: 160, yOffset: 100})
    }, [signature])

    return (<>
        
        <Card variant='outline'>
            <CardBody>
                <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{ className: 'signatureCanvas', width: 500, height: 200}}
                />
            </CardBody>
        </Card> 
        <ButtonGroup mt='3'>
            <Button colorScheme='green' onClick={save}>שמור חתימה</Button>
            <Button colorScheme='red' onClick={clear}>נקה חתימה</Button>
        </ButtonGroup>
    </>)
}