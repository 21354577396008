import React, {useState} from 'react'
import {Box, Button, FormControl, FormLabel, Input} from '@chakra-ui/react'
import {CountingAPI} from '../../apis/CountingAPI'

const JsonUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null)

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const uploadFile = async () => {
        if (!selectedFile) {
            alert('Please select a file first')
            return
        }

        const formData = new FormData()
        formData.append('file', selectedFile)

        try {
            const response = await CountingAPI.jsonUpload(formData, true) // Assuming you have an upload method in your AuthAPI

            if (response.status === 200) {
                alert('File uploaded successfully')
            } else {
                alert('File upload failed')
            }
        } catch (error) {
            console.error('Error uploading file', error)
            alert('Error uploading file')
        }
    }

    return (
        <Box>
            <FormControl id='upload'>
                <FormLabel>Upload file</FormLabel>
                <Input type='file' onChange={handleFileChange}/>
            </FormControl>
            <Button colorScheme='blue' onClick={uploadFile} mt={3}>Upload</Button>
        </Box>
    )
}

export default JsonUpload
