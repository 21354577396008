import {Button} from '@chakra-ui/react'
import React from 'react'
import {exportWorkingHours} from 'utlis/excelUtils'
import { WorkingHoursAPI } from 'apis/WorkingHoursAPI'

export const WorkingHoursExportButton = (props) => {
	return (
		<Button onClick={async () => exportWorkingHours(
			(await WorkingHoursAPI.getAll(props.filters)).working_hours 
			)} 
			mr={2} size={'sm'} colorScheme={'green'}>
				Export
		</Button>
	)
}