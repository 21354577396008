import {Button} from '@chakra-ui/react'
import React from 'react'

export const CompanyModalButton = (props) => {
    const {mode, onOpen} = props
    return (
        <>
            {mode === 'new' ? (
                <Button mr={2} size='sm' colorScheme='green' onClick={onOpen}>New</Button>
            ) : (
                <Button mr={2} size='sm' colorScheme='blue' onClick={onOpen}>Edit</Button>
            )}
        </>
    )
}