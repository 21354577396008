import {Button} from '@chakra-ui/react'
import React from 'react'

export const CompanyCatalogModalButton = (props) => {
	const {onOpen} = props
	return (
		<>
			<Button mr={2} size='sm' colorScheme='yellow' onClick={onOpen}>Catalog</Button>
		</>
	)
}