import {useCountedItemEdit} from '../useCountedItem/useCountedItemEdit'

export const useItemsCountedEdit = (itemId, refreshTable, initialUpc, initialMkt, initialAmount) => {
    return useCountedItemEdit({
        itemId,
        initialUpc,
        initialMkt,
        initialAmount,
        refreshCallback: refreshTable
    })
}