import { useState } from 'react'
import { CompanyAPI } from 'apis/CompanyAPI'
import { saveAs } from 'file-saver'
import * as ExcelJS from 'exceljs'
import { formatTimeToLocal } from 'utlis/timeUtils'
import { useToast } from '@chakra-ui/react'

const useDownloadCounted = (countingId, counting, group_items) => {
    const [isLoading, setIsLoading] = useState(false)
//    const [rowsMap, setRowsMap] = useState({}) // [rowName: number]: [customRowName: string
    const toast = useToast()

    const fetchData = async (countingId) => {
        const resp = await CompanyAPI.downloadItems(countingId, false,  group_items)
        return [resp.counted_items, resp.rows_map, resp.category_rows, resp.catalog_items, resp.users]
    }

    const sheetHeaders = [
        'Row Name',
        'Category',
        'UPC',
        'MKT_1',
        'Amount', 
        'Description',
        ...(counting?.settings?.customRowNames ? ['Custom Row Name'] : []),
        'Device Number',
        'Name',
        'Length',
        'Time',
        'Invalid',
    ] 

    const generateExcel = async (filteredCountedItems, rowsMap, categoryRows, catalogItems, users) => {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Data')
        worksheet.columns = sheetHeaders.map((header) => ({ header, key: header, width: ['UPC', 'MKT_1'].includes(header) ? 17: 10 }))
        
        filteredCountedItems.forEach(({row_name, user_id, upc, mkt_1, amount, device_number, user, mkt_1_length, scanned_at, is_found}) => {
            worksheet.addRow([
                isNaN(Number(row_name)) ? row_name : Number(row_name),
                rowsMap[row_name]?.category_row_id ? categoryRows[rowsMap[row_name].category_row_id]?.name : '',
                upc,
                mkt_1,
                isNaN(Number(amount)) ? amount : Number(amount),
                catalogItems[upc]?.mkt_2, //description
                ...(counting?.settings?.customRowNames ? [rowsMap[row_name ?? ''].custom_row_name ?? ''] : []),
                isNaN(Number(device_number)) ? device_number : Number(device_number),
                user_id ? `${users[user_id].name} ${users[user_id].last_name}` : '',
                isNaN(Number(mkt_1_length)) ? mkt_1_length : Number(mkt_1_length),
                scanned_at ? formatTimeToLocal(scanned_at) : '',
                is_found ? 'No' : 'Yes',
            ])
        })

        worksheet.views = [{state: 'frozen', ySplit: 1}]
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        saveAs(blob, `CountingData_${counting.store.company.name}_${counting.store.code}.xlsx`)
    }

    const downloadCounted = async () => {
        setIsLoading(true)
        try {
            const [countedItems, rowsMap, categoryRows, catalogItems, users ] = await fetchData(countingId)
            //const filteredCountedItems = prepareData(countedItems)
            await generateExcel(countedItems, rowsMap, categoryRows, catalogItems, users)
            toast({
                title: 'Downloading.',
                description: 'The data is being downloaded.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'An error occurred.',
                description: 'Unable to download the data.' + error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return {
        downloadCounted,
        isLoading,
    }
}

export default useDownloadCounted
