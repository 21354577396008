import React, {useState} from 'react'
import {
    Box, Button,
    Card,
    CardBody,
    CardHeader, Flex,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Th,
    Thead,
    Tr, Td, 
    Checkbox,
    Editable, EditableInput, EditablePreview
} from '@chakra-ui/react'
import StoreAdd from './StoreAdd/StoreAdd'
import {useParams} from 'react-router-dom'
import {useStores} from 'hooks/useStores/useStores'
import {StoresImportModal} from './StoresImportModal/StoresImportModal'
import {DateYMD} from 'components/Date/DateYMD'
import { CompanyAPI } from 'apis/CompanyAPI'
import useAuthStore from 'stores/authStore'




const Stores = () => {
    const {company_id} = useParams()
    const {stores, deleteStore, setStores, fetchStores, company} = useStores(company_id)
    const [selectedStores, setSelectedStores] = useState([])
    const user = useAuthStore((state) => state.user)

    const handleCheckboxChange = (storeId) => {
        setSelectedStores(selectedStores.includes(storeId) 
            ? selectedStores.filter(id => id !== storeId) 
            : [...selectedStores, storeId]
        )
    }

    const deleteSelectedStores = () => {
        selectedStores.forEach(storeId => deleteStore(storeId))
        setSelectedStores([]) // reset the selected stores
    }

    return (
        <div>
            <Card>
                <CardHeader>
                    <Flex>
                        <Box marginRight='auto'>
                            <StoreAdd company={company} setStores={setStores} />
                        </Box>
                        <Box marginLeft='auto'>
                            <StoresImportModal id={company._id} fetchStores={fetchStores} />
                        </Box>
                    </Flex>
                </CardHeader>
                <CardBody>

                    <TableContainer>
                        <Table variant='striped'>
                            <TableCaption>List of the Stores <b>{company?.name || ''}</b></TableCaption>
                            <Thead>
                                <Tr>
                                    <Th></Th><Th>Name</Th><Th>Branch</Th><Th>Last counting</Th><Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {stores?.map(({name, code, latest_counting, _id}) => (
                                    <Tr key={_id}>
                                        <Td><Checkbox border={'1px'} isChecked={selectedStores.includes(_id)} onChange={() => handleCheckboxChange(_id)} /></Td>
                                        <Td>
                                            <Editable defaultValue={name} onSubmit={(nextValue) => 
                                                nextValue !== name && CompanyAPI.addStore(company_id , { _id, name: nextValue })}
                                                isDisabled={!user.is_admin}
                                                >
                                                <EditablePreview />
                                                <EditableInput />
                                            </Editable>
                                        </Td>
                                        <Td>
                                            <Editable defaultValue={code || 'empty'} onSubmit={(nextValue) =>
                                                nextValue !== code && CompanyAPI.addStore(company_id, { _id, nextCode: nextValue })}
                                                isDisabled={!user.is_admin}
                                                >
                                                <EditablePreview />
                                                <EditableInput />
                                            </Editable>
                                        </Td>
                                        <Td>{latest_counting?.created_at && (<DateYMD date={latest_counting?.created_at}/>)}</Td>
                                        <Td>
                                        <Button
                                            onClick={() => confirm('Are you sure you want to delete this store?') && deleteStore(_id)}
                                            size='sm' colorScheme='red' mb={1}
                                        >
                                            Delete
                                        </Button>
                                        </Td>
                                    </Tr>
                                ))}                                
                            </Tbody>
                        </Table>
                        <Button colorScheme={'red'} onClick={deleteSelectedStores}>Delete Selected</Button>
                    </TableContainer>
                </CardBody>
            </Card>
        </div>
    )
}

export default Stores
