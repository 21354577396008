import {useApi} from '../../../hooks/useApi/useApi'
import {useEffect} from 'react'
import {Card, CardBody} from '@chakra-ui/react'
import {RowsEmptyTable} from './RowsEmptyTable/RowsEmptyTable'

export const RowsEmpty = (props) => {
    const {countingId} = props
    const {rowsEmpty, fetchRowsEmpty} = useApi()
    useEffect(() => {
        fetchRowsEmpty(countingId)
    }, [countingId])
    return (
        <Card>
            <CardBody>
                <RowsEmptyTable rowsEmpty={rowsEmpty}/>
            </CardBody>
        </Card>
    )
}