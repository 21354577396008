import React, { useState, useEffect } from 'react'
import { Card, CardBody, Table, TableCaption, TableContainer, Tbody, Th, Td, Thead, Tr } from '@chakra-ui/react'
import { RowAPI } from '../../../apis/RowAPI'
import { RowsCounted } from '../RowsCounted/RowsCounted'
import { api } from '../../../apis/configs/axiosConfigs'
import { Captions } from '../Captions'


const getCategoryFree = async function (filters) {
    const response = await api.request({
        url: '/rows/category-free',
        method: 'GET',
        params: filters,
    })

    return response.data.rows
}


export const CategoryFree = (props) => {

    const [rows, setRows] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const result = await getCategoryFree({countingId: props.countingId})
            setRows(result)
        }
        fetchData()
    },[])

// מנה: כמות סופר מסופון
    return (
        <Card>
            <CardBody>

                
            <TableContainer>
                <Table variant='striped'>
                    <TableCaption placement={'top'}>
                        <Captions
                            total={rows.length}
                            totalAmount={rows.reduce((acc, row) => acc + row.amount, 0)}
                            totalRows={rows.length}
                        />
                    </TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Row</Th>
                            <Th>Amount</Th>
                            <Th>User</Th>
                            <Th>Device</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows.map(row => (
                            <Tr key={row._id}>
                                <Td>{row.name}</Td>
                                <Td>{row.amount}</Td>
                                <Td>{`${row?.user?.name} ${row?.user?.last_name}`}</Td>
                                <Td>{row.device_number}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                    </Table>
               </TableContainer>
            </CardBody>
        </Card>
    )
}