import {api} from './configs/axiosConfigs'
import {defineCancelApiObject} from './configs/axiosUtils'

export const UserAPI = {
    getAll: async function (user, cancel = false) {
        const response = await api.request({
            url: '/users',
            method: 'GET',
            params: {user_id: user._id},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
//     update user
    update: async function (id, data) {
        const response = await api.request({
            url: `/users/${id}`,
            method: 'PUT',
            data: data,
        })

        return response.data
    },
//     create user
    create: async function (data) {
        const response = await api.request({
            url: `/users`,
            method: 'POST',
            data: data,
        })
        return response.data
    },

    delete: async function (id) {
        const response = await api.request({
            url: `/users/${id}`,
            method: 'DELETE',
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(UserAPI)