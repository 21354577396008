import CountingAdd from '../Counting/CountingAdd/CountingAdd'

import {useCountings} from '../../hooks/useCountings/useCountings'
import {
    Button,
    Card,
    CardBody,
    CardHeader, Checkbox,
    Heading,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Th,
    Thead,
    Tr,
    Select,
    Input
} from '@chakra-ui/react'
import { CountingRow } from './CountingsTable/CountingRow'
import {DeleteCountings} from './CountingsTable/DeleteCountings/DeleteCountings'
import Pagination from '../Pagination/Pagination'
import useAuthStore from '../../stores/authStore'
import { Filters } from './Filters'

const Countings = () => {
   const {countings, companies, fetchCountings, handleChangeCompany, handleChangeStoreId,
       selectedCompanyId, selectedStoreId, handleSubmit, stores, setSelectedStoreId,
       setSelectedCompanyId, setSelectedCompany, selectedDate, handleDateChange, managers,
       handleSelectCounting, handleDeleteCountings, isDeleting, handlePageChange, filters, setFilters, selectedManagerId, setSelectedManagerId, settings, setSettings} = useCountings()
       const user = useAuthStore((state) => state.user)
    return (
        <div>
            <CountingAdd
                companies={companies}
                fetchCountings={fetchCountings}
                handleChangeCompany={handleChangeCompany}
                handleChangeStoreId={handleChangeStoreId}
                handleSubmit={handleSubmit}
                selectedCompanyId={selectedCompanyId}
                selectedStoreId={selectedStoreId}
                stores={stores}
                setSelectedStoreId={setSelectedStoreId}
                setSelectedCompanyId={setSelectedCompanyId}
                setSelectedCompany={setSelectedCompany}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                managers={managers}
                setSelectedManagerId={setSelectedManagerId}
                selectedManagerId={selectedManagerId}
                settings={settings}
                setSettings={setSettings}

            />
            <Card m={2}>
            <CardHeader>
                <Heading size='md'> Countings </Heading>
            </CardHeader>
            <CardBody>
                <TableContainer>
                    <Table variant='striped'>
                        <TableCaption>List of the Countings</TableCaption>
                        <Thead>
                            <Tr>
                                <Filters 
                                    companies={companies} 
                                    //shops={countings?.shops} 
                                    handleInputChange={fetchCountings}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                            </Tr>  
                            <Tr>
                                <Th>

                                </Th>
                                <Th>Date</Th>
                                <Th>Company</Th>
                                <Th>Shop</Th>
                                <Th>Branch</Th>
                                <Th>Manager</Th>
                                <Th>Amount</Th>
                                <Th>Missing</Th>
                                <Th>Double</Th>
                                <Th>
                                    {(user?.is_admin || user?.is_counting_manager) &&(
                                        <DeleteCountings handleDeleteCountings={handleDeleteCountings} isDeleting={isDeleting}/>
                                    )}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {countings?.data?.map(counting => (
                                <CountingRow
                                    key={counting._id}
                                    //fetchCountings={fetchCountings}
                                    counting={counting}
                                    managers={managers}
                                    managerSelected={counting?.manager_id}
                                    handleSelectCounting={handleSelectCounting}
                                />
                            ))}
                        </Tbody>
                    </Table>
                    <Pagination lastPage={countings.last_page ?? 1} getData={handlePageChange} />
                </TableContainer>
            </CardBody>
        </Card>
        </div>
    )
}

export default Countings