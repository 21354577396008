import React, {useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'
import CategoryRowsTable from './CategoryRowsTable/CategoryRowsTable'
import CategoryRowModal from './CategoryRowModal/CategoryRowModal'
import {Card, CardBody} from '@chakra-ui/react'
import { Gauge, WaterContent } from './Chart'

const CategoryRows = (props) => {
    const countingId = props.countingId
    const [categoryRows, setCategoryRows] = useState([])
    //const [rows, setRows] = useState([])
    const [nextRow, setNextRow] = useState(1)

    const fetchCategoryRows = async (countingId) => {
        const resp = await CountingAPI.getCategoryRows(countingId)
        if(resp.category_rows?.length) {
            resp.category_rows.sort((a,b) => a.row_to - b.row_to) 
            setNextRow(resp.category_rows[resp.category_rows.length - 1].row_to + 1)
        }
        resp.category_rows.map((c, i) => resp.category_rows[i].rowsCount = resp.rows[c._id]?.count ?? 0)
        setCategoryRows(resp.category_rows)
        //setRows(resp.rows)
    }
    useEffect(() => {
        fetchCategoryRows(countingId)
    }, [])

    const categoriesWithRowsInARange = (rowFrom, rowTo) => categoryRows.filter(c => (
        c.row_from >= rowFrom && c.row_from <= rowTo) 
        || (c.row_to >= rowFrom && c.row_to <= rowTo) 
        || (c.row_from <= rowFrom && c.row_to >= rowTo)
        )

    const maxChartColumns = 14
    const chartLinesAmount = Math.ceil(categoryRows.length / maxChartColumns)
    const oneLineCount = Math.ceil(categoryRows.length / chartLinesAmount)

    return (
        <>
            <Card m={1}>
                <CardBody>
                    <CategoryRowModal
                        fetchCategoryRows={fetchCategoryRows}
                        countingId={countingId}
                        mode={'new'}
                        rowFrom={nextRow}
                        categoriesWithRowsInARange={categoriesWithRowsInARange}
                    />
                </CardBody>
            </Card>
            <Card m={1}>
                <CardBody>
                    <CategoryRowsTable
                        fetchCategoryRows={fetchCategoryRows}
                        countingId={countingId}
                        categoryRows={categoryRows}
                        categoriesWithRowsInARange={categoriesWithRowsInARange}
                    />

                    {/* <Gauge data={categoryRows} title={'Progress'}/> */}
                {[...Array(chartLinesAmount)].map((_, i) => 
                    <WaterContent key={i} data={categoryRows.slice(i * oneLineCount, (i + 1) * oneLineCount)} title={'Water Content'}/>
                )}
                </CardBody>
            </Card>
        </>
    )
}

export default CategoryRows