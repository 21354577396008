import { Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { RowsCountedTableRowOld } from './RowsCountedTableRowOld'
import {RowsCountedTableCaptionOld} from './RowsCountedTableCaptionOld/RowsCountedTableCaptionOld'

export const RowsCountedTableOld = (props) => {
    const { rows, totalRows, totalAmount, getRowsCounted } = props
    const handleFilterChange = (newFilters) => {
        getRowsCounted(newFilters)
    }
    return (
        <TableContainer>
            <Table variant='striped'>
                <TableCaption placement={'top'}>
                    <RowsCountedTableCaptionOld amount={totalAmount} total={totalRows} />
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Row</Th>
                        <Th>Device</Th>
                        <Th>User</Th>
                        <Th>Amount</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rows.map(row => (
                        <RowsCountedTableRowOld
                            key={row._id?.row_name+row._id?.device_number}
                            id={row._id?.row_name}
                            deviceNumber={row._id?.device_number}
                            totalAmount={row.total_amount}
                            name={`${row?.user?.name} ${row?.user?.last_name}`}
                        />
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}
