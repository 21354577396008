import {useState} from 'react'
import {useFieldFilter} from '../useField/useFieldFilter'

export const useItemsEditedFilters = (items) => {
    const [upcQuery, setUpcQuery] = useState('')
    const [upcExactMatch, setUpcExactMatch] = useState(false)
    const [mktQuery, setMktQuery] = useState('')
    const [mktExactMatch, setMktExactMatch] = useState(false)
    const [rowQuery, setRowQuery] = useState('')
    const [rowExactMatch, setRowExactMatch] = useState(false)
    const [deviceQuery, setDeviceQuery] = useState('')
    const [deviceExactMatch, setDeviceExactMatch] = useState(false)
    const [nameQuery, setNameQuery] = useState('')
    const [nameExactMatch, setNameExactMatch] = useState(false)

    let filteredItems = useFieldFilter(items, upcQuery, 'upc', upcExactMatch)
    filteredItems = useFieldFilter(filteredItems, mktQuery, 'mkt_1', mktExactMatch)
    filteredItems = useFieldFilter(filteredItems, rowQuery, 'row_name', rowExactMatch)
    filteredItems = useFieldFilter(filteredItems, deviceQuery, 'device_number', deviceExactMatch)
    filteredItems = useFieldFilter(filteredItems, nameQuery, 'fullName', nameExactMatch)

    return {
        filteredItems,
        upcQuery, setUpcQuery, upcExactMatch, setUpcExactMatch,
        mktQuery, setMktQuery, mktExactMatch, setMktExactMatch,
        rowQuery, setRowQuery, rowExactMatch, setRowExactMatch,
        deviceQuery, setDeviceQuery, deviceExactMatch, setDeviceExactMatch,
        nameQuery, setNameQuery, nameExactMatch, setNameExactMatch,
    }
}
