import {Card, CardBody} from '@chakra-ui/react'
import {ItemsEditedTable} from './ItemsEditedTable/ItemsEditedTable'

import {useItemsEdited} from '../../../../hooks/useItems/useItemsEdited'

export const ItemsEdited = (props) => {
    const {countingId, refreshCounting} = props
    const {itemsEdited, getItemsEdited} = useItemsEdited(countingId)
    return (
        <Card>
            <CardBody>
                <ItemsEditedTable refreshCounting={refreshCounting} itemsEdited={itemsEdited} getItemsEdited={getItemsEdited}/>
            </CardBody>
        </Card>
    )
}