import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay, useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import {CompanyCatalogModalButton} from './CompanyCatalogModalButton/CompanyCatalogModalButton'
import {CompanyCatalogModalForm} from './CompanyCatalogModalForm/CompanyCatalogModalForm'

export const CompanyCatalogModal = (props) => {
	const {id, name} = props
	const {isOpen, onOpen, onClose} = useDisclosure()
	return (
		<>
			<CompanyCatalogModalButton onOpen={onOpen}/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay/>
				<ModalContent>
					<ModalHeader>Catalog {name}</ModalHeader>
					<ModalCloseButton/>
					<ModalBody>
						<CompanyCatalogModalForm onClose={onClose} id={id}/>
					</ModalBody>
					<ModalFooter>

					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}