import {useState} from 'react'
import {CompanyAPI} from '../../apis/CompanyAPI'
import {useToast} from '@chakra-ui/react'

export const useStoresUpload = (id, onClose, fetchStores) => {

    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    // const [processing, setProcessing] = useState(false)
    // const [error, setError] = useState(false)
    const [complete, setComplete] = useState(false)
    const [progress, setProgress] = useState(0)
    const [importing, setImporting] = useState(false)
    const [existedCount, setExistedCount] = useState(0)
    const [newCount, setNewCount] = useState(0)
    const [storesToDeleteCount, setStoresToDeleteCount] = useState(0)
    const [stores, setStores] = useState([])
    const [storesToDelete, setStoresToDelete] = useState([])

    const toast = useToast()
    const handleFileChange = (event) => {
        setFile(event.target.files[0])
        setUploading(false)
        setComplete(false)
        // setError(false)
        // setProcessing(false)
        // setImporting(false)
    }

    const handleFileUpload = async () => {
        if (file) {
            setUploading(true)
            setComplete(false)
            // setError(false)
            let response
            try{
                response = await CompanyAPI.uploadStoresFile(id, file, (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    if(percentCompleted === 100) {
                        // setProcessing(true)
                    }
                    setProgress(percentCompleted)
                })

            } catch (e) {
                console.log('error', e)
                toast({
                    description: 'file_upload_error',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                // setError(true)
                // setProcessing(false)
                return false
            }

            console.log('response', response)
            setExistedCount(response?.existedCount)
            setNewCount(response?.newCount)
            setStoresToDeleteCount(response?.storesToDeleteCount)
            setStores(response?.stores)
            setStoresToDelete(response?.storesToDelete)
            // setProcessing(false)
            setComplete(true)
        } else {
            console.log('No file selected')
        }
    }

    const handleSave = async () => {
        console.log('handleSave')
        console.log('stores', stores)
        console.log('storesToDelete', storesToDelete)
        setImporting(true)
        let resp = await CompanyAPI.addStores(id, stores)
        console.log('resp add stores', resp)
        for (const storeToDelete of storesToDelete) {
            try{
                await CompanyAPI.deleteStore(storeToDelete._id, id)
            } catch (e) {
                console.log('error', e)
                setImporting(false)
                toast({
                    description: 'store_delete_error',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
        }
        toast({
            description: 'stores_imported',
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
        setImporting(false)
        fetchStores()
        onClose()
    }


    return {
        file,
        uploading,
        handleFileChange,
        handleFileUpload,
        progress,
        existedCount,
        newCount,
        storesToDeleteCount,
        complete,
        handleSave,
        importing,
    }

}