import { useState } from 'react'
import { Table, TableContainer, TableCaption, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { ItemsEditedTableRow } from './ItemsEditedTableRow'
import { useSortableData } from '../../../../../hooks/useSort/useSortableData'
import { ItemsEditedHeaderAmount } from '../ItemsEditedHeaderAmount/ItemsEditedHeaderAmount'
import { ItemsEditedFilters } from './ItemsEditedFilters/ItemsEditedFilters'
import { Captions } from 'components/Counting/Captions'

export const ItemsEditedTable = ({ itemsEdited, refreshCounting }) => {
    const [filteredItems, setFilteredItems] = useState(itemsEdited)
    const { items: sortedItems, requestSort, sortConfig } = useSortableData(filteredItems)

    return (
        <TableContainer>
            <Table>
                <TableCaption placement={'top'}>
                    <Captions total={itemsEdited.length} totalAmount={itemsEdited.reduce((acc,i) => acc + i.amount, 0)} />
                </TableCaption>
                <Thead>
                    <Tr>
                        <ItemsEditedFilters itemsEdited={itemsEdited} setFilteredItems={setFilteredItems} />
                    </Tr>
                    <Tr>
                        <Th>UPC</Th>
                        <Th>MKT</Th>
                        <Th>Row</Th>
                        <Th>
                            <ItemsEditedHeaderAmount requestSort={requestSort} sortConfig={sortConfig} />
                        </Th>
                        <Th>Device</Th>
                        <Th>Name</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedItems.map(item => (
                        <ItemsEditedTableRow
                            key={item._id}
                            id={item._id}
                            upc={item.upc}
                            device={item.device_number}
                            row={item.row_name}
                            mkt={item.mkt_1}
                            amount={item.amount}
                            userName={item.fullName}
                            refreshCounting={refreshCounting}
                        />
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}
