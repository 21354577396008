import {Button, Card, CardBody, FormLabel, Input, Spinner, Text} from '@chakra-ui/react'
import {useStoresUpload} from '../../../../hooks/useStores/useStoresUpload'
import {
	FileUploadProgressBar
} from '../../../FileUpload/FileUploadProgressBar/FileUploadProgressBar'
import {StoresImportSection} from '../StoresImportStats/StoresImportSection'

export const StoresImportModalForm = (props) => {
	const {id, onClose, fetchStores} = props
	const {handleFileChange, handleFileUpload ,uploading, progress, newCount,existedCount,
		storesToDeleteCount, complete, handleSave, importing} = useStoresUpload(id, onClose, fetchStores)
	return (
		<>
			<Card>
				<CardBody>
					<FormLabel>Shops</FormLabel>
					<Input type='file' name='fileShopsImport' onChange={handleFileChange}/>
					<Button m={2} onClick={handleFileUpload}>Upload</Button>
					{uploading && <FileUploadProgressBar progress={progress} />}
					{complete && (<StoresImportSection
						newCount={newCount}
						existedCount={existedCount}
						storesToDeleteCount={storesToDeleteCount}
						handleSave={handleSave}
					/>)}
					{importing &&(
						<>
							<Text mt={4}>Importing</Text>
							<Spinner/>
						</>
					)}
				</CardBody>
			</Card>
		</>
	)
}