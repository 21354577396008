import {Button} from '@chakra-ui/react'

export const RowsDoubleDeleteButton = (props) => {
	const {onDelete} = props
	return (
		<>
			<Button size='sm' colorScheme='red' mr={1} onClick={onDelete}>
				Delete
			</Button>
		</>
	)
}