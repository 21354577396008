import {useCallback, useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'
import {CountedItemAPI} from '../../apis/CountedItemAPI'
import {useApiToast} from '../useApi/useApiToast'
import {useToast} from '@chakra-ui/react'

export const useItemsInvalid = (countingId, refreshCallback, filters={}) => {
    const [itemsInvalid, setItemsInvalid] = useState([])
    const [itemsInvalidCount, setItemsInvalidCount] = useState(0)
    const [itemsInvalidSumAmount, setItemsInvalidSumAmount] = useState()
    const [itemsInvalidRows, setItemsInvalidRows] = useState([])
    const [itemsSelected, setItemsSelected] = useState([])
    const [isCheckingAll, setIsCheckingAll] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [isDeletingAll, setIsDeletingAll] = useState(false)


    const toast = useToast()
    const apiToast = useApiToast()
    const fetchItemsInvalid = async (countingId) => {
        try {
            const resp = await CountingAPI.getItemsInvalid(countingId, filters)
            setItemsInvalid(resp?.items_invalid)
        } catch (err) {
            console.error(err)
        }
    }

    const sumItemsInvalid = () => {
        let sum = 0
        itemsInvalid.forEach((item) => {
            sum += item.amount
        })
        setItemsInvalidSumAmount(sum)
        return sum
    }

    const countRows = () => {
        let rows = []
        itemsInvalid.forEach((item) => {
            if (!rows.includes(item.row_name)) {
                rows.push(item.row_name)
            }
        })
        setItemsInvalidRows(rows)
        return rows
    }

    const onCheckboxChange = useCallback((countedItemId, checked) => {
        console.log('onCheckboxChange', countedItemId, checked)
        if(checked) {
            setItemsSelected(prevItemsSelected => [...prevItemsSelected, countedItemId])
        } else {
            setItemsSelected(prevItemsSelected => prevItemsSelected.filter(itemId => itemId !== countedItemId))
        }
    }, [])

    const checkAllSelected = async () => {
        console.log('checkAllSelected', itemsSelected.length)
        if(itemsSelected.length === 0) {
            toast({
                description: 'No items selected',
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        setIsCheckingAll(true)
        try{
            let resp = await CountedItemAPI.checkInCatalog(itemsSelected)
            console.log('resp', resp)
            apiToast(resp)
            fetchItemsInvalid(countingId)

        } catch (error) {
            toast({
                title: 'Error',
                description: 'Error checking items in catalog',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            refreshCallback()
            setIsCheckingAll(false)
        }
    }

    const deleteAllSelected = async () => {
        console.log('deleteAllSelected', itemsSelected.length)
        if(itemsSelected.length === 0) {
            toast({
                description: 'No items selected',
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        setIsDeletingAll(true)
        try{
            let resp = await CountedItemAPI.deleteMany(itemsSelected)
            console.log('resp', resp)
            apiToast(resp)
            fetchItemsInvalid(countingId)

        } catch (error) {
            toast({
                title: 'Error',
                description: 'Error deleting items',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            refreshCallback()
            setIsDeletingAll(false)
            setItemsSelected([])
        }
    }

    const handleSelectAll = useCallback(() => {
        setSelectAll(prevSelectAll => !prevSelectAll)
    }, [])

    const refreshTable = () => {
        fetchItemsInvalid(countingId)
    }

    useEffect(() => {
        if(selectAll){
            setItemsSelected(itemsInvalid.map(item => item._id))
        }else{
            setItemsSelected([])
        }
    }, [selectAll])


    // useEffect(() => {
    //     console.log('itemsSelected', itemsSelected)
    // }, [itemsSelected])

    useEffect(() => {
        fetchItemsInvalid(countingId)
    }, [filters])

    useEffect(() => {
        setItemsInvalidCount(itemsInvalid.length)
        sumItemsInvalid()
        countRows()
    }, [itemsInvalid])

    useEffect(() => {
        console.log('itemsSelected', itemsSelected)
    }, [itemsSelected])

    return {itemsInvalid, fetchItemsInvalid, itemsInvalidCount, itemsInvalidSumAmount,
        itemsInvalidRows, onCheckboxChange, checkAllSelected, isCheckingAll, selectAll, handleSelectAll,
        itemsSelected, refreshTable, isDeletingAll, deleteAllSelected}
}