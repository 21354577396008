import {Button} from '@chakra-ui/react'

export const ItemsInvalidDelete = (props) => {
	const {isDeleting, deleteAllSelected} = props
	return (
		<>
			<Button ml={1} size={'sm'} isLoading={isDeleting}
					loadingText='Deleting...' onClick={deleteAllSelected} colorScheme={'red'}>Delete</Button>
		</>
	)
}