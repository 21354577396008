import {Button} from '@chakra-ui/react'
import {CountedItemDelete} from '../../../CountedItemDelete/CountedItemDelete'

export const ItemsInvalidTableRowButtons = (props) => {
	const {isEditing, onEdit, onSave, refreshTable, id, refreshCounting} = props
	return (
		<>
			{isEditing ? (
				<Button size={'sm'} colorScheme={'green'} onClick={onSave} mr={1}>Save</Button>
			) : (
				<Button size={'sm'} colorScheme={'green'} onClick={onEdit} mr={1}>Edit</Button>
			)}
			<CountedItemDelete id={id} refreshTable={refreshTable} refreshCounting={refreshCounting}/>
		</>
	)
}