import React, { useEffect, useState } from 'react'
import { Card, CardBody, Input, Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr, Td } from '@chakra-ui/react'
import { Captions } from '../Captions'
import { useParams } from 'react-router-dom'
import { api } from '../../../apis/configs/axiosConfigs'
import { WorkingHourModal } from '../../WorkingHours/WorkingHourModal/WorkingHourModal'

const getWorkingHours = async function (filters) {
    const response = await api.request({
        url: '/working-hours/',
        method: 'GET',
        params: filters,
    })

    return response.data.working_hours

}

const updateWorkingHours = async function (id, data) {
    const response = await api.request({
        url: `/working-hours/${id}`,
        method: 'PUT',
        data: data,
    })
    return response.data
}

export const CountingWorkingHours = (props) => {
    
        const { countingId } = useParams()
        const [ workingHours, setWorkingHours ] = useState([])  
        const [ shiftTimeAmount, setShiftTimeAmount ] = useState('')


        useEffect(() => {
            const fetchData = async () => {
                const result = await getWorkingHours({ countingId: countingId })
                setWorkingHours(result)
            }
            fetchData()
        }, [])

        useEffect(() => {
            const inSeconds = workingHours.reduce((acc, item) => {
                if (item.time_work_closed) {
                    const start = new Date(item.time_work_opened)
                    const end = new Date(item.time_work_closed)
                    const diff = end - start
                    return acc + diff
                } else {
                    return acc
                } 
            }, 0)/1000
            const hours = Math.floor(inSeconds / 3600)
            const minutes = Math.floor((inSeconds % 3600) / 60)
            //setShiftTimeAmount(minutes)
            setShiftTimeAmount(`${hours}:${String(minutes).padStart(2, '0')}`)
        }, [workingHours])
        return (
            <Card>
                <CardBody>
                    <TableContainer>
                        <Table variant='striped'>
                            <TableCaption placement={'top'}>
                                <Captions
                                    totalRows={workingHours.length}
                                    totalAmount={shiftTimeAmount}
                                />
                            </TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Employee</Th>
                                    <Th>Shift start</Th>
                                    <Th>Shift end</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {workingHours && workingHours.map((hours, idx) => (
                                    <Tr key={idx}>
                                        <Td>{`${hours.user?.name} ${hours.user?.last_name}`}</Td>
                                        <Td>{(new Date(hours.time_work_opened)).toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' })}</Td>
                                        <Td>{
                                            hours.time_work_closed 
                                                ? (new Date(hours.time_work_closed)).toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' })
                                                : <Input type='time' size='lg' placeholder='Enter time of ending' onBlur={(event) => {
                                                    const jsDate = new Date(hours.time_work_opened)
                                                    jsDate.setHours(...event.target.value.split(':'))
                                                    const newWorkingHours = workingHours.map(item => item._id === hours._id ? {...item, time_work_closed: jsDate.toISOString()} : item)
                                                    updateWorkingHours(hours._id, newWorkingHours[idx])
                                                    setWorkingHours(newWorkingHours)
                                                }}/>
                                        }</Td>
                                        <Td>
                                            <WorkingHourModal
                                                id={hours._id}
                                                userTz={hours.user?.identify}
                                                name={`${hours.user?.name} ${hours.user?.last_name}`}
                                                date={hours.created_at}
                                                timeDrivingStart={hours.time_driving_to_work_opened}
                                                timeWorkingStart={hours.time_work_opened}
                                                timeWorkingClosed={hours.time_work_closed}
                                                timeDrivingClosed={hours.time_driving_from_work_closed}
                                                timeDrivingFromWorkStart={hours.time_driving_from_work_opened}
                                                timeDrivingToWorkClosed={hours.time_driving_to_work_closed}
                                                updateWorkingHours={(id, hours) => { 
                                                    updateWorkingHours(id, hours) 
                                                    const newWorkingHours = workingHours.map(item => item._id === id ? {...item, ...hours} : item)
                                                    setWorkingHours(newWorkingHours)
                                                }}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>
        )
}