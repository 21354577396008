import React from 'react'
import {Button} from '@chakra-ui/react'
import useAuthStore from '../../../stores/authStore'
import {useNavigate} from 'react-router-dom'

const LogOutButton = () => {
    const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn)
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
    const navigate = useNavigate()
    const handleLogOut = () => {
        console.log('handle logout')
        console.log(isLoggedIn)
        setIsLoggedIn(false)
        navigate('/login')
    }
    return (
        <Button onClick={handleLogOut} ml={2}>Log Out</Button>
    )
}

export default LogOutButton