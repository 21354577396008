import {useEffect, useState} from 'react'
import {RowAPI} from 'apis/RowAPI'

export const useRowsCounted = (initialFilters) => {
    const [rows, setRows] = useState([])
    const [filters, setFilters] = useState(initialFilters)
    const [isLoading, setIsLoading] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [totalTested, setTotalTested] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const fetchRowsCounted = async () => {
        const resp = await RowAPI.getAll(filters)
        setRows(resp)
        setTotalAmount(resp.total_amount)
        setTotalRows(resp.total)
        setTotalTested(resp.total_tested)
    }

    
    const handlePageChange = (data) => {
        getRowsCounted(data)
        setCurrentPage(data)
    }
    
    const getRowsCounted = async (newFilters) => {
        setFilters(oldFilters => ({...oldFilters, ...newFilters}))
    }

    useEffect(() => {
        fetchRowsCounted().catch((error) => {
            console.error('Failed to fetch items:', error)

        }).finally(() => {
            setIsLoading(false)
        })
    }, [filters])

    return { rows, isLoading, totalRows, totalAmount, totalTested, getRowsCounted, handlePageChange, currentPage, filters, setFilters }
}