import { useEffect, useState } from 'react'
import { Button, Card, CardBody, Stack, Box, FormControl, FormLabel, Input, TableContainer, TableCaption, Tbody, Tr, Th, Td, Thead, Table, Tag } from '@chakra-ui/react'
import { api } from 'apis/configs/axiosConfigs'
import { hoursFromSeconds } from '../../utlis/timeUtils'
import useDownloadStatistics from 'hooks/useDownload/useDownloadStatistics'

import { Captions } from '../Counting/Captions'




export const Statistics = () => {
    // const [statistics, setStatistics] = useState(null)
    const [filteredStatistics, setFilteredStatistics] = useState([])
    const [tags, setTags] = useState({
        totalRows: 0,
        items: 0,
        totalTime: '00:00'
    })
    const [filter, setFilter] = useState({
        dateFrom: null,
        dateTo: null,
        withItemsPerUser: true,
        //userId: null
    })

    

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    

    const formatRow = ({ created_at, counting, user, total_work_time, counted_items }) => {
        return [
            (new Date(created_at)).toLocaleDateString('he-IL'),
            counting?.store?.company?.name,
            counting?.store?.name,
            `${user?.name} ${user?.last_name}`,
            hoursFromSeconds(total_work_time),
            counted_items,
            total_work_time && Math.floor(counted_items/(total_work_time/60/60))
        ]
    }

    const {download, isLoading} = useDownloadStatistics(formatRow, filteredStatistics)
    
    const fetchStatistics = async (filters) => {
        setLoading(true)
        setError(null)
        api.request({
            url: '/working-hours',
            params: filters
        }).then(resp => {
            const data = resp.data.working_hours
            data.map((s, i) => data[i].counted_items = resp.data.counted_items[`${s.user_id}_${s.counting_id}`] ?? 0)
            setFilteredStatistics(data)
            setLoading(false)
        }).catch(err => {
            setError(err)
            setLoading(false)
        })
    }

   

    const handleFilterChange = e => {
        const { name, value } = e.target
        // console.log(name, value)
        setFilter({ ...filter, [name]: value })
    }

    useEffect(() => {
        filter.dateFrom && filter.dateTo && fetchStatistics(filter)
    }, [filter])

    useEffect(() => 
        setTags({
            totalRows: filteredStatistics.length,
            items: filteredStatistics.reduce((acc, s) => acc + s.counted_items, 0),
            totalTime: hoursFromSeconds(filteredStatistics.reduce((acc, s) => acc + s.total_work_time, 0))
        })
    , [filteredStatistics])
    

    return (
        <Card>
            <CardBody>
               
                <Stack direction='row' spacing={4} align='center' justify='end'>    
                    <Button onClick={download} 
                            size='sm' colorScheme='teal' mr='1' 
                            isLoading={isLoading} loadingText='Downloading...'
                            justifySelf='end'
                            >
                                Export
                    </Button>       
                </Stack>
                <Stack direction='row' spacing={4} align='center'>
                    <Box>
                        <FormControl>
                            <FormLabel>Date from</FormLabel>
                            <Input type='date' name='dateFrom' onChange={handleFilterChange}/>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel>Date to</FormLabel>
                            <Input type='date' name='dateTo' onChange={handleFilterChange}/>
                        </FormControl>
                    </Box>
                </Stack>
                           
                <TableContainer>
                    <Table variant='striped'>
                        <TableCaption placement={'top'}>
                            <Stack direction='row' spacing={4}>
                                <Tag colorScheme='purple'>TotalRows: { tags.totalRows } </Tag>
                                <Tag colorScheme='blue'>Items: { tags.items }</Tag>
                                <Tag colorScheme='green'>Total time: { tags.totalTime }</Tag>
                            </Stack>
                     
                        </TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Date</Th><Th>Company</Th><Th>Branch</Th><Th>User</Th><Th>Working Hours</Th><Th>Items Counted</Th><Th>Items per hour</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredStatistics.map(statistic => 
                                    <Tr key={statistic._id}>
                                        {formatRow(statistic).map((cell, i) => <Td key={i}>{cell}</Td>)}
                                    </Tr>
                                )}
                            </Tbody>
                    </Table>
                </TableContainer>
            </CardBody>
        </Card>
    )

}