import {Card, CardBody} from '@chakra-ui/react'
import {RowsDoubleTable} from './RowsDoubleTable/RowsDoubleTable'
import {useRowsDouble} from '../../../hooks/useRows/useRowsDouble'

export const RowsDouble = (props) => {
    const {countingId, refreshCounting} = props
    const {rowsDouble, getRowsDouble, rowsDoubleTotal, loading} = useRowsDouble(countingId)

    return (
        <Card>
            <CardBody>
                <RowsDoubleTable
                    rowsDouble={rowsDouble}
                    countingId={countingId}
                    getRowsDouble={getRowsDouble}
                    rowsDoubleCount={rowsDoubleTotal}
                    refreshCounting={refreshCounting}
                    loading={loading}
                />
            </CardBody>
        </Card>
    )
}
