import {useState, useEffect} from 'react'
import {Table, TableCaption, TableContainer, Tbody, Th, Td, Thead, Tr, Input, Textarea, Tfoot, Button, Select } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { api } from 'apis/configs/axiosConfigs'

export const DeviceCount = (props) => {
    const { countingId } = useParams()
    const [ devices, setDevices ] = useState([])
    const [ users, setUsers ] = useState([])


    useEffect(() => {
        const fetchData = async () => {
            const { data }  = await api.get('/counting/devices-statistics/' + countingId )
            data.devices.forEach((device, idx) => {
                const sameIndex = data.added_devices.findIndex(added_device => 
                    added_device.device_number === device.device_number 
                    && added_device.user_id === device.user_id
                )
                if(sameIndex !== -1) {
                    const sameD = data.added_devices.splice(sameIndex, 1)[0]
                    data.devices[idx].device_amount = sameD.device_amount
                    data.devices[idx].notes = sameD.notes
                }
            })
            data.added_devices.forEach(device => {
                data.devices.push(device)
            })

            data.devices.sort((a, b) => a.device_number - b.device_number)

            setDevices(data.devices)
            setUsers(data.users)
        }
        fetchData()
    }, [])
    return (
        <TableContainer>
            <Table variant='striped'>
                <TableCaption placement={'top'}>
                    <Button colorScheme={'green'} size={'sm'} float='left' onClick={()=>
                        setDevices([...devices, {
                            user_name: '',
                            device_number: null,
                            amount: 0,
                            device_amount: 0,
                            notes: ''
                        }])
                    }>New</Button>
                    <Button colorScheme={'blue'} size={'sm'} float='right' onClick={() => {
                        
                        api.post('/counting/devices-statistics/' + countingId, { devices }).then(resp => {
                            console.log(resp)
                        })

                    }}> Save </Button>
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>User</Th>
                        <Th>Device</Th>
                        <Th>Amount on server</Th>
                        <Th>Amount on device</Th>
                        <Th>Delta</Th>
                        <Th>Notes</Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {
                        devices.map((row, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{typeof row.user !== 'undefined' ? `${row.user[0].name} ${row.user[0].last_name}` :
                                        <Select placeholder='Select user' value={row.user_id ?? null} onChange={(e) => {
                                            setDevices(devices.map((device, i) => 
                                                i === index ? { ...device, user_id: e.target.value } : device
                                            ))
                                        }}>
                                            { users.map((user, index) => {
                                                return (
                                                    <option key={index} value={user._id}>{`${user.name} ${user.last_name}`}</option>
                                                )
                                            }) }
                                        </Select>
                                    }</Td>
                                    <Td>{row.amount ? row.device_number : <Input name='device_number' borderColor='green' value={row.device_number} onChange={(e) =>{
                                            setDevices(devices.map((device, i) => 
                                                i === index ? { ...device, device_number: e.target.value } : device
                                            ))  
                                    }}/>
                                    }</Td>
                                    <Td>{row.amount}</Td>
                                    <Td> <Input borderColor='green' value={row.device_amount ?? ''} onChange={(e) => {
                                            setDevices(devices.map((device, i) => 
                                                i === index ? { ...device, device_amount: e.target.value } : device
                                            ))
                                        }
                                    }/> </Td>
                                    <Td>{row.amount - (row.device_amount ?? 0) }</Td>
                                    <Td> <Textarea borderColor='green' value={row.notes ?? ''} onChange={(e) => {
                                            setDevices(devices.map((device, i) => 
                                                i === index ? { ...device, notes: e.target.value } : device
                                            ))
                                        }
                                    }/> </Td>
                                </Tr>
                            )
                        })

                    }
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td>Total</Td>
                        <Td></Td>
                        <Td> { devices.reduce((sum, i) => sum + i.amount, 0)} </Td>
                        <Td> { devices.reduce((sum, i) => sum +  parseInt(i.device_amount ?? 0), 0)} </Td>
                        <Td> { devices.reduce((sum, i) => sum + i.amount - (i.device_amount ?? 0) , 0)} </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}