import { useState } from 'react'
import {useToast} from '@chakra-ui/react'
import {CountingAPI} from '../../apis/CountingAPI'
import {useApiToast} from '../useApi/useApiToast'

export const useRowsDoubleEdit = (initialRowName, deviceName, amount, userId, countingId, getRowsDouble, refreshCallback) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedRowName, setEditedRowName] = useState(initialRowName)
    const toast = useToast()
    const apiToast = useApiToast()

    initialRowName = initialRowName.toString()

    const handleEdit = () => {
        setIsEditing(true)
    }

    const updateRowName = async () => {
        let resp = await CountingAPI.updateRowNameForUser(countingId, userId, deviceName, initialRowName, editedRowName)
        apiToast(resp)
        console.log('resp', resp)
    }

    const handleSave = async () => {
        setIsEditing(false)

        if (editedRowName.toString() === initialRowName.toString()) {
            console.log('rowName not changed')
            toast({
                title: 'rowName not changed',
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
            return false
        }
        console.log('update rowName from ', initialRowName, ' to ', editedRowName, ' for device ', deviceName, ' and user ', userId, ' in counting ', countingId)
        setIsEditing(false)
        await updateRowName()
        getRowsDouble()
        refreshCallback()

    }

    const handleInputChange = (event) => {
        setEditedRowName(event.target.value)
    }

    const handleDelete = async () => {
        console.log('delete rowName ', initialRowName, ' for device ', deviceName, ' and user ', userId, ' in counting ', countingId)
        if (window.confirm('Are you sure?')) {
            await deleteRow()
            refreshCallback()
        }
    }

    const deleteRow = async () => {
        let resp = await CountingAPI.deleteRowForUser(countingId, userId, deviceName, initialRowName)
        apiToast(resp)
        console.log('resp', resp)
        getRowsDouble()
    }


    return {
        isEditing,
        editedRowName,
        handleEdit,
        handleSave,
        handleInputChange,
        handleDelete
    }
}
