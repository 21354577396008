import {useToast} from '@chakra-ui/react'
import {useCallback} from 'react'

export function useApiToast() {
    const toast = useToast()

    return useCallback((resp) => {
        let toastStatus = resp?.status !== 0 ? 'error' : 'info'
        toast({
            description: resp.message,
            status: toastStatus,
            duration: 5000,
            isClosable: true,
        })
    }, [toast])
}
