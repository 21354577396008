import {Button} from '@chakra-ui/react'
import {CountedItemAPI} from '../../../../apis/CountedItemAPI'
import {useApiToast} from '../../../../hooks/useApi/useApiToast'

export const CountedItemDelete = (props) => {
	const {id, refreshTable, refreshCounting}  = props
	const apiToast = useApiToast()
	const handleDeleteCountedItem = async () => {
		if (window.confirm('Are you sure you want to delete this item?')) {
			console.log('delete item', id)
			const resp = await CountedItemAPI.delete(id)
			apiToast(resp)
			refreshTable()
			refreshCounting()
		}

	}
	return (
		<Button
			onClick={() => handleDeleteCountedItem()}
			size='sm'
			colorScheme='red'
		>
			Delete
		</Button>
	)
}