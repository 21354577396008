import {Button} from '@chakra-ui/react'
import React from 'react'
import {CompanyAPI} from '../../../../apis/CompanyAPI'
import {useApiToast} from '../../../../hooks/useApi/useApiToast'

export const CompanyDeleteButton = (props) => {
    const {id, fetchCompanies} = props
    const showToast = useApiToast()
    const deleteCompany = async () => {
        if (window.confirm('Are you sure you want to delete this company?')) {
            console.log('delete store', id)
            let resp = await CompanyAPI.delete(id)
            showToast(resp)
            fetchCompanies()
        }
    }
    return (
        <>
            <Button mr={2} onClick={deleteCompany} colorScheme='red' size='sm'>Delete</Button>
        </>
    )
}