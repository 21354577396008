import { useState } from 'react'
import { useApiToast } from '../useApi/useApiToast'
import { CountedItemAPI } from '../../apis/CountedItemAPI'

export const useCountedItemEdit = ({
                                itemId,
                                initialUpc,
                                initialMkt,
                                initialAmount,
                                refreshCallback,
                                editFields = ['upc', 'mkt_1', 'amount', 'deleted_at']
                            }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedUpc, setEditedUpc] = useState(initialUpc)
    const [editedMkt, setEditedMkt] = useState(initialMkt)
    const [editedAmount, setEditedAmount] = useState(initialAmount)
    const apiToast = useApiToast()

    const handleEdit = () => {
        setIsEditing(true)
    }

    const handleSave = async () => {
        setIsEditing(false)
        await updateItem()
        refreshCallback()
    }

    const updateItem = async () => {
        const updateData = {
            upc: editedUpc,
            mkt_1: editedMkt,
            amount: editedAmount,
            deleted_at: null
        }

        const filteredData = Object.keys(updateData)
            .filter(key => editFields.includes(key))
            .reduce((obj, key) => {
                obj[key] = updateData[key]
                return obj
            }, {})

        let resp = await CountedItemAPI.update(itemId, filteredData)
        apiToast(resp)
    }

    const handleInputChange = (field) => (event) => {
        if (field === 'upc') setEditedUpc(event.target.value)
        if (field === 'mkt_1') setEditedMkt(event.target.value)
        if (field === 'amount') setEditedAmount(event.target.value)
    }

    return {
        isEditing,
        editedValues: { upc: editedUpc, mkt_1: editedMkt, amount: editedAmount },
        handleEdit,
        handleSave,
        handleInputChange
    }
}