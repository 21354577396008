import {InputEditable} from '../../../Inputs/InputEditable/InputEditable'
import React from 'react'

export const TesterAmount = (props) => {
    const { amount, handleChange } = props

    // Эта функция будет вызвана, когда значение InputEditable изменится
    const handleInputEditableChange = (field) => (event) => {
        //event.target.value == parseInt(event.target.value) &&
        handleChange(event, field)
    }

    return (
        <>
            <InputEditable type='number' value={amount} isEditing={true} onChange={handleInputEditableChange} />
        </>
    )
}
