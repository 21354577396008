import { useApiToast } from 'hooks/useApi/useApiToast'
import {api} from './configs/axiosConfigs'

export const RowAPI = {
    getAll: async function (filters) {
        const response = await api.request({
            url: '/rows',
            method: 'GET',
            params: filters,
        })
        return response.data
    },

    itemsAmount: async function (counting_id, rows) {
        const response = await api.request({
            url: '/rows/items-amount',
            method: 'GET',
            params: {counting_id, rows}
        })
        return response.data
    },

    getComparation: async function (counting_id, rows, checked_counting) {
        if (!checked_counting || !rows?.length) return
        const response = await api.request({
            url: '/rows/compare-countings',
            method: 'GET',
            params: {counting_id, rows, checked_counting}
        })
        return response.data
    },

    getUnpaginated: async function (filters) {
        const response = await api.request({
            url: '/rows/all',
            method: 'GET',
            params: filters
        })

        return response.data
    },

    getCategoryFree: async function (filters) {
        const response = await api.request({
            url: '/rows/category-free',
            method: 'GET',
            params: filters,
        })
        return response.data
    },

    delete: async function (rowId) {
        const response = await api.request({
            url: `/rows/${rowId}`,
            method: 'DELETE',
        })
        return response.data
    },

    update: async function (rowId, data) {
        const response = await api.request({
            url: `/rows/${rowId}`,
            method: 'PUT',
            data,
        })
        return response.data
    }

}
