import { Button, Td } from '@chakra-ui/react'
import {RowsDoubleDeleteButton} from '../RowsDoubleDeleteButton/RowsDoubleDeleteButton'

export const RowsDoubleActionButtons = ({ isEditing, onEdit, onSave, onDelete }) => {
	return (
		<Td>
			{isEditing ? (
				<Button size='sm' colorScheme='green' onClick={onSave} mr={1}>
					Save
				</Button>
			) : (
				<Button size='sm' colorScheme='blue' onClick={onEdit} mr={1}>
					Edit
				</Button>
			)}
			<RowsDoubleDeleteButton onDelete={onDelete} />
		</Td>
	)
}
