import {
	Card,
	CardBody,
	Stack,
	Heading,
	Table,
	TableCaption,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr, Button
} from '@chakra-ui/react'
import React from 'react'
import {WorkingHoursTableRow} from './WorkingHoursTableRow/WorkingHoursTableRow'
import {WorkingHoursExportButton} from '../WorkingHoursExportButton/WorkingHoursExportButton'
import { Filters } from 'components/Filters'
import {useWorkingHours} from 'hooks/useWorkingHours/useWorkingHours'
import {useParams} from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'
import Pagination from 'components/Pagination/Pagination'
import {WorkingHoursFilters} from './WorkingHoursFilters/WorkingHoursFilters'


export const WorkingHoursTable = (props) => {

	const { setFilteredHours } = props
	
	const { userId } = useParams()
	const { workingHours, updateWorkingHours, handleDeleteWorkingHour, handlePageChange, filters, setFilters, deleteMany } = useWorkingHours(userId)
	
	return (
		<div ><Heading m={3} size='md'> Working Hours </Heading>
		{/* <Card m={2} > */}
			<Stack direction='row' spacing={4} align='center' justify='end'>
				
				<WorkingHoursExportButton filters={filters} setFilteredHours={setFilteredHours} />
				{filters?.dateFrom && filters?.dateTo && <Button colorScheme='red' size='sm' onClick={() => {
					deleteMany()
				}}>Delete</Button>}
			</Stack>
			{/* <CardBody > */}
				<TableContainer >
					<Table variant='striped'>
						<TableCaption>List of the Working Hours</TableCaption>
						<Thead>
							<Tr>
								<Th width='30px'>User/TZ</Th>
								{/* <Th size='sm'>TZ</Th> */}
								<Th width='30px'>Date</Th>
								<Th width='30px'>Driving Start</Th>
								<Th width='30px'>Working (period)</Th>
								{/* <Th>Working Finish</Th> */}
								<Th width='30px'>Driving Finish</Th>
								<Th>Driving</Th>
								<Th>Work</Th>
								<Th>Total</Th>
								<Th>Company/Shop</Th>
								{/* <Th>Shop</Th> */}
								<Th>Actions</Th>
							</Tr>
							{/* <WorkingHoursFilters workingHours={workingHours} updateWorkingHours={updateWorkingHours} /> */}
							<Tr>
								<Filters
									filters={filters ?? {}}
									setFilters={setFilters}
								/>
							</Tr>
						</Thead>
						<Tbody >
							{ workingHours?.data.map(workingHour => (
								<WorkingHoursTableRow
									key={workingHour._id}
									id={workingHour._id}
									userId={workingHour.user_id}
									createdAt={workingHour.created_at}
									totalTime={workingHour.total_time}
									totalWorkTime={workingHour.total_work_time}
									totalDriveTime={workingHour.total_drive_time}
									storeName={workingHour.counting?.store?.name}
									companyName={workingHour.counting?.store?.company?.name}
									timeDrivingStart={workingHour.time_driving_to_work_opened}
									timeDrivingToWorkClosed={workingHour.time_driving_to_work_closed}
									timeWorkingStart={workingHour.time_work_opened}
									timeWorkingClosed={workingHour.time_work_closed}
									timeDrivingFromWorkStart={workingHour.time_driving_from_work_opened}
									timeDrivingClosed={workingHour.time_driving_from_work_closed}
									userTz={workingHour.user?.identify}
									name={`${workingHour.user?.name} ${workingHour.user?.last_name}`}
									date={workingHour.created_at}
									updateWorkingHours={updateWorkingHours}
									workingHour={workingHour}
									handleDeleteWorkingHour={handleDeleteWorkingHour}
								/>
							))}
						</Tbody>
					</Table>
					{ !workingHours?.data && <Spinner/> }
					<Pagination lastPage={ workingHours?.last_page ?? 1 } getData={ handlePageChange } />
				</TableContainer>
			{/* </CardBody> */}
		{/* </Card> */}
		</div>
	)
}