import {Card, CardBody, FormLabel, Input} from '@chakra-ui/react'

export const CompanyModalForm = ({values, handleChange}) => (
    <Card>
        <CardBody>
            <FormLabel>Name</FormLabel>
            <Input name='name' value={values.name} onChange={handleChange}/>
            <FormLabel>Catalog file</FormLabel>
            <Input name='file' value={values.file} onChange={handleChange}/>
        </CardBody>
    </Card>
)