import {Box, Button, Checkbox, Input, Link, Td, Tr, Select, Badge} from '@chakra-ui/react'
import {NavLink} from 'react-router-dom'
import DeleteCountingButton from './DeleteCounting/DeleteCountingButton'
import {DateYMD} from '../../Date/DateYMD'
import {CountingManagersSelect} from '../CountingManagers/CountingManagersSelect'
import {useCounting} from '../../../hooks/useCounting/useCounting'
import useAuthStore from '../../../stores/authStore'

export const CountingRow = (props) => {
    const { managers, managerSelected, handleSelectCounting, counting } = props
    const {managerSelectedId, handleManagerChange} = useCounting(counting._id, managerSelected)
    const user = useAuthStore((state) => state.user)
    const manager = managers.find(manager => manager._id === managerSelectedId) ?? (user?.is_shift_manager && user)

    return (
        <Tr key={counting._id}>
            <Td>
                <Checkbox onChange={()=>{handleSelectCounting(counting._id)}} border={'1px'}/>
            </Td>
            <Td>
                {<DateYMD date={counting.date_start || counting.created_at}/>}
            </Td>
            <Td>
                    {counting?.store?.company?.name}
            </Td>
            <Td>
                <Badge ml={2} colorScheme='red' hidden={!counting.checked_counting}>CONTROL</Badge>
                <Link as={NavLink} to={`/counting/${counting._id}`}>{counting.store?.name}</Link>
                
            </Td>
            <Td>
                {counting.store?.code}
            </Td>
            <Td>
                {(user?.is_shift_manager || user?.is_admin)
                    ?    <Select value={managerSelectedId ?? (user?.is_shift_manager && user._id)} onChange={handleManagerChange}>
                            <option value={manager?._id ?? ''}>{manager ? `${manager.name} ${manager.last_name}` : 'Select manager'}</option>
                            {managers.map((manager) => (
                                <option key={manager._id} value={manager._id}>{manager.name} {manager.last_name}</option>
                            ))}
                        </Select>
                    : (manager && <>{manager.name} {manager.last_name}</>)
                }
            </Td>
            <Td>
                {counting.counted_items_amount}
            </Td>
            <Td>
                {counting.rows_missed_amount}
            </Td>
            <Td>
                {counting.rows_double_amount}
            </Td>
            <Td>

            </Td>
        </Tr>
    )
}