import React, {useEffect} from 'react'
import CompaniesTable from './CompaniesTable/CompaniesTable'
import {useApi} from '../../hooks/useApi/useApi'

const Companies = () => {

    const {companies, fetchCompanies} = useApi()

    useEffect(() => {
        fetchCompanies()
    }, [])
    // useEffect(() => {
    //     console.log('companies', companies)
    // }, [companies])
    return (
        <div>
            <CompaniesTable fetchCompanies={fetchCompanies} companies={companies}/>
        </div>
    )
}

export default Companies