import {Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr, Button} from '@chakra-ui/react'
import {RowsCountedTableRow} from './RowsCountedTableRow'
import {RowsCountedTableCaption} from './RowsCountedTableCaption/RowsCountedTableCaption'
import { Captions } from '../../Captions'
import {RowsCountedFilters} from './RowsCountedFilters/RowsCountedFilters'
import {useTesters} from '../../../../hooks/useTesters/useTesters'
import Pagination from '../../../Pagination/Pagination'
import {useDownloadCountedRows} from 'hooks/useDownload/useDownloadCountedRows'
import { useContext } from 'react'
import { CountingTabsContext } from 'components/Counting/CountingTabs/CountingTabs'
import { useState, useEffect } from 'react'
import { RowAPI } from 'apis/RowAPI'



export const RowsCountedTable = (props) => {
	const {rows, totalRows, getRowsCounted, countingId, refreshCounting, handlePageChange} = props
	const handleFilterChange = (newFilters) => {
		getRowsCounted(newFilters)
	}

	const refreshTable = () => {
		// console.log('RowsCountedTable refreshTable')
		getRowsCounted({})
		refreshCounting()
	}

	const refreshTesters = () => {
		getTesters()
		getRowsCounted({})
	}
	const {testers, addTester, testerDefault, getTesters} = useTesters(countingId, refreshTable)
	
	const { counting } = useContext(CountingTabsContext)
	
	const { downloadCountedRows, isLoading } = useDownloadCountedRows( testers, counting)

	const [ itemsAmount, setItmesAmount ] = useState([])
	const [ totalAmount, setTotalAmount] = useState(0)
	const [ totalTested, setTotalTested ] = useState(0)

	const [ diff, setDiff ] = useState({})

	const fetchItemsCounted = async () => {
		if (!rows?.data) return
		const resp = await RowAPI.itemsAmount( counting._id, rows.data.map(row => row.name))
		setItmesAmount(resp.amounts)
		setTotalAmount(resp.totalAmount)
		setTotalTested(resp.totalTested)
	}

	const getComparation = async () => {
		if (!rows?.data) return
		const resp = await RowAPI.getComparation( counting._id, rows.data.map(row => row.name), counting.checked_counting )
		console.log('getComparation', resp)
		resp?.data && setDiff(resp.data)
	} 

	useEffect(() => {
		fetchItemsCounted()
	}, [rows])

	useEffect(() => {
		counting.checked_counting && getComparation()
	}, [counting, rows])

	return (
		<>
			<TableContainer>
				<Table variant='striped'>
					<TableCaption placement={'top'}>
						<Captions
							amount={totalAmount}
							total={totalRows}
							testers={testers}
							totalTested={totalTested}
							addTester={addTester}
							counting={counting}
						/>
					</TableCaption>
					<Thead>
						<Tr>
							<RowsCountedFilters countingId={countingId} onFilterChange={handleFilterChange} counting={counting} />
						</Tr>
						<Tr>
							<Th>Test</Th>
							<Th>Row</Th>
							<Th>Amount</Th>
							{ counting.settings?.customRowNames && <Th>Custom Name</Th> }
							<Th>Category</Th>
							<Th>User</Th>
							<Th>Device</Th>
							<Th>Start</Th>
							<Th>Finish</Th>
							<Th>Amount T</Th>
							<Th>Tester</Th>
							<Th>
								<Button colorScheme={'green'} size={'sm'} onClick={downloadCountedRows} isLoading={isLoading}>Export</Button>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{rows?.data?.map(row => (
							<RowsCountedTableRow
								key={row._id}
								row={row}
								counting={counting}
								diffRow={diff[row.name] ?? null}
								amount={ itemsAmount[`${row.name}_${row.user_id}_${row.device_number}`] ?? 0 /*row.amount*/}
								refreshTable={refreshTable}
								testers={testers}
								testerDefault={row.tester_id && row.tester_id.length > 0 ? row.tester_id : testerDefault}
								getTesters={refreshTesters}
							/>
						))}
					</Tbody>
				</Table>
				<Pagination lastPage={rows.last_page || 1} getData={handlePageChange} />
			</TableContainer>
		</>
	)
}