import {Card, CardBody} from '@chakra-ui/react'
import {useRowsCounted} from '../../../hooks/useRows/useRowsCounted'
import {RowsCountedTable} from './RowsCountedTable/RowsCountedTable'

export const RowsCounted = (props) => {
	const {countingId, refreshCounting, counting} = props
	const {rows, totalRows, totalAmount, totalTested, getRowsCounted, handlePageChange} = useRowsCounted({counting_id: countingId})

	return (
		<>
			<Card>
				<CardBody>
					<RowsCountedTable
						getRowsCounted={getRowsCounted}
						totalRows={totalRows}
						totalAmount={totalAmount}
						totalTested={totalTested}
						rows={rows}
						countingId={countingId}
						refreshCounting={refreshCounting}
						handlePageChange={handlePageChange}
					/>
				</CardBody>
			</Card>
		</>
	)
}