import {Checkbox, Td, Tr} from '@chakra-ui/react'
import {memo, useEffect, useState} from 'react'
import {ItemsInvalidTableRowButtons} from '../ItemsInvalidTableRowButtons/ItemsInvalidTableRowButtons'
import {useItemsInvalidEdit} from '../../../../../../hooks/useItems/useItemsInvalidEdit'
import {InputEditable} from '../../../../../Inputs/InputEditable/InputEditable'

const ItemsInvalidTableRow = memo((props) => {
    const { id, upc, mkt, row, device, amount, name, selectAll, refreshTable, onCheckboxChange, refreshCounting } = props
    const [isChecked, setIsChecked] = useState(false)
    const {
        isEditing,
        editedValues,
        handleEdit,
        handleSave,
        handleInputChange,
    } = useItemsInvalidEdit(id, refreshTable, upc, mkt, amount)

    const handleCheckboxChange = (event) => {
        const isCheckedNow = event.target.checked
        requestAnimationFrame(() => {
            setIsChecked(isCheckedNow)
            onCheckboxChange(id, isCheckedNow)
        })
    }


    useEffect(() => {
        setIsChecked(selectAll)
    }, [selectAll])

    return (
        <Tr key={id}>
            <Td>
                <Checkbox border={'1px'} isChecked={isChecked} onChange={handleCheckboxChange} />
            </Td>
            <Td>
                <InputEditable isEditing={isEditing} value={editedValues.upc} onChange={handleInputChange} field='upc' />
            </Td>
            <Td>
                <InputEditable isEditing={isEditing} value={editedValues.mkt_1} onChange={handleInputChange} field='mkt_1' />
            </Td>
            <Td>{row}</Td>
            <Td>{amount}</Td>
            <Td>{device}</Td>
            <Td>{name}</Td>
            <Td>
                <ItemsInvalidTableRowButtons
                    onEdit={handleEdit}
                    onSave={handleSave}
                    isEditing={isEditing}
                    id={id}
                    refreshTable={refreshTable}
                    refreshCounting={refreshCounting}
                />
            </Td>
        </Tr>
    )
})

ItemsInvalidTableRow.displayName = 'ItemsInvalidTableRow'

export default ItemsInvalidTableRow
