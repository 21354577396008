import {useEffect, useState} from 'react'

function useForm(initialValues) {
    const [values, setValues] = useState(initialValues)

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        setValues({
            ...values,
            [event.target.name]: value,
        })
        return value
    }

    const clearValues = () => {
        setValues(initialValues)
    }

    // useEffect(() => {
    //     setValues(initialValues)
    // }, [initialValues])

    return {values, handleChange, clearValues, setValues}
}


export default useForm