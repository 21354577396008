import {useState} from 'react'
import {useToast} from '@chakra-ui/react'

export const useFormValidation = () => {
    const [errors, setErrors] = useState([])
    const toast = useToast()

    const validateUser = (user) => {
        let newErrors = []
        if (!user.identify) {
            newErrors.push('identify empty')
        }
        if (!user.name) {
            newErrors.push('name empty')
        }
        if (!user.last_name) {
            newErrors.push('last name empty')
        }

        setErrors(newErrors)
        if (newErrors.length > 0) {
            newErrors.forEach(error => {
                toast({
                    title: 'Validation Error',
                    description: error,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
        }

        return newErrors
    }

    return { errors, validateUser }
}