import {WorkingHoursTable} from './WorkingHoursTable/WorkingHoursTable'
import {Spinner} from '@chakra-ui/react'
import {useEffect, useState} from 'react'

export const WorkingHours = () => {
	
	const [filteredHours, setFilteredHours] = useState()
	
	return (
		<>
			{/*!filteredHours ? <Spinner/> : */ <WorkingHoursTable
				workingHours={filteredHours}
				setFilteredHours={setFilteredHours}
			/>}
		</>
	)
}