import {Checkbox, Input, Select, Th} from '@chakra-ui/react'
import {useFilters} from '../../../../../hooks/useFilters/useFilters'
import {useCallback} from 'react'

export const RowsCountedFilters = (props) => {
	const {countingId, onFilterChange, counting} = props
	const {handleInputChange, handleExactMatchChange, handleSelectChange, users, devices, categories, testers} = useFilters(countingId, onFilterChange)

	const handleInputChangeCallback = useCallback((type) => (event) => handleInputChange(type, event.target.value, false), [handleInputChange])
	const handleExactMatchChangeCallback = useCallback((type) => (event) => handleExactMatchChange(type, event.target.checked), [handleExactMatchChange])
	const handleSelectChangeCallback = useCallback((type) => (event) => handleSelectChange(type, event.target.value), [handleSelectChange])
	const filtersConfig = [
		{type: 'test_passed', isSelect: true},
		{type: 'name', placeholder: 'Row', isSelect: false},
		counting.settings?.customRowNames ? {type: 'custom_row_name', placeholder: 'Custom Name', isSelect: false} : null,
		{type: '', isPlaceholder: true},
		{type: 'category_row_id', placeholder: 'Category', isSelect: true},
		{type: 'user_id', placeholder: 'Name', isSelect: true},
		{type: 'device_number', placeholder: 'Device', isSelect: true},
		{type: '', isPlaceholder: true},
		{type: '', isPlaceholder: true},
		{type: '', isPlaceholder: true},
		{type: 'tester_id', placeholder: 'Tester', isSelect: true},

	].filter(i=>i)

	return (
		<>
			{filtersConfig.map((filter, index) =>
				<Th key={index}>
					{filter.isPlaceholder ? null : filter.isSelect ? (
						<Select placeholder={filter.placeholder} onChange={handleSelectChangeCallback(filter.type)}>
							{filter.type === 'user_id' ? users && users.map(user =>
								<option key={user._id} value={`${user.name} ${user.last_name}`}>{`${user.name} ${user.last_name}`}</option>
							) : filter.type === 'device_number' ? devices && devices.map(device =>
								<option key={device} value={device}>{device}</option>
							) : filter.type === 'category_row_id' ? categories && categories.map(category =>
								<option key={category._id} value={category._id}>{category.name}</option>
							) : filter.type === 'tester_id' ? testers && testers.map(tester =>
								<option key={tester._id} value={tester._id}>{tester.name}</option>
							) : (
								<>
									<option value={undefined}>All</option>
									<option value='No'>Yes</option>
									<option value='Yes'>No</option>
								</>
							)}
						</Select>
					) : (
						<>
							<Input placeholder={filter.placeholder} onChange={handleInputChangeCallback(filter.type)} />
							<Checkbox onChange={handleExactMatchChangeCallback(filter.type)}></Checkbox>
						</>
					)}
				</Th>
			)}
		</>
	)
}