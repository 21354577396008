import {RowsFilterSelectOld} from './RowsFilterSelectOld'
import React, {useEffect, useState} from 'react'
import {FormLabel, HStack, VStack} from '@chakra-ui/react'
import {RowsFilterCategoriesOld} from './RowsFilterCategoriesOld'
import {useApi} from '../../../../hooks/useApi/useApi'

export const RowsFilterOld = (props) => {
    const {countingId, getRows} = props
    const {devices, fetchDevices} = useApi()
    const [deviceSelected, setDeviceSelected] = useState()
    const {rowCategories, fetchRowCategories} = useApi()
    const [categorySelected, setCategorySelected] = useState()


    useEffect(() => {
        fetchDevices(countingId)
        fetchRowCategories(countingId)
    }, [])

    useEffect(() => {
        getRows(countingId, deviceSelected, categorySelected)
    }, [deviceSelected, categorySelected])
    return (
        <HStack spacing={4} alignItems='flex-end'>
            <VStack>
                <FormLabel>Device</FormLabel>
                <RowsFilterSelectOld
                    items={devices}
                    itemSelected={deviceSelected}
                    setItemSelected={setDeviceSelected}
                    placeholder={'Select Device'}
                />
            </VStack>
            <VStack>
                <FormLabel>Category</FormLabel>
                <RowsFilterCategoriesOld
                    items={rowCategories}
                    itemSelected={categorySelected}
                    setItemSelected={setCategorySelected}
                    placeholder={'Select Category'}
                />
            </VStack>
        </HStack>
    )
}