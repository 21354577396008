import { Spacer, Tag, Stack, Checkbox, Button } from '@chakra-ui/react'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import DownloadCountingButton from '../../Countings/CountingsTable/DownloadCounting/DownloadCountingButton'
import { CountingTabsContext } from '../CountingTabs/CountingTabs'
import { TesterAdd } from '../Testers/TesterAdd/TesterAdd'
import { CompanyAPI } from 'apis/CompanyAPI'

export const Captions = (props) => {
	const { total, totalAmount, amount, totalRows, testers, totalTested, addTester, countingId, counting, group_items } = props
	const { revisedTabs, toggleRevisedTab } = useContext(CountingTabsContext)
	const [ searchParams ] = useSearchParams()
	const tab = searchParams.get('tab')

	const addControlCounting = async () => {
		const resp = await CompanyAPI.addCounting({
			company_id: counting.store.company._id, 
			store_id: counting.store._id, 
			date_start: counting.date_start,
			checked_counting: counting._id
		})
		if (resp.status === 0) {
			window.location.href = `/counting/${resp.counting._id}`
		}
	}

	const toCounting = (link) => {
		window.location.href =  `/counting/${link}`
	}
	return (
		<Stack direction='row' spacing={4}>
			{total !== undefined && (<Tag colorScheme='blue'>Total: {total}</Tag>)}
			{totalAmount && (<Tag colorScheme='green'>Sum amount: {totalAmount}</Tag>)}
            {amount && (<Tag colorScheme='green'>Amount: {amount}</Tag>)}
            {totalTested !== undefined && (<Tag colorScheme='purple'>Tested: {totalTested}</Tag>)}
			{totalRows && (<Tag colorScheme='purple'>Rows: {totalRows}</Tag>)}
			<Checkbox isChecked={ revisedTabs.includes(tab) } onChange={() => toggleRevisedTab(tab) }> Revised </Checkbox>
			<Spacer />
			{ 
				counting?.checked_counting && <Button color='red' onClick={()=>toCounting(counting.checked_counting)}>To Checked Counting</Button>
			}
					{/* <Link as={NavLink} to={`/counting/${counting.checked_counting}?tab=counted-rows`} title='Original counting'>Control Counting</Link> */}
			{	counting && (!counting.control_counting 
						? <Button onClick={addControlCounting}>Create Control Counting</Button>
						: <Button onClick={()=>toCounting(counting.control_counting)}>To Control Counting</Button>)
			}
			{ !!counting && (
				<>
					<Spacer />
					<DownloadCountingButton countingId={countingId} counting={counting} group_items={group_items}/>
				</>
			)}
            {
                totalTested !==undefined && (<>
                    <Spacer />
                    <TesterAdd testers={testers} addTester={addTester}/>
                </>)
            }
	
		</Stack>
	)
}