import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Card, CardBody, CardHeader, Spinner} from '@chakra-ui/react'
import {CountingTabs} from './CountingTabs/CountingTabs'
import {useApi} from '../../hooks/useApi/useApi'
import {CountingHeader} from './CountingHeader/CountingHeader'

export const Counting = () => {
    const {countingId} = useParams()
    const {counting, fetchCounting, updateCounting, setCounting} = useApi()
    // const [ style, setStyle ] = useState({ position: 'fixed', bottom: '0'})

    useEffect(() => {
        fetchCounting(countingId)
        // changeStyle()
        // window.addEventListener('scroll', changeStyle)

        // return () => {
        //     window.removeEventListener('scroll', changeStyle)
        // }
    }, [])

    // const changeStyle = () => {
    //     console.log(window.scrollY)
    //     if (window.scrollY > 0) {
    //         setStyle({position: 'fixed', top: '0'})
    //     } else {
    //         setStyle({})
    //     }
    // }

    if (!counting) {
        return <Spinner/>  // или любой другой индикатор загрузки
    }

    return (
        <Card>
            <CardHeader style={{ position: 'fixed', bottom: '0', zIndex: '5'}}>
                <CountingHeader counting={counting}/>
            </CardHeader>
            <CardBody>
                <CountingTabs refreshCounting={() => fetchCounting(countingId)} countingId={countingId} counting={counting} updateCounting={updateCounting} setCounting={setCounting}/>
            </CardBody>
        </Card>
    )
}
