import React from 'react'
import {Button, useDisclosure, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react'
import {CountingAPI} from '../../../apis/CountingAPI'
import {useApiToast} from '../../../hooks/useApi/useApiToast'

const CategoryRowDelete = (props) => {
    const {id, fetchCategoryRows, countingId} = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const showToast = useApiToast()
    const deleteCategoryRow = async (id) => {
        console.log('delete category row with id', id)
        const resp = await CountingAPI.deleteCategoryRow(id)
        console.log(resp)
        showToast(resp)
        fetchCategoryRows(countingId)
    }
            // <Button onClick={() => deleteCategoryRow(id)} size='sm' colorScheme='red'>Delete</Button>

    return (
        <>
            <Button colorScheme='red' size='sm' onClick={onOpen}>Delete</Button>

            <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Category
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure? You can&apos;t undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='red' onClick={() => deleteCategoryRow(id)} ml={3}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

export default CategoryRowDelete