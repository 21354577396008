import {useState} from 'react'
import {RowAPI} from '../../apis/RowAPI'
import {useApiToast} from '../useApi/useApiToast'

export const useTesterEditAmount = (rowId, amount, refreshCallback, testerDefault) => {
    const [testerAmount, setTesterAmount] = useState(amount)
    const [testerIdSelected, setTesterIdSelected] = useState(undefined)
    const [isEditing, ] = useState(true)

    const apiToast = useApiToast()
    const handleTesterAmountChange = (event) => {
        setTesterAmount(event.target.value)
    }

    const handleTesterIdSelectedChange = (event) => {
        setTesterIdSelected(event.target.value)
        console.log('testerIdSelected', event.target.value)
    }

    const saveTesterAmount = async () => {
        console.log('saveTesterAmount', testerIdSelected, testerAmount)
        const resp = await RowAPI.update(rowId, {tester_id: testerIdSelected || testerDefault, tester_amount: testerAmount})
        await refreshCallback()
        apiToast(resp)
    }

    return {
        testerAmount,
        testerIdSelected,
        isEditing,
        handleTesterAmountChange,
        handleTesterIdSelectedChange,
        saveTesterAmount,
    }
}