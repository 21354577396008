import {useEffect, useState} from 'react'
import {formatTimeToLocal} from '../../utlis/timeUtils'

export const DateHMS = (props) => {
    const {date} = props
    const [timeShow, setTimeShow] = useState()

    useEffect(() => {
        setTimeShow(formatTimeToLocal(date))
    }, [date])
    return (
        <>
            { date ? timeShow : '' }
        </>
    )
}