import {WorkingHourButton} from '../WorkingHourButton/WorkingHourButton'
import {
	Button, Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay, useDisclosure, FormControl, FormLabel, Input
} from '@chakra-ui/react'
import React, { useState } from 'react'
import useForm from 'hooks/useForm/useForm'
import { isoToDateTimeLocal } from 'utlis/timeUtils'

export const WorkingHourModal = (props) => {
	const {updateWorkingHours} = props
	// const values = props

	const [ values, setValues ] = useState(props)

	const twins = {
		timeDrivingToWorkClosed: 'timeWorkingStart',
		timeWorkingClosed: 'timeDrivingFromWorkStart',
	}


	const handleChange = (e) => {
		const value = e.target.value
        setValues({
            ...values,
            [e.target.name]: value,
			...twins[e.target.name] && {[twins[e.target.name]]: value}
        })
	}

    const {isOpen, onOpen, onClose} = useDisclosure()
	const saveWorkingHour = () => {
		console.log('save working hour')
		console.log('values', values)
		// update only time* fields
		try{
			updateWorkingHours(values.id, {
				time_driving_to_work_opened: values.timeDrivingStart,
				time_driving_from_work_closed: values.timeDrivingClosed,
				time_work_opened: values.timeWorkingStart,
				time_work_closed: values.timeWorkingClosed,
				time_driving_to_work_closed: values.timeDrivingToWorkClosed,
				time_driving_from_work_opened: values.timeDrivingFromWorkStart,
			})
		} catch (e) {
			console.log('saveWorkingHour', 'error', e)
		}

		onClose()
	}

	// useEffect(() => {
	// 	console.log('workingHours', workingHours)
	// 	clearValues()
	// }, [workingHours])

	return (
		<>
			<WorkingHourButton  mode={'edit'} onOpen={onOpen}/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay/>
				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalCloseButton/>
					<ModalBody>
						
								<FormControl>
									<FormLabel>TZ</FormLabel>
									<Input name='identify' value={values.userTz} isDisabled={true} />
								</FormControl>

								<FormControl>
									<FormLabel>Name</FormLabel>
									<Input name='name' value={values.name} isDisabled={true} />
								</FormControl>

								<FormControl>
									<FormLabel>Driving To Work Start</FormLabel>
									<Input type='datetime-local' name='timeDrivingStart' value={isoToDateTimeLocal(values.timeDrivingStart)} onChange={handleChange} />
								</FormControl>
								<FormControl>
									<FormLabel>Driving To Work Finish</FormLabel>
									<Input type='datetime-local' name='timeDrivingToWorkClosed' value={isoToDateTimeLocal(values.timeDrivingToWorkClosed)} onChange={handleChange} />
								</FormControl>
								<FormControl>
									<FormLabel>Working Start</FormLabel>
									<Input type='datetime-local' name='timeWorkingStart' value={isoToDateTimeLocal(values.timeWorkingStart)} onChange={handleChange} />
								</FormControl>

								<FormControl>
									<FormLabel>Working Close</FormLabel>
									<Input type='datetime-local' name='timeWorkingClosed' value={isoToDateTimeLocal(values.timeWorkingClosed)} onChange={handleChange} />
								</FormControl>

								<FormControl>
									<FormLabel>Driving From Work Start</FormLabel>
									<Input type='datetime-local' name='timeDrivingFromWorkStart' value={isoToDateTimeLocal(values.timeDrivingFromWorkStart)} onChange={handleChange} />
								</FormControl>

								<FormControl>
									<FormLabel>Driving Close</FormLabel>
									<Input type='datetime-local' name='timeDrivingClosed' value={isoToDateTimeLocal(values.timeDrivingClosed)} onChange={handleChange} />
								</FormControl>
							
					</ModalBody>
					<ModalFooter>
						<Button onClick={()=>saveWorkingHour()} colorScheme='blue' mr={3}>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}