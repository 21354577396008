import React from 'react'
import {
    Card,
    CardBody,
    CardHeader, Flex,
    Heading,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import CompanyRow from './CompanyRow'
import {CompanyModal} from '../CompanyModal/CompanyModal'

const CompaniesTable = (props) => {
    const {companies, fetchCompanies} = props
    return (
        <Card m={2}>
            <CardHeader>
                <Flex justifyContent='space-between' alignItems='center'>
                    <Heading size='md'> Companies </Heading>
                    <CompanyModal fetchCompanies={fetchCompanies} mode={'new'}/>
                </Flex>
            </CardHeader>
            <CardBody>
                <TableContainer>
                    <Table variant='striped'>
                        <TableCaption>List of the companies</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Actions</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {companies.map((company) => (
                                <CompanyRow fetchCompanies={fetchCompanies} key={company._id} id={company._id}
                                            name={company.name} file={company.file}/>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </CardBody>
        </Card>
    )
}

export default CompaniesTable