import { useState } from 'react'
import {useToast} from '@chakra-ui/react'
import {CompanyAPI} from '../../apis/CompanyAPI'
import {useApiToast} from '../useApi/useApiToast'

export const useFileUpload = (id, onClose) => {

    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState(false)
    const [complete, setComplete] = useState(false)
    const [progress, setProgress] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [goodRowsCount, setGoodRowsCount] = useState(0)
    const [badRowsCount, setBadRowsCount] = useState(0)
    const [tmpFileName, setTmpFileName] = useState('')
    const [importing, setImporting] = useState(false)
    const toast = useToast()
    const apiToast = useApiToast()
    const [isItm, setIsItm] = useState(false)
    const handleFileChange = (event) => {
        setFile(event.target.files[0])
        setUploading(false)
        setComplete(false)
        setError(false)
        setProcessing(false)
        setImporting(false)
    }

    const handleFileUpload = async () => {
        if (file) {
            setUploading(true)
            setComplete(false)
            setError(false)
            let response
            try{
                response = await CompanyAPI.uploadFile(id, file, isItm, (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    if(percentCompleted === 100) {
                        setProcessing(true)
                    }
                    setProgress(percentCompleted)
                })

            } catch (e) {
                console.log('error', e)
                toast({
                    description: 'file_upload_error',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                setError(true)
                setProcessing(false)
                return false
            }

            console.log('response', response.data)
            setTmpFileName(response.tmpFileName)
            setTotalRows(response.totalRows)
            setGoodRowsCount(response.goodRowsCount)
            setBadRowsCount(response.badRowsCount)
            setProcessing(false)
            setComplete(true)
        } else {
            console.log('No file selected')
        }
    }

    const handleSave = async () => {
        setImporting(true)
        let resp
        try {
            resp = await CompanyAPI.catalogReplaceFile(id, tmpFileName)
        } catch (e) {
            console.log('error', e)
        } finally {
            setError(true)
            setImporting(false)
        }
        setImporting(false)
        apiToast(resp)
        if(resp.status === 0){
            onClose()
        }
    }
    const handleChangeItm = (ß) => {
        setIsItm(!isItm)
    }

    return {
        file,
        uploading,
        processing,
        importing,
        error,
        complete,
        progress,
        totalRows,
        goodRowsCount,
        badRowsCount,
        tmpFileName,
        handleFileChange,
        handleFileUpload,
        handleSave,
        isItm,
        handleChangeItm,
    }
}
