import {api} from './configs/axiosConfigs'
import {defineCancelApiObject} from './configs/axiosUtils'

export const CountingAPI = {
    getCategoryRows: async function (countingId, cancel = false) {
        const response = await api.request({
            url: `/counting/category-row/${countingId}`,
            method: 'GET',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    updateCategoryRow: async function (categoryRow, cancel = false) {
        const data = {
            name: categoryRow.name,
            row_from: categoryRow.rowFrom,
            row_to: categoryRow.rowTo,
        }
        const response = await api.request({
            url: `/counting/category-row/${categoryRow.id}`,
            method: 'PUT',
            data: data,
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    createCategoryRow: async function (categoryRow, cancel = false) {
        const data = {
            name: categoryRow.name,
            row_from: categoryRow.rowFrom,
            row_to: categoryRow.rowTo,
        }
        const response = await api.request({
            url: `/counting/${categoryRow.countingId}/category-row`,
            method: 'POST',
            data: data,
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    deleteCategoryRow: async function (categoryRowId, cancel = false) {
        const response = await api.request({
            url: `/counting/category-row/${categoryRowId}`,
            method: 'DELETE',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getMissingRows: async function (countingId, cancel = false, filters) {
        const response = await api.request({
            url: `/counting/missing-rows/${countingId}`,
            method: 'GET',
            params: filters,
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    deleteCounting: async function (countingId, cancel = false) {
        const response = await api.request({
            url: `/counting/${countingId}`,
            method: 'DELETE',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    jsonUpload: async function (formData) {
        const response = await api.request({
            url: '/json/upload', // Use your actual upload endpoint
            method: 'POST',
            // signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,

            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    },

    photoUpload: async function (countingId, formData) {
        const response = await api.request({
            url: `/counting/photo/upload/${countingId}`,
            method: 'POST', 
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    },

    photoList: async function (countingId) {
        const response = await api.request({
            url: `/counting/photo/list/${countingId}`,
            method: 'GET',
        })

        return response.data
    },

    getRowsOld: async function (countingId, deviceName = null, categoryId = null) {
        const response = await api.request({
            url: `/rows/old/${countingId}?device_name=${deviceName}&category_id=${categoryId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    },

    getDevices: async function (countingId) {
        const response = await api.request({
            url: `/counting/devices/${countingId}`,
            method: 'GET',
        })

        return response.data
    },

    getRowsDouble: async function (countingId) {
        const response = await api.request({
            url: `/rows/double/${countingId}`,
            method: 'GET',
        })

        return response.data
    },

    getItemsInvalid: async function (countingId, filters = {}) {
        const response = await api.request({
            url: `/items/invalid/${countingId}`,
            method: 'GET',
            params: filters,
        })

        return response.data
    },

    getCounting: async function (countingId) {
        const response = await api.request({
            url: `/counting/${countingId}`,
            method: 'GET',
        })

        return response.data
    },
    getRowsEmpty: async function (countingId) {
        const response = await api.request({
            url: `/rows/empty/${countingId}`,
            method: 'GET',
        })

        return response.data
    },
    getItemsEdited: async function (countingId) {
        const response = await api.request({
            url: `/items/edited/${countingId}`,
            method: 'GET',
        })

        return response.data
    },

    updateRowNameForUser: async function (countingId, userId, deviceNumber, rowNameOld, rowNameNew) {
        const response = await api.request({
            url: `/counting/${countingId}/update-row-name-for-user`,
            method: 'PUT',
            data: {
                row_name_old: rowNameOld,
                row_name_new: rowNameNew,
                user_id: userId,
                device_number: deviceNumber,
            }

        })
        return response.data
    },

    deleteRowForUser: async function (countingId, userId, deviceNumber, rowName) {
        const response = await api.request({
            url: `/counting/${countingId}/delete-row-for-user`,
            method: 'DELETE',
            data: {
                row_name: rowName,
                user_id: userId,
                device_number: deviceNumber,
            }
        })
        return response.data
    },
    getUsers: async function (countingId) {
        const response = await api.request({
            url: `/counting/${countingId}/users`,
            method: 'GET',
        })

        return response.data
    },
    createTester: async function (countingId, testerName) {
        const response = await api.request({
            url: `/counting/${countingId}/testers`,
            method: 'POST',
            data: {
                name: testerName,
            }
        })
        return response.data
    },
    getAll: async function (filters, user, cancel = false) {
        
        if(user.is_shift_manager && !(user.is_admin || user.is_counting_manager)) {
            filters.manager_id = user._id
        }
        const response = await api.request({
            url: '/counting/get-all',
            method: 'GET',
            params: {...filters},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    update: async function (countingId, data, cancel = false) {
        const response = await api.request({
            url: `/counting/${countingId}`, //?_method=PUT`,
            method: 'POST',
            data: data,
        })

        return response.data
    }
}

const cancelApiObject = defineCancelApiObject(CountingAPI)