import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons'

export const ItemsEditedHeaderAmount = (props) => {
    const {requestSort, sortConfig} = props
    return (
        <>
			<span onClick={() => requestSort('amount')} style={{cursor: 'pointer'}}>
				Amount
                {sortConfig && sortConfig.key === 'amount' ? (
                    sortConfig.direction === 'ascending' ? <ChevronDownIcon boxSize={4}/> : <ChevronUpIcon boxSize={4}/>
                ) : null}
			</span>
        </>
    )
}