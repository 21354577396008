import {api} from './configs/axiosConfigs'

export const CountedItemAPI = {
    update: async function (itemId, data) {
        const response = await api.request({
            url: `/counted-items/${itemId}`,
            data: data,
            method: 'PUT',
        })

        return response.data
    },
    getAll: async function (filters) {
        const response = await api.request({
            url: '/counted-items',
            method: 'GET',
            params: filters,
        })
        return response.data
    },
    delete: async function (itemId) {
        const response = await api.request({
            url: `/counted-items/${itemId}`,
            method: 'DELETE',
        })
        return response.data
    },
    checkInCatalog: async function (itemIds) {
        console.log('itemIds', itemIds)
        const response = await api.request({
            url: '/counted-items/check-in-catalog',
            method: 'POST',
            data: {items_ids: itemIds},
        })
        return response.data
    },
    deleteMany: async function (itemIds) {
        const response = await api.request({
            url: '/counted-items/delete-many',
            method: 'POST',
            data: {items_ids: itemIds},
        })
        return response.data
    }
}
