import {useEffect, useState} from 'react'
import {WorkingHoursAPI} from 'apis/WorkingHoursAPI'
import {useApiToast} from '../useApi/useApiToast'

export const useWorkingHours = ( userId ) => {
    const [workingHours, setWorkingHours] = useState(null)
    const [filters, setFilters] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const showToast = useApiToast()
    
    const fetchWorkingHours = async () => {
        let resp
        if (userId){
            resp = await WorkingHoursAPI.getByUser(userId)
        } else {
            resp = await WorkingHoursAPI.getPaginated(filters)
        }
        setWorkingHours(resp)
    }

    const updateWorkingHours = async (id, data) => {
        const resp = await WorkingHoursAPI.update(id, data)
        await fetchWorkingHours() 
        showToast(resp)
    }


    useEffect(() => {
        fetchWorkingHours()
    }, [filters])

    const handleDeleteWorkingHour = async (id) => {
        if (window.confirm('Are you sure you want to delete this working hour?')) {
            console.log('delete working hour', id)
            let resp = await WorkingHoursAPI.delete(id)
            showToast(resp)
            fetchWorkingHours()
        }
    }

    const deleteMany = async () => {
        if (window.confirm(`Are you sure you want to delete these working hours for period from ${filters.dateFrom} to ${filters.dateTo}?`)) {
            console.log(`delete many working hours for period ${filters.dateFrom} to ${filters.dateTo}`)
            let resp = await WorkingHoursAPI.deleteMany(filters.dateFrom, filters.dateTo)
            showToast({message: `Deleted ${resp.deleted} rows`})
            fetchWorkingHours()
        }
    }

    const handlePageChange = (data) => {
        (async () => setFilters(oldFilters => ({...oldFilters, ...data})))()
        setCurrentPage(data)
    }

    return {workingHours, fetchWorkingHours, updateWorkingHours, handleDeleteWorkingHour, currentPage, handlePageChange, filters, setFilters, deleteMany}
}
