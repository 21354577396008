import {useCallback, useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'

export const useRowsDouble = (countingId) => {
    const [rowsDouble, setRowsDouble] = useState([])
    const [rowsDoubleTotal, setRowsDoubleTotal] = useState()
    const [loading, setLoading] = useState(false)

    const fetchRowsDouble = useCallback(async (id) => {
        setLoading(true)
        const resp = await CountingAPI.getRowsDouble(id)
        if (resp?.rows_double) {
            setRowsDouble(resp.rows_double)
            setRowsDoubleTotal(resp.rows_double_total)
        }
        setLoading(false)
    }, [])

    const getRowsDouble = () => {
        fetchRowsDouble(countingId).catch((error) => {
            console.error('Failed to fetch rows:', error)
        })
    }


    useEffect(() => {
        getRowsDouble()
    }, [countingId])

    return {rowsDouble, getRowsDouble, rowsDoubleTotal, loading}
}
