import {Button, Text} from '@chakra-ui/react'

export const CompleteSection = (props) => {
	const { totalRows, goodRowsCount, badRowsCount, handleSave, importing } = props
	return (
		<>
			<Text mt={4}>Processing complete</Text>
			<Text>Total Rows: {totalRows}</Text>
			<Text color={badRowsCount > 0 ? 'red' : 'inherit'}>Bad Rows: {badRowsCount}</Text>
			<Text>Good Rows: {goodRowsCount}</Text>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Button isDisabled={goodRowsCount < 1|| importing} m={2} colorScheme={'blue'} onClick={handleSave}>Import</Button>
			</div>
		</>
	)
}