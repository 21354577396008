import {Select} from '@chakra-ui/react'
import React from 'react'

export const RowsFilterCategoriesOld = (props) => {
    const {items, itemSelected, setItemSelected, placeholder} = props
    const handleChangeCategory = (event) => {
        setItemSelected(event.target.value)
        // console.log(itemSelected)
    }
    return (
        <Select onChange={handleChangeCategory} placeholder={placeholder} value={itemSelected}>
            {items.map(item => (
                <option key={item._id} value={item._id}>{item.name}</option>
            ))}
        </Select>
    )
}