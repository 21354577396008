import React from 'react'
import {Button, Td, Tr} from '@chakra-ui/react'
import {NavLink} from 'react-router-dom'
import {CompanyModal} from '../CompanyModal/CompanyModal'
import {CompanyCatalogModal} from '../CompanyCatalogModal/CompanyCatalogModal'
import {CompanyDeleteButton} from './CompanyDeleteButton/CompanyDeleteButton'

const CompanyRow = (props) => {
    const {id, name, file, fetchCompanies} = props

    return (
        <Tr key={id}>
            <Td>
                {name}
            </Td>
            <Td>
                <Button mr={2} as={NavLink} to={`/stores/${id}`} colorScheme='teal' size='sm'>Shops</Button>
                <CompanyModal fetchCompanies={fetchCompanies} id={id} name={name} file={file} mode='edit'/>
                <CompanyCatalogModal id={id} name={name} file={file}/>
                <CompanyDeleteButton id={id} fetchCompanies={fetchCompanies}/>
            </Td>
            <Td>
                Enabled
            </Td>
        </Tr>
    )
}

export default CompanyRow