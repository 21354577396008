import {Button, Td, Tr} from '@chakra-ui/react'
import {DateYMD} from '../../../Date/DateYMD'
import {DateSecondsToTime} from '../../../Date/DateSecondsToTime'
import React, {useEffect} from 'react'
import {DateHMS} from '../../../Date/DateHMS'
import {WorkingHourModal} from '../../WorkingHourModal/WorkingHourModal'
import {WorkingHourDeleteButton} from '../../WorkingHourDeleteButton/WorkingHourDeleteButton'

export const WorkingHoursTableRow = (props) => {
	const {
		id, totalTime, totalWorkTime, totalDriveTime, storeName, companyName, timeDrivingFromWorkStart, timeDrivingToWorkClosed,
		timeWorkingClosed, timeWorkingStart,timeDrivingClosed,timeDrivingStart, userTz, name, date,
		updateWorkingHours, handleDeleteWorkingHour

	} = props

	return (
		<Tr key={id}>
			<Td width='30px'>{name}<br/>{userTz}</Td>
			{/* <Td></Td> */}
			<Td width='30px'><DateYMD date={timeDrivingStart} /></Td>
			<Td width='30px'>
				<DateHMS date={timeDrivingStart}/>
			</Td>
			<Td width='30px'>
				<DateHMS date={timeWorkingStart}/> <br/> <DateHMS date={timeWorkingClosed}/>
			</Td>
			{/* <Td>
				
			</Td> */}
			<Td width='30px'>
				<DateHMS date={timeDrivingClosed}/>
			</Td>
			<Td>
				<DateSecondsToTime seconds={totalDriveTime} />
			</Td>
			<Td>
				<DateSecondsToTime seconds={totalWorkTime} />
			</Td>
			<Td>
				<DateSecondsToTime seconds={totalTime} />			
			</Td>
			<Td>{companyName}<br/>{storeName}</Td>
			{/* <Td></Td> */}
			<Td>
				<WorkingHourModal
					id={id}
					userTz={userTz}
					name={name}
					date={date}
					timeDrivingStart={timeDrivingStart}
					timeWorkingStart={timeWorkingStart}
					timeWorkingClosed={timeWorkingClosed}
					timeDrivingClosed={timeDrivingClosed}
					timeDrivingFromWorkStart={timeDrivingFromWorkStart}
					timeDrivingToWorkClosed={timeDrivingToWorkClosed}
					updateWorkingHours={updateWorkingHours}
				/>
				<WorkingHourDeleteButton id={id} handleDeleteWorkingHour={handleDeleteWorkingHour} />
			</Td>
		</Tr>
	)
}