import {Box, Button, ButtonGroup, Container, HStack, Spacer} from '@chakra-ui/react'
import {NavLink, useLocation} from 'react-router-dom'
import useAuthStore from '../../stores/authStore'
import LogOutButton from './LogOutButton/LogOutButton'
import {useEffect} from 'react'

export const NavBar = () => {
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
    const user = useAuthStore((state) => state.user)

    const location = useLocation()

    useEffect(() => {
        // console.log('useffect isloggedin', isLoggedIn)
    }, [isLoggedIn])

    const getButtonVariant= (path) => {
        if (path === '/countings' && location.pathname.startsWith('/counting')) {
            return 'outline'
        }
        if (path === '/countings' && location.pathname.startsWith('/stores')) {
            return 'outline'
        }
        return location.pathname === path ? 'outline' : ''
    }

    return (
        <Box as='section'>
            <Box as='nav' bg='bg-surface' boxShadow='sm'>
                <Container py={{ base: '4', lg: '5' }}>
                    <HStack spacing='10' justify='space-between' width='100%'>

                        <ButtonGroup variant='link' spacing='8'>
                            {isLoggedIn && (
                                <>
                                    <Button as={NavLink} to='/countings' variant={getButtonVariant('/countings')}>
                                        Countings
                                    </Button>
                                    {(user?.is_admin || user?.is_counting_manager) && (
                                        <>
                                        <Button as={NavLink} to='/companies' variant={getButtonVariant('/companies')}>
                                            Companies
                                        </Button>
                                        <Button as={NavLink} to='/users' variant={getButtonVariant('/users')}>
                                            Workers
                                        </Button>
                                        <Button as={NavLink} to='/statistics' variant={getButtonVariant('/statistics')}>
                                            Statistics
                                        </Button>
                                        {user?.is_admin && (
                                            <Button as={NavLink} to='/working-hours' variant={getButtonVariant('/working-hours')}>
                                                Hours
                                            </Button>
                                        )}
                                        </>
                                     )}
                                </>
                            )}
                        </ButtonGroup>

                        <Spacer />

                        <HStack spacing='3'>
                            {isLoggedIn ? (
                                <LogOutButton />
                            ) : (
                                <Button as={NavLink} to={'/login'}>
                                    Login
                                </Button>
                            )}
                        </HStack>

                    </HStack>
                </Container>
            </Box>
        </Box>
    )
}
