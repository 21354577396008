import { Card, CardBody, Input, Button, ButtonGroup, Tooltip, Stack, Textarea, VStack, HStack, Divider, Box } from '@chakra-ui/react'
import React, { useState, useContext } from 'react'
import { api } from '../../../apis/configs/axiosConfigs'
import { CountingTabsContext } from '../CountingTabs/CountingTabs'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'  
import useForm from 'hooks/useForm/useForm' 
import useAuthStore from 'stores/authStore'

import { Signature } from './Signature'
import Galery  from './Galery'

// const getResults = async function (params) {
//     const response = await api.request({
//         url: 'counting/inventory-results/' + params.countingId,
//     })

//     return response.data.results
// }

const forRevise = [
    {
        name: 'Counted Items',
        path: 'counted-items',
    },
    {
        name: 'Counted Rows',
        path: 'counted-rows',
    },
    {
        name: 'Counted Category Free',
        path: 'category-free',
    },
    // {
    //     name: 'Plan/Actual',
    //     path: 'plan-actual',
    // },
    // {
    //     name: 'Items Edited',
    //     path: 'items-edited',
    // },
    {
        name: 'Items Invalid',
        path: 'invalid-items',
    },
    {
        name: 'Rows Double',
        path: 'double-rows',
    },
    {
        name: 'Rows Missed',
        path: 'missed-rows',
    },
    // {
    //     name: 'Rows Empty',
    //     path: 'rows-empty',
    // },
    {
        name: 'Counting Hours',
        path: 'counting-hours',
    },
]

export const InventoryResults = () => {
    
    const { revisedTabs, counting, refreshCounting, updateCounting, setCounting } = useContext(CountingTabsContext)
    const keyFields = [
        'time_start_manually',
        'time_finish_manually',
        'items_amount_manually',
        'items_no_barcode_manually',
        'store_prepared',
        'notes',
        'manager_name_manually',
        'manager_signature_manually'
    ]

    const {values, handleChange, clearValues, setValues} = useForm(
        keyFields.reduce((acc, key) => counting ? ({...acc, [key]: (counting[key] ?? '')}) : acc, {})
    )
    
    const handleChangeExtra = (event) => {
        setCounting(prevState => ({...prevState, [event.target.name]: handleChange(event)}))
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const { countingId } = useParams()

    const notRevisedTabs = forRevise.filter(item => !revisedTabs.includes(item.path))

    const [managerSignature, setManagerSignature] = useState(counting?.manager_signature)

    const user = useAuthStore((state) => state.user)

    // const snakeToCamel = str =>
    //     str.toLowerCase().replace(/([-_][a-z])/g, group =>
    //         group
    //         .toUpperCase()
    //         .replace('-', '')
    //         .replace('_', '')
    //     )

    return (
        <>
            <Card>
                <CardBody style={{ direction: 'rtl', marginBottom: '5px'}}>
                    <HStack justifyContent='start' alignItems='end' spacing='10vw'>
                        <Stack spacing={6} maxWidth={'550'}>
                            <div>שם חברה: <strong>{counting.store?.company?.name}</strong></div>
                            <div>שם סניף: <strong>{counting.store?.name} {counting.store?.code}</strong></div>
                            <div>תאריך תחילת ספירה: <strong>{(new Date(counting.date_start)).toLocaleDateString('he-IL')}</strong></div>
                            <div>שעת תחילת ספירה: </div>
                                <Input type='time' value={values.time_start_manually} onChange={handleChangeExtra} name='time_start_manually' form='getPdf' htmlSize={30} width='auto' borderColor='green' placeholder='*חובה להקליד'/>
                            <div>תאריך סיום ספירה: 
                                {!user.is_admin && <strong > {!!values.time_finish_manually && (new Date(values.time_finish_manually)).toLocaleDateString('he-IL')}</strong>}
                            </div>
                            {!user.is_admin && <Tooltip placement='top' label='בלחיצה על כפתור סיום ספירה בטופס זה, שעת סיום הספירה תופיע פה'>
                                <div > שעת סיום ספירה: <strong> {!!values.time_finish_manually && (new Date(values.time_finish_manually)).toLocaleTimeString('he-IL')}</strong></div>
                            </Tooltip>}
                            
                            <Input hidden={!user.is_admin} type='datetime-local' value={values.time_finish_manually ?? '' } onChange={handleChangeExtra} name='time_finish_manually' form='getPdf' width='auto' borderColor='green' />
                            <Tooltip placement='top' label='יימשך משייוך הספירה למנהל ספירה'>
                                <div>מנהל ספירה : <strong> {!!counting.manager && `${counting.manager.name} ${counting.manager.last_name}`}</strong> </div>
                            </Tooltip>
                            <div>כמות פריטים שנספרה: 
                                </div><Input type='number' value={values.items_amount_manually} name='items_amount_manually' form='getPdf' onChange={handleChangeExtra} htmlSize={30} width='auto' borderColor='green' placeholder='*חובה להקליד תאריך'/>
                            <div>פריטים ללא ברקוד: 
                                </div> <Input value={ values.items_no_barcode_manually } name='items_no_barcode_manually' form='getPdf' onChange={handleChangeExtra} htmlSize={30} width='auto' borderColor='green' placeholder='למילוי ידנית'/>
                            {/* <div>הכנת החנות לספירה: </div><Textarea value={values.store_prepared} name='store_prepared'  form='getPdf' onChange={handleChange} width='auto' borderColor='green' placeholder='למ ילוי ידנית'/>
                            <div>הערות כלליות: </div>
                            <Textarea value={values.notes} name='notes' form='getPdf' onChange={handleChange} width='auto' borderColor='green' placeholder='למ ילוי ידנית'/> */}
                            <div>אישור מנהל/ת החנות את נכונות תוצאות הספירה:</div>
                            <div>שם: </div><Input value={values.manager_name_manually} name='manager_name_manually' form='getPdf' onChange={handleChangeExtra} htmlSize={30} width='auto' borderColor='green' placeholder='חובה להכליד*'/>
                            <div>חתימה: </div> 
                            {/* <Input type='file' value={managerSignature} name='manager_signature_manually' form='getPdf' onChange={handleChange} htmlSize={30} width='auto' borderColor='green' placeholder='חובה להקליד*'/> */}
                            {/* <Input value={values.manager_signature_manually} name='manager_signature_manually' form='getPdf' onChange={handleChange} htmlSize={30} width='auto' borderColor='green' placeholder='חובה להקליד*'/> */}
                            <Signature 
                                signature={managerSignature} 
                                setSignature={setManagerSignature} 
                                name='manager_signature' 
                                setCounting={data => setCounting(prevState => ({...prevState, ...data}))}
                                />
                            <ButtonGroup spacing={6} >

                                {/* <Button colorScheme='blue'>בדקתי את כל המנות הכפולות</Button> */}
                                <Button colorScheme='green' isDisabled={notRevisedTabs.length} hidden={!!values.time_finish_manually} onClick={
                                    ()=> {
                                        const date = new Date()
                                        const time_finish_manually = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,16)
                                        setValues({...values, time_finish_manually}) 
                                    }}>סיום ספירה
                                </Button>
                                {/* <Button onClick={() => toPDF() } colorScheme='red'>pdf</Button> */}
                                {/* <ComponentToPrint /> */}
                                {/* <Button type='button' colorScheme='green'
                                    onClick={() => {
                                        updateCounting(counting._id, new FormData(document.getElementById('getPdf')))
                                    }}
                                >
                                    שמירה
                                </Button> */}
                                <form id='getPdf' method='get' action={`${process.env.REACT_APP_API_BASE_URL}/counting/summary/${counting._id}`} target='_blank'>
                                    <Button type='submit' colorScheme='yellow'>הורדה</Button>
                                </form>

                            </ButtonGroup>
                        </Stack>
                        {/* <Divider orientation='vertical' width='sm'/> */}
                        <VStack spacing='40px' justify={'space-between'}>
                            <Galery countingId={countingId} />                  
                           
                            <VStack justifyContent='flex-end' height='100%' align='right' spacing='6px' hidden={!!values.time_finish_manually}>
                                <h1>לשוניות שלא סגורים:</h1>                         
                                {
                                    notRevisedTabs
                                        .map((tab, idx) => (
                                            <Button key={idx} onClick={() => setSearchParams({tab: tab.path})} 
                                                variant='outline' 
                                                colorScheme='yellow'>
                                                    {tab.name}
                                            </Button>)
                                        )
                                }
                                
                                {/* <div>במידה וישנן לשוניות שלא סומנו בוי בריבוע הבדיקה</div>
                                <div>הן יופיעו פה כדי שמנהל הספירה ידע לאן לחזור ולסמן</div>

                                <div>כפתור סיום ספירה: לא ניתן לסיים ספירה אם יש לשוניות פתוחות שלא סומנו ב&rdquo;וי&rdquo; ולא הוקלדה שעת פתיחת ספירה ואין שם וחתימת מנהל/ת חנות</div> */}
                            </VStack>
                        </VStack>
                    </HStack>
                </CardBody>
            </Card>
        </>
    )
}