import {useMemo, useState} from 'react'

export const useFieldFilter = (items, query, field, exactMatch) => {
    const [filteredItems, setFilteredItems] = useState(items)

    useMemo(() => {
        const lowercasedQuery = query.toLowerCase()

        setFilteredItems(items.filter(item => {
            const itemValue = String(item[field]).toLowerCase()
            return exactMatch
                ? itemValue === lowercasedQuery
                : itemValue.includes(lowercasedQuery)
        }))
    }, [items, query, field, exactMatch])

    return filteredItems
}
