import React from 'react'
import {
    Box,
    Button,
    Card,
    CardBody,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react'
import useForm from '../../../hooks/useForm/useForm'
import {useApiToast} from '../../../hooks/useApi/useApiToast'

import {CountingAPI} from '../../../apis/CountingAPI'

const validate = (values, categoriesWithRowsInARange, id) => {
    const errors = []
    if (Number(values.rowFrom) > Number(values.rowTo)) {
        errors.push('Row from can not be bigger than row to')
    }
    if (values.rowFrom === '' || values.rowTo === '' || values.name === '') {
        errors.push('Can not be empty')
    }
    const sameRangeCategories = categoriesWithRowsInARange(values.rowFrom, values.rowTo)
    if (sameRangeCategories.length > 0 && sameRangeCategories[0]._id !== values.id) {
        errors.push('There is already a category in this range')
    }
    if (values.rowFrom < 1) {
        errors.push ('Row from can not be smaller than 1')
    }
    if (values.rowFrom > 1 && categoriesWithRowsInARange(values.rowFrom - 1, values.rowFrom - 1).length === 0) {
        errors.push('There is no category in the previous row')
    }


    return errors
}

const CategoryRowModal = (props) => {
    const toast = useToast()
    const showToast = useApiToast()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {values, handleChange, clearValues} = useForm({name: props.name, rowFrom: props.rowFrom, rowTo: props.rowTo})
    const id = props.id
    const fetchCategoryRows = props.fetchCategoryRows
    const countingId = props.countingId
    const mode = props.mode
    const saveCategoryRow = async () => {
        console.log('saving row', values)
        const errors = validate({...values, id}, props.categoriesWithRowsInARange)
        if (errors.length > 0) {
            toast({
                description: errors.join('. '),
                status: 'warning',  
            })
            return
        }
        let resp
        if (id === undefined) {
            resp = await CountingAPI.createCategoryRow({countingId, ...values})
        } else {
            resp = await CountingAPI.updateCategoryRow({id, ...values})
        }

        console.log(resp)
        showToast(resp)
        fetchCategoryRows(countingId)
        clearValues()
        onClose()
    }

    React.useEffect(() => {
        if (props.rowFrom !== undefined) {
            handleChange({target: {name: 'rowFrom', value: props.rowFrom}})
        }
    }, [props.rowFrom])

    return (
        <>
            {mode === 'new' ? (
                <Button mr={2} size='sm' colorScheme='green' onClick={onOpen}>New</Button>
            ) : (
                <Button mr={2} size='sm' colorScheme='blue' onClick={onOpen}>Edit</Button>
            )}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Category {values.name}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>

                        <Card>
                            <CardBody>
                                <Box border='1px' borderColor='gray.300' borderRadius='md' p={3} mb={4}>
                                    <FormLabel>Name</FormLabel>
                                    <Input name='name' value={values.name} onChange={handleChange}/>
                                </Box>
                                <Box border='1px' borderColor='gray.300' borderRadius='md' p={3} mb={4}>

                                    <HStack spacing={4} alignItems='flex-end'>
                                        <VStack>
                                            <FormLabel>From row</FormLabel>
                                            <Input name='rowFrom' value={values.rowFrom} onChange={handleChange}/>
                                        </VStack>
                                        <VStack>
                                            <FormLabel>To row</FormLabel>
                                            <Input name='rowTo' value={values.rowTo} onChange={handleChange}/>
                                        </VStack>
                                    </HStack>
                                </Box>
                            </CardBody>
                        </Card>

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={saveCategoryRow}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}

export default CategoryRowModal