import {Td, Tr} from '@chakra-ui/react'
import React from 'react'

export const RowsCountedTableRowOld = (props) => {
    const {id, totalAmount, deviceNumber, name} = props
    return (
        <Tr>
            <Td>
                {id}
            </Td>
            <Td>
                {deviceNumber}
            </Td>
            <Td>
                {name}
            </Td>
            <Td>
                {totalAmount}
            </Td>
        </Tr>
    )
}