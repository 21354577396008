import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent, ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	HStack, Flex,
	Spacer,
} from '@chakra-ui/react'
import React, {useEffect} from 'react'
import {UserButton} from '../UserButton/UserButton'
import {UserForm} from '../UserForm/UserForm'
import useForm from '../../../../hooks/useForm/useForm'
import {useFormValidation} from '../../../../hooks/useForm/useFormValidation'
import {useApiToast} from '../../../../hooks/useApi/useApiToast'
import {UserAPI} from '../../../../apis/UserAPI'

export const UserModal = (props) => {
	// console.log(props)
	const {fetchUsers, mode} = props
	const {isOpen, onOpen, onClose} = useDisclosure()
	const {values, handleChange, clearValues, setValues} = useForm({
		_id: props._id,
		identify: props.tz,
		name: props.firstName,
		last_name: props.lastName,
		is_counter: props.isCounter,
		is_shift_manger: props.isShiftManager,
		is_counting_manager: props.isCountingManager,
		is_shift_manager: props.isShiftManager,
		is_admin: props.isAdmin,
		password: props.password,
	})

	const { validateUser } = useFormValidation()
	const showApiToast = useApiToast()
	const saveUser = async () => {
		// console.log('save user')
		// console.log('values', values)
		const validationErrors = validateUser(values)
		if (validationErrors.length === 0) {
			console.log('submit user')
			// if is set values._id update user, else create

			let resp
			if(values._id){
				console.log('update user')
				resp = await UserAPI.update(values._id, values)
			} else {
				console.log('create user')
				resp = await UserAPI.create(values)
			}

			showApiToast(resp)
			if(resp.status === 0){
				fetchUsers()
				clearValues()
				onClose()
			}

		}
	}
	useEffect(() => {
		setValues({
			_id: props._id,
			identify: props.tz,
			name: props.firstName,
			last_name: props.lastName,
			is_counter: props.isCounter,
			is_shift_manger: props.isShiftManager,
			is_counting_manager: props.isCountingManager,
			is_shift_manager: props.isShiftManager,
			is_admin: props.isAdmin,
			password: props.password,
		})
	}, [props])
	return (
		<>
			<UserButton mode={mode} onOpen={onOpen}/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay/>
				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalCloseButton/>
					
					<ModalBody>
						<UserForm values={values} handleChange={handleChange} saveUser={saveUser}/>
					</ModalBody>
					<ModalFooter>
						<HStack justifyContent='space-between'>
							{values._id && <><Button colorScheme='red' onClick={() => UserAPI.delete(values._id).then(resp=> {
								if (resp.status === 0) {
									fetchUsers()
									clearValues()
									onClose()
									}}
							)} >Delete</Button><Spacer/></>}
							<Button onClick={()=>saveUser(values)} colorScheme='blue'>
								Save
							</Button>
							<Button onClick={onClose}>Cancel</Button>
						</HStack>
						
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}