import './App.css'
import {Box} from '@chakra-ui/react'
import {NavBar} from './components/NavBar/NavBar'
import useAuthStore from './stores/authStore'
import AppRouter from './components/AppRouter/AppRouter'

function App() {
    useAuthStore((state) => state.isLoggedIn)
    return (
        <div className='App'>
            <NavBar position='sticky' top={0} bg='white' zIndex='sticky' />
            <Box as='main' position='absolute' height='90vh' overflowY='auto' overflowX='hidden'
                 p={4}
                 w='full'
                //  maxW='1900px'
                 mx='auto'
            >
                <AppRouter/>
            </Box>

        </div>
    )
}

export default App
