import {Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr} from '@chakra-ui/react'
import {RowsEmptyRow} from './RowsEmptyRow'
import {Captions} from '../../Captions'

export const RowsEmptyTable = (props) => {
    const {rowsEmpty} = props
    return (
        <TableContainer>
            <Table>
                <TableCaption placement={'top'}>
                    <Captions total={rowsEmpty.length} />
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>
                            Row
                        </Th>
                        <Th>
                            Amount
                        </Th>
                        <Th>
                            Name
                        </Th>
                        <Th>
                            Device
                        </Th>
                        <Th>
                            Time
                        </Th>
                        <Th>
                            Date
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rowsEmpty.map(rowEmpty => (
                        <RowsEmptyRow
                            key={rowEmpty._id}
                            name={rowEmpty.name}
                            date={rowEmpty.created_at}
                            userFirstName={rowEmpty?.user?.name}
                            userLastName={rowEmpty?.user?.last_name}
                            deviceNumber={rowEmpty.device_number}
                        />
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}