import { Td, Tr } from '@chakra-ui/react'
import { ItemsEditedActionButtons } from './ItemsEditedActionButtons/ItemsEditedActionButtons'
import { useItemsEditedEdit } from '../../../../../hooks/useItems/useItemsEditedEdit'
import {InputEditable} from '../../../../Inputs/InputEditable/InputEditable'

export const ItemsEditedTableRow = (props) => {
	const { id, upc, mkt, row, device, amount, userName, refreshCounting } = props
	const { isEditing, editedValues, handleEdit, handleSave, handleInputChange } = useItemsEditedEdit(id, amount, refreshCounting)

	return (
		<Tr key={id}>
			<Td>{upc}</Td>
			<Td>{mkt}</Td>
			<Td>{row}</Td>
			<Td>
				<InputEditable
					isEditing={isEditing}
					value={editedValues.amount}
					onChange={handleInputChange}
					field='amount'
				/>
			</Td>
			<Td>{device}</Td>
			<Td>{userName}</Td>
			<Td>
				<ItemsEditedActionButtons
					isEditing={isEditing}
					onEdit={handleEdit}
					onSave={handleSave}
				/>
			</Td>
		</Tr>
	)
}
