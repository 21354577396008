import React, {useState} from 'react'
import {Button, Center, FormControl, FormLabel, Input, VStack} from '@chakra-ui/react'
import styles from './LoginForm.module.css'
import {AuthAPI} from '../../../apis/AuthAPI'
import useAuthStore from '../../../stores/authStore'
import {useNavigate} from 'react-router-dom'

function LoginForm() {
    const navigate = useNavigate()
    const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn)
    const setUser = useAuthStore((state) => state.setUser)

    const [formData, setFormData] = useState({
        login: '',
        password: '',
    })
    const handleChange = (event) => {
        const {name, value} = event.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        let resp = await AuthAPI.login(formData.login, formData.password)
        console.log('resp login', resp)
        if (resp?.status === 0) {
            console.log('login complete')
            setIsLoggedIn(true)
            setUser(resp.user)
            navigate('/countings')
        }
    }
    return (
        <Center>
            <div>
                <VStack spacing={4}>
                    <img alt={'Logo'} className={styles.logo} src='images/logo.png'/>
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel>Username</FormLabel>
                            <Input
                                name={'login'}
                                value={formData.login}
                                type='text'
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Password</FormLabel>
                            <Input
                                name={'password'}
                                value={formData.password}
                                type='password'
                                onChange={handleChange}
                            />
                        </FormControl>
                        <Button type='submit' colorScheme='blue'>
                            Log in
                        </Button>
                    </form>
                </VStack>

            </div>

        </Center>
    )
}

export default LoginForm