import { Select } from '@chakra-ui/react'
import React, {useEffect} from 'react'

export const TesterSelect = (props) => {
    const { testers, testerIdSelected, handleChange } = props
    useEffect(() => {
        console.log('TesterSelect useEffect testerIdSelected', testerIdSelected)
    }, [testerIdSelected])
    useEffect(() => {
        // console.log('TesterSelect useEffect no deps', testerIdSelected)
        // handleChange({target: {value: testerIdSelected}})
    }, [])
    return (
        <Select value={testerIdSelected} onChange={handleChange}>
            <option value={''}>Select Tester</option>
            {testers && testers.map(tester => (
                <option key={tester._id} value={tester._id}>{tester.name}</option>
            ))}
        </Select>
    )
}
