import { Flex, Spacer, Tag, Stack, Checkbox } from '@chakra-ui/react'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import DownloadCountingButton from '../../../Countings/CountingsTable/DownloadCounting/DownloadCountingButton'
import { CountingTabsContext } from '../../CountingTabs/CountingTabs'

export const ItemsTableCaption = (props) => {
	const { total, totalAmount, totalRows, countingId, counting, isRevised, setRevised, group_items } = props
	const { revisedTabs, toggleRevisedTab } = useContext(CountingTabsContext)
	const [ searchParams ] = useSearchParams()
	const tab = searchParams.get('tab')
	// console.log('ItemsTableCaption', props)
	return (
		// <Flex direction='row'>
		<Stack direction='row' spacing={4}>
			{total && (<Tag colorScheme='blue'>Total: {total}</Tag>)}
			{totalAmount && (<Tag colorScheme='green'>Sum amount: {totalAmount}</Tag>)}
			{totalRows && (<Tag colorScheme='purple'>Rows: {totalRows}</Tag>)}
			<Checkbox isChecked={ revisedTabs.includes(tab) } onChange={() => toggleRevisedTab(tab) }> Revised </Checkbox>
			{counting && (
				<>
					<Spacer />
					<DownloadCountingButton countingId={countingId} counting={counting} group_items={group_items}/>
				</>
			)}
			
		</Stack>

		// </Flex>
	)
}