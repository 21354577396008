import {useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'

export const useFilters = (countingId, onFilterChange) => {
    const [filters, setFilters] = useState({})
    const [devices, setDevices] = useState([])
    const [users, setUsers] = useState([])
    const [categories, setCategories] = useState([])
    const [testers, setTesters] = useState([])

    useEffect(() => {
        getUsers()
        getDevices()
        getCategories()
        getTesters()
    }, [])

    const handleInputChange = (type, value, exactMatch) => {
        console.log('handleInputChange', type, value, exactMatch)
        if (value) { // Only set filter if value is not empty
            const filterValue = exactMatch ? value : `%${value}%`
            setFilters(prevFilters => ({...prevFilters, [type]: filterValue}))
            onFilterChange({...filters, [type]: filterValue})
        } else { // If value is empty, remove the filter
            setFilters(prevFilters => {
                const newFilters = {...prevFilters}
                delete newFilters[type]
                return newFilters
            })
            onFilterChange({...filters, [type]: undefined})
        }
    }

    const handleExactMatchChange = (type, isChecked) => {
        if (filters[type]) { // Only set filter if it exists
            const value = filters[type]
            const exactMatchValue = isChecked ? value.replace(/%/g, '') : `%${value}%`
            setFilters(prevFilters => ({...prevFilters, [type]: exactMatchValue}))
            onFilterChange({...filters, [type]: exactMatchValue})
        }
    }

    const computeFilterValue = (type, value) => {
        let filterValue = value || undefined

        switch (type) {
            case 'user_id':
                return users.find(user => `${user.name} ${user.last_name}` === value)?._id
            case 'with_deleted':
            case 'device_number':
            case 'category_row_id':
            case 'tester_id':
                return filterValue
            case 'is_manual':
                return value === 'No' ? false : (value === 'Yes' ? true : undefined)
            default:
                return value === 'No' ? true : value === 'Yes' ? false : undefined
        }
    }

    const handleSelectChange = (type, value) => {
        const filterValue = computeFilterValue(type, value)

        setFilters(prevFilters => {
            const newFilters = {...prevFilters, [type]: filterValue}
            onFilterChange(newFilters)
            return newFilters
        })
    }



    const getUsers = async () =>{
        const resp = await CountingAPI.getUsers(countingId)
        // console.log('getUsers', resp)
        setUsers(resp.users)
    }

    const getDevices = async () =>{
        const resp = await CountingAPI.getDevices(countingId)
        // console.log('getDevices', resp)
        setDevices(resp.devices)
    }

    const getCategories = async () =>{
        const resp = await CountingAPI.getCategoryRows(countingId)
        // console.log('getCategoryRows', resp)
        setCategories(resp.category_rows)
    }

    const getTesters = async () =>{
        const resp = await CountingAPI.getCounting(countingId)
        // console.log('getTesters', resp)
        setTesters(resp.counting.testers)
    }
    return {handleInputChange, handleExactMatchChange, handleSelectChange, users, devices, categories, testers}
}