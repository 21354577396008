import { Input } from '@chakra-ui/react'
import React, { useState } from 'react'

export const CountingAddDate = (props) => {
    const {selectedDate, handleDateChange} = props

    return (
        <>
            <Input value={selectedDate} type='date' onChange={handleDateChange} />
        </>
    )
}
