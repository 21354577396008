import axios from 'axios'

// const baseURL = "http://10.0.0.44:8000"
// const baseURL = "https://yaad-backend.t2l.lol"
// const baseURL = "http://127.0.0.1:8000"
const baseURL = process.env.REACT_APP_API_BASE_URL
export const api = axios.create({
    // withCredentials: true,
    baseURL: baseURL,
})

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
    const statusCode = error.response?.status
    const config = error?.config
    console.log('config', config)
    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        // console.error(error)
    } else if (!config?.sent) {
        config.sent = true
        console.log('401 error, refresh token')
        let response = await axios.request({
            url: `${baseURL}/admin/auth/refresh-token`,
            method: 'POST',
            data: {refresh_token: localStorage.getItem('refresh_token')}
        })
        if (!response.data.access_token) {
            localStorage.setItem('user', '')
            window.location = '/login'
        }
        console.log(response.data)
        console.log(response.data.access_token)
        localStorage.setItem('access_token', response.data.access_token)
        config.headers['Authorization'] = 'Bearer ' + response.data.access_token
        return axios.request(error.config)
    } else if (config?.sent) {
        // debugger;
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
// api.interceptors.response.use(undefined, (error) => {
//     return errorHandler(error)
// })

// api.interceptors.request.use(
//     async config => {
//         // const value = await redisClient.get(rediskey)
//         // const keys = JSON.parse(value)
//         config.headers = {
//             'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
//             'Accept': 'application/json',
//             // 'Content-Type': 'application/x-www-form-urlencoded'
//         }
//         return config;
//     },
//     error => {
//         Promise.reject(error)
//     });
