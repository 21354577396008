import React, {useEffect, useState} from 'react'
import { Th, Input } from '@chakra-ui/react'

export const Filters = ({companies, setFilters, filters, extraFilters }) => {
    const [stores, setStores] = useState([])
    
    const filtersConfig = [
        { type: 'date_start', placeholder: 'Date'  },
        { type: 'company_name', placeholder: 'Company', options: companies },
        { type: 'store_name', placeholder: 'Shop', options: stores }
    ]

    const onCompanyChange = (e) => {
        const company = companies.find(company => company.name === e.target.value)
        setStores(company?.stores ?? [])
        setFilters({...filters, company_id: company?._id ?? '', company_name: company?.name ?? '', store_id: undefined, store_name: undefined})
    }

    const onStoreChange = (e) => {
        const store = stores.find(store => store.name === e.target.value)
        setFilters({...filters, store_id: store?._id ?? '', store_name: store?.name ?? ''})
    }
    // useEffect(() => {
        
    //         const company = companies.find(company => company.name === filters.company_name)
    //         setStores(company?.stores ?? [])
    //         setFilters({...filters, store_id: '', store_name: '', company_id: company?._id ?? '', company_name: company?.name ?? ''})
        
    // }, [filters?.company_name])

    // useEffect(() => {
    //     const store = stores.find(store => store.name === filters.store_name)
    //     setFilters({...filters, store_id: store?._id ?? '', store_name: store?.name ?? ''})
    // }, [filters?.store_name])



    return (
        <>
            {filtersConfig.map((filter, index) =>
                <Th key={index}>
                    {
                        filter.options ? 
                            <><Input id={`filter_${filter.type}`} list={`${filter.type}_list`} placeholder={filter.placeholder} value={filters ? (filters[filter.type] ?? '') : null}
                                onChange={(e) =>{
                                    switch (filter.type) {
                                        case 'company_name':
                                            onCompanyChange(e)
                                            break
                                        case 'store_name':
                                            onStoreChange(e)
                                            break
                                        default:
                                            setFilters({...filters, [filter.type]: e.target.value})
                                            break
                                    }

                                }} style={{minWidth: '100px'}}/>
                                <datalist id={`${filter.type}_list`}>
                                    {filter.options && filter.options.map((item, i) =>
                                        <option key={i} data-value={item._id} value={item.name}></option>)}
                                </datalist></>
                             : (
                            <Input id={`filter_${filter.type}`} placeholder={filter.placeholder} onChange={(e) => setFilters({...filters, [filter.type]: e.target.value})} value={filters ? (filters[filter.type] ?? '') : null}
                                type={filter.type.startsWith('date') ? 'date' : 'text'}
                            />)
                    }
                </Th>
            )}
        </>
    )
}