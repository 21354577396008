import { Checkbox, Input, Th, Select } from '@chakra-ui/react'
import {useFilters} from 'hooks/useFilters/useFilters'
import {useCallback} from 'react'

const Options = ({ filter, users, devices }) => {
	switch (true) {
		case filter === 'user_id':
			return users.map(user =>
				<option key={user._id} value={`${user.name} ${user.last_name}`}>{`${user.name} ${user.last_name}`}</option>
			)
		case filter === 'device_number':
			return devices.map(device =>
				<option key={device} value={device}>{device}</option>
			)
		case filter === 'with_deleted':
			return <>
				<option value='withTrashed'>With deleted</option>
				<option value='onlyTrashed'>Only deleted</option>
			</>
		default:
			return <>
				<option value='Yes'>Yes</option>
				<option value='No'>No</option>
			</>
	}
}

export const ItemsCountedFilters = (props) => {
	const {countingId, handleFilterChange, counting} = props
	const {handleInputChange, handleExactMatchChange, handleSelectChange, users, devices} = useFilters(countingId, handleFilterChange)

	const handleInputChangeCallback = useCallback((type) => (event) => handleInputChange(type, event.target.value, false), [handleInputChange])
	const handleExactMatchChangeCallback = useCallback((type) => (event) => handleExactMatchChange(type, event.target.checked), [handleExactMatchChange])
	const handleSelectChangeCallback = useCallback((type) => (event) => handleSelectChange(type, event.target.value), [handleSelectChange])

	const filtersConfig = [
		{type: 'upc', placeholder: 'UPC', isSelect: false},
		{type: 'mkt_1', placeholder: 'MKT', isSelect: false},
		{type: '', isPlaceholder: true},
		{type: 'row_name', placeholder: 'Row', isSelect: false},
		counting.settings?.customRowNames ? {type: 'custom_row_name', placeholder: 'Custom Name', isSelect: false} : null,
		{type: '', isPlaceholder: true},
		{type: '', isPlaceholder: true},
		{type: 'device_number', placeholder: 'Device', isSelect: true},
		{type: 'user_id', placeholder: 'Name', isSelect: true},
		{type: 'is_found', placeholder: 'Invalid', isSelect: true},
		{type: 'is_manual', placeholder: 'Manual', isSelect: true},
		{type: 'with_deleted', placeholder: 'Without Deleted', isSelect: true},
	].filter(i=>i)


	

	return (
		<>
			{filtersConfig.map((filter, index) => filter.isPlaceholder ? <Th key={index}></Th>:
				<Th key={index}>
					{filter.isSelect ? (
						<Select placeholder={filter.placeholder} onChange={handleSelectChangeCallback(filter.type)}>
							<Options filter={filter.type} users={users} devices={devices} />
						</Select>
					) : (
						<>
							<Input placeholder={filter.placeholder} onChange={handleInputChangeCallback(filter.type)} />
							<Checkbox onChange={handleExactMatchChangeCallback(filter.type)}></Checkbox>
						</>
					)}
				</Th>
			)}
		</>
	)
}
