import {CountingAPI} from '../../apis/CountingAPI'
import {useApiToast} from '../useApi/useApiToast'
import {RowAPI} from '../../apis/RowAPI'

export const useRowsCountedEdit = (rowId, refreshCallback) => {
    const apiToast = useApiToast()
    const handleDelete = async () => {
        if (window.confirm('Are you sure?')) {
            await deleteRow()
            refreshCallback()
        }
    }

    const deleteRow = async () => {
        let resp = await RowAPI.delete(rowId)
        apiToast(resp)
        console.log('resp', resp)
    }

    return { handleDelete }
}