import {Th, Tr} from '@chakra-ui/react'
import {ItemsCountedFilters} from '../ItemsCountedFilters/ItemsCountedFilters'
import {ItemsCountedHeaderSortable} from '../ItemsCountedHeaderSortable/ItemsCountedHeaderSortable'

export const ItemsCountedTableHeader = (props) => {
    const {toggleSortOrder, sortConfig, handleFilterChange, countingId, counting, group_items} = props
    return (
        <>
            {!group_items && <Tr>
                <ItemsCountedFilters countingId={countingId} handleFilterChange={handleFilterChange} counting={counting}/>
            </Tr>}
            <Tr>
                <Th>UPC</Th>
                <Th>MKT</Th>
                <Th>
                    <ItemsCountedHeaderSortable fieldName='amount' title='Amount' toggleSortOrder={toggleSortOrder} sortConfig={sortConfig} />
                </Th>
                <Th>Description</Th>
                {!group_items && <Th>Row</Th>}
                {!group_items && <Th>Category</Th>}
                { counting.settings?.customRowNames && <Th>Custom Row Name</Th>}
                
                <Th>
                    <ItemsCountedHeaderSortable fieldName='mkt_1_length' title='Length' toggleSortOrder={toggleSortOrder} sortConfig={sortConfig} />                </Th>
                {!group_items && <><Th>Time</Th>
                <Th>Device</Th>
                <Th>Name</Th>
                <Th>Invalid</Th>
                <Th>Manual</Th></>}
            </Tr>
        </>
    )
}