import { useState, useEffect } from 'react'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { formatTimeToLocal } from '../../utlis/timeUtils'
import { useToast } from '@chakra-ui/react'
import { api } from 'apis/configs/axiosConfigs'
import { useParams } from 'react-router-dom'


export const useDownloadCountedRows = (testers, counting) => {
    const [isLoading, setIsLoading] = useState(false)
    // const [data, setData] = useState([])
    const { countingId } = useParams()
    const toast = useToast()

    const header = [
        'Test',
        'Row',
        'Amount',
        ...(counting.settings?.customRowNames ? [ 'Custom Row Name' ] : []),
        'Amount_t',
        'Category',
        'User',
        'Device',
        'Start',
        'Finish',
        'Tester',
    ]
   
    const formatRow = (row) => {
        return [
            row.test_passed === undefined ? '-' : (row.test_passed ? 'Passed' : 'Failed'), 
            row.name,
            row.amount,
            row.tester_amount,
            ...(counting.settings?.customRowNames ? [row.custom_row_name] : []),
            row.category_row?.name,
            `${row?.user?.name} ${row?.user?.last_name}`,
            row.device_number,
            formatTimeToLocal(row.created_at),
            formatTimeToLocal(row.closed_at || row.updated_at),
            //row.tester_id ? testers.find(tester => tester.id === row.tester_id).name : '',
            row.tester?.name ?? '',
        ]
    }

    const generateAndSaveExcel = async (workbook, filename) => {
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, filename)
    }

    const downloadCountedRows = async () => {
        setIsLoading(true)
        try {
            const { data } = await api.get('/rows/all' , { params: { counting_id: countingId } })
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Data')
            worksheet.addRow(header)
            data.data.forEach(row => 
                worksheet.addRow(formatRow(row))
            )

            await generateAndSaveExcel(workbook, 'ExportedRowsData.xlsx')

            toast({
                title: 'Downloading.',
                description: 'The rows data is being downloaded.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            console.error(error)
            toast({
                title: 'An error occurred.',
                description: 'Unable to download the rows data.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return {
        downloadCountedRows,
        isLoading,
    }
}
