import {Td, Tr} from '@chakra-ui/react'
import {DateHMS} from '../../../../Date/DateHMS'
import {useItemsCountedEdit} from '../../../../../hooks/useItems/useItemsCountedEdit'
import {ItemsCountedTableRowButtons} from './ItemsCountedTableRowButtons/ItemsCountedTableRowButtons'
import {InputEditable} from '../../../../Inputs/InputEditable/InputEditable'

export const ItemsCountedTableRow = (props) => {
    const {id, upc, mkt, description, row, category, amount, length, isFound, isManual, time, name, customRowName, device, refreshTable, refreshCounting, counting, group_items} = props

    const {
        isEditing,
        editedValues,
        handleEdit,
        handleSave,
        handleInputChange,
    } = useItemsCountedEdit(id, refreshTable, upc, mkt, amount)

    return (
        <Tr key={id}>
            <Td>
                <InputEditable isEditing={isEditing} value={editedValues.upc} onChange={handleInputChange} field='upc' />
            </Td>
            <Td>
                <InputEditable isEditing={isEditing} value={editedValues.mkt_1} onChange={handleInputChange} field='mkt_1' />
            </Td>
            <Td>
                <InputEditable isEditing={isEditing} value={editedValues.amount} onChange={handleInputChange} field='amount' />
            </Td>
            <Td>
                {description}
            </Td>
            {!group_items && <Td>{row}</Td> }
            <Td>{category}</Td>
            { counting.settings?.customRowNames && <Td>{customRowName}</Td>}
            <Td>{length}</Td>
            {!group_items && <><Td><DateHMS date={time} /></Td>

            <Td>{device}</Td>
            <Td>{name}</Td>
            <Td color={isFound ? 'green' : 'red'}>{isFound ? 'No' : 'Yes'}</Td>
            <Td color={!isManual ? 'green': 'red'}>{isManual ? 'Yes': 'No'}</Td>
            <Td>
                <ItemsCountedTableRowButtons
                    onEdit={handleEdit}
                    onSave={handleSave}
                    isEditing={isEditing}
                    id={id}
                    refreshTable={refreshTable}
                    refreshCounting={refreshCounting}
                    deletedAt={props.deletedAt}
                />
            </Td></>}
        </Tr>
    )
}
