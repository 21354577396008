import {Button} from '@chakra-ui/react'
import {useRowsCountedEdit} from '../../../../../hooks/useRows/useRowsCountedEdit'

export const RowsCountedDeleteButton = (props) => {
	const {id, refreshTable} = props
	const {handleDelete} = useRowsCountedEdit(id, refreshTable)
	return (
		<>
			<Button size='sm' colorScheme='red' mr={1} onClick={handleDelete}>
				Delete
			</Button>
		</>
	)
}