import { Input } from '@chakra-ui/react'

export const InputEditable = (props) => {
	const { value, onChange, isEditing, field, type } = props
	if (isEditing) {
		return (
			<Input {...(type ? {type} : {})} borderColor='green' value={value} onChange={onChange(field)} />
		)
	} else {
		return <>{value}</>
	}
}
