import { api } from './configs/axiosConfigs'

export const WorkingHoursAPI = {

    getByUser: async function (userId) {
        const response = await api.request({
            url: `/working-hours/user/${userId}`,
            method: 'GET',
        })

        return response.data
    },

    getAll: async function (filters) {
        filters && Object.keys(filters).map(item => !filters[item] && delete(filters[item]))
        const response = await api.get(`/working-hours`,{
            params: filters
        })

        return response.data
    },

    getPaginated: async function (filters) {
        filters && Object.keys(filters).map(item => !filters[item] && delete(filters[item]))
        const response = await api.get(`/working-hours/paginated`,{
            params: filters
        })

        return response.data
    },

    update: async function(id, values) {
        const response = await api.request({
            url: `/working-hours/${id}`,
            method: 'PUT',
            data: values
        })

        return response.data
    },
    
    delete: async function(id) {
        const response = await api.request({
            url: `/working-hours/${id}`,
            method: 'DELETE',
        })
        return response.data
    },

    deleteMany: async function(dateFrom, dateTo) {
        const response = await api.request({
            url: `/working-hours/delete-many`,
            method: 'POST',
            data: {dateFrom, dateTo}
        })
        return response.data
    }

}
