import { Button } from '@chakra-ui/react'
import useDownloadCounted from '../../../../hooks/useDownload/useDownloadCounted'

const DownloadCountingButton = (props) => {
    const { countingId, counting, group_items } = props
    const { downloadCounted, isLoading } = useDownloadCounted(countingId, counting, group_items)

    return (
        <Button
            onClick={downloadCounted}
            size='sm'
            colorScheme='teal'
            mr='1'
            isLoading={isLoading}
            loadingText='Generating...'
        >
            Download
        </Button>
    )
}

export default DownloadCountingButton
