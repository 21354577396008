import {Flex, Tag} from '@chakra-ui/react'

export const RowsCountedTableCaptionOld = (props) => {
	const {amount, total} = props
	return (
		<Flex direction='row'>
			<Tag colorScheme='blue'>Total: {total}</Tag>
			<Tag colorScheme='green'>Amount: {amount}</Tag>
		</Flex>
	)
}