import { Box, Text } from '@chakra-ui/react'
import {DateYMD} from '../../Date/DateYMD'
import {useItemsCounted} from 'hooks/useItems/useItemsCounted'

export const CountingHeader = ({ counting }) => {
	const { itemsCounted: {total_amount}, getItemsCounted } = useItemsCounted({counting_id: counting._id})
	
	return (
		<Box display='flex' justifyContent='space-between' alignItems='center' padding='1em' backgroundColor='gray.200' borderRadius='md' gap='1em'>
			<Text>
				<b>Date:</b> <DateYMD date={counting.date_start} />
			</Text>
			<Text>
				<b>Company:</b> {counting?.store?.company?.name}
			</Text>
			<Text>
				<b>Shop:</b> {counting?.store?.name}
			</Text>
			<Text>
				<b>Branch:</b> {counting?.store?.code}
			</Text>
			<Text>
				<b>Amount:</b> {total_amount ?? counting?.counted_items_amount}
			</Text>
		</Box>
	)
}
