import {Card, CardBody, FormLabel, Input, Button, Text, Spinner, Switch} from '@chakra-ui/react'
import {useFileUpload} from '../../../../hooks/useFile/useFileUpload'
import {FileUploadProgressBar} from '../../../FileUpload/FileUploadProgressBar/FileUploadProgressBar'
import {ProcessingSection} from './ProcessingSection/ProcessingSection'
import {CompleteSection} from './CompleteSection/CompleteSection'

export const CompanyCatalogModalForm = (props) => {
	const {id, onClose} = props
	const { uploading, processing, error, complete, progress,
		totalRows, goodRowsCount, badRowsCount,
		handleFileChange, handleFileUpload, handleSave, importing , isItm,handleChangeItm} = useFileUpload(id, onClose)

	return (
		<>
			<Card>
				<CardBody>
					<FormLabel>Catalog</FormLabel>
					<Input type='file' name='uploadedFile' onChange={handleFileChange}/>
					Is ITM:
					<Switch
						id='on-off-switch'
						isChecked={isItm}
						onChange={(e) => handleChangeItm(e.target.checked)}
					/>
					<br/>
					<Button m={2} onClick={handleFileUpload}>Upload</Button>
					{uploading && <FileUploadProgressBar progress={progress} />}
					{processing && <ProcessingSection />}
					{error &&
						<>
							<Text mt={4} color={'red'}>Error</Text>
						</>
					}
					{complete && (
						<CompleteSection
							totalRows={totalRows}
							goodRowsCount={goodRowsCount}
							badRowsCount={badRowsCount}
							handleSave={handleSave}
							importing={importing}
						/>
					)}
					{importing &&(
						<>
							<Text mt={4}>Importing</Text>
							<Spinner/>
						</>
					)}
				</CardBody>
			</Card>
		</>
	)
}
