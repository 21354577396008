import {Td, Tr, Input, Button } from '@chakra-ui/react'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, PopoverCloseButton, PopoverHeader } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import React from 'react'
import {DateHMS} from '../../../Date/DateHMS'
import {RowsCountedDeleteButton} from './RowsCountedDelete/RowsCountedDelete'
import {TesterSelect} from '../../Testers/TesterSelect/TesterSelect'
import {TesterAmount} from '../../Testers/TesterAmount/TesterAmount'
import {useTesterEditAmount} from '../../../../hooks/useTesters/useTesterEditAmount'
import {TestPassed} from '../../Testers/TestPassed/TestPassed'
import { RowAPI } from '../../../../apis/RowAPI'
import { useApiToast } from 'hooks/useApi/useApiToast'

export const RowsCountedTableRow = (props) => {
	const { amount, refreshTable, testers, testerDefault, getTesters, counting, row, diffRow } = props
	const {testerAmount, testerIdSelected, handleTesterAmountChange, handleTesterIdSelectedChange, saveTesterAmount} = useTesterEditAmount(row._id, row.tester_amount, getTesters, testerDefault)

	const [ editMode, setEditMode ] = React.useState(false)
	const [ editCustomRowName, setEditCustomRowName ] = React.useState(false)
	const [ updatedRow, setUpdatedRow ] = React.useState({ name: row.name, ...(counting.settings?.customRowNames ? {custom_row_name: row.custom_row_name ?? null} : {})})

	const apiToast = useApiToast()

	const updateRow = () => {
		RowAPI.update(row._id, updatedRow).then(resp => {
			apiToast(resp)
		})
		setEditMode(false)
		setEditCustomRowName(false)
	}

	return (
		<Tr>
			<Td>
				<TestPassed testPassed={row.test_passed} />
			</Td>
			<Td>
				{ !editMode 
					? <> <EditIcon onClick={() => setEditMode(true)} style={{ cursor: 'pointer'}}/> { updatedRow.name } </> 
					: <Input value={ updatedRow.name } 
							onChange={(e) => setUpdatedRow(prevState => ({...prevState, name: e.target.value }))} 
							onBlur={updateRow}/> }
			</Td>
			<Td>
				{ !diffRow ? amount :
					<Popover placement='right'>
						<PopoverTrigger>
							<Button colorScheme='red'> {amount} </Button>
						</PopoverTrigger>
						<PopoverContent w='400' bgGradient='linear(to-t, green.50, green.700)'>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Diff</PopoverHeader>
							<PopoverBody>
								{diffRow.values.map((v, index) => (<p key={index}>mkt_1 {v.mkt_1} upc {v.upc} diff {v.difference}</p>))}
							</PopoverBody>
						</PopoverContent>
					</Popover>
				}
			</Td>
			{	counting.settings?.customRowNames &&
				<Td>
					{ !editCustomRowName ? <><EditIcon onClick={()=>setEditCustomRowName(true)} style={{ cursor: 'pointer'}}/> {updatedRow.custom_row_name}</>
						: <Input value={updatedRow.custom_row_name} 
							onChange={(e) => setUpdatedRow(prevState => ({...prevState, custom_row_name: e.target.value }))} 
							onBlur={updateRow} />
					}
				</Td>
			}
			<Td>
				{row.category_row?.name}
			</Td>
			<Td>
				{`${row?.user?.name} ${row?.user?.last_name}`}
			</Td>
			<Td>
				{row.device_number}
			</Td>
			<Td>
				<DateHMS date={row.created_at} />
			</Td>
			<Td>
				<DateHMS date={row.closed_at || row.updated_at} />
			</Td>
			<Td>
				<TesterAmount handleChange={handleTesterAmountChange} amount={testerAmount} />
			</Td>
			<Td>
				<TesterSelect
					testerIdSelected={testerIdSelected || testerDefault}
					handleChange={handleTesterIdSelectedChange}
					testers={testers}
				/>
			</Td>
			<Td>
				<Button size={'sm'} colorScheme={'green'} mr={2} onClick={saveTesterAmount} >Save</Button>
				<RowsCountedDeleteButton refreshTable={refreshTable} id={row._id} />
			</Td>
		</Tr>
	)
}