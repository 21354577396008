import { useState } from 'react'
import { saveAs } from 'file-saver'
import * as ExcelJS from 'exceljs'
import { hoursFromSeconds } from '../../utlis/timeUtils'
import { useToast } from '@chakra-ui/react'

const useDownloadStatistics = (formatRow, filteredStatistics) => {
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    const getHeaderRow = () => [
        'Date',
        'Company',
        'Branch',
        'User',
        'Working Hours',
        'Items Counted',
        'Items per hour'
    ]

    // const formatRow = ({ created_at, counting, user, total_work_time, counted_items }) => {
    //     return [
    //         (new Date(created_at)).toLocaleDateString('he-IL'),
    //         counting?.store?.company?.name,
    //         counting?.store?.name,
    //         `${user?.name} ${user?.last_name}`,
    //         hoursFromSeconds(total_work_time),
    //         counted_items,
    //         total_work_time && Math.floor(counted_items/(total_work_time/60/60))
    //     ]
    // }

    const download = async () => {
        setIsLoading(true)
        //api.request({url: '/working-hours', params: filters })
        toast({
                title: 'Download started',
                description: 'The file will start downloading soon',
                status: 'info',
                duration: 5000,  
                isClosable: true,
            })
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Data')
        worksheet.addRow(getHeaderRow())
        
        filteredStatistics.forEach((statistic) => worksheet.addRow(formatRow(statistic)))
        worksheet.views = [{state: 'frozen', xSplit: 0, ySplit: 1}]
        workbook.xlsx.writeBuffer().then(buffer =>
           saveAs( new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }), 'statistics.xlsx')
        )

        setIsLoading(false)
    }

    return {download, isLoading}
}

export default useDownloadStatistics