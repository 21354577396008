import {Button} from '@chakra-ui/react'
import {CountedItemDelete} from '../../../CountedItemDelete/CountedItemDelete'

export const ItemsCountedTableRowButtons = (props) => {
	const {isEditing, onEdit, onSave, refreshTable, id, refreshCounting} = props
	return (
		<>
			{isEditing ? (
				<Button size={'sm'} colorScheme={'green'} onClick={onSave} mr={1}>Save</Button>
			) : (
				<Button size={'sm'} colorScheme={'green'} onClick={onEdit} mr={1}>Edit</Button>
			)}
			{props.deletedAt 
				? <Button size='sm' colorScheme='blue' mr='1' onClick={onSave}>Restore</Button> 
				: <CountedItemDelete id={id} refreshTable={refreshTable} refreshCounting={refreshCounting}/>
			}
		</>
	)
}