import {Button} from '@chakra-ui/react'
import React from 'react'

export const DeleteCountings = (props) => {
    const {isDeleting, handleDeleteCountings} = props
    return (
        <>
            <Button
                loadingText={'Deleting...'}
                isLoading={isDeleting}
                size={'sm'}
                colorScheme={'red'}
                onClick={handleDeleteCountings}
            >Delete</Button>
        </>
    )
}