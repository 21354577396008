import { Card, CardBody, HStack, Stack,  Input, Textarea, Button, Text, FormControl, FormLabel, Divider, Select } from '@chakra-ui/react'
import React, { useState, useContext } from 'react'
import useForm from 'hooks/useForm/useForm'
import { Signature } from '../InventoryResults/Signature'
import { api } from 'apis/configs/axiosConfigs'
import { CountingTabsContext } from '../CountingTabs/CountingTabs'


const getResults = async function (params) {
    const response = await api.request({
        url: 'counting/inventory-results/' + params.countingId,
    })

    return response.data.results
}

export const InventoryFeedback = () => {

    const { counting, refreshCounting, updateCounting, setCounting } = useContext(CountingTabsContext)
    const keyFields = [
        'storePreparation',
        'warehousePreparation',
        'teamWork',
        'generalComments',
        'arrivalOnTime',
        'serviceCountingManager',
        'serviceCountingTeam',
        'rowsChecks',
        'rowsChecksAmount',
        'countingErrors',
        'generalCommentsCounting',
        'improvementSuggestions',
        'countingManagerName',
        'countingManagerSignature',
        'storeManagerName',
        'manager'
    ]
     
    const { values, handleChange, clearValues, setValues } = useForm(
        keyFields.reduce((acc, f) => {
            acc[f] = counting.feedback && counting.feedback[f] ? counting.feedback[f] : ''
            return acc
        }, {})
    )

    const handleChangeExtra = (event) => {
        setCounting(prevState => ({...prevState, feedback: {...prevState.feedback, [event.target.name]: handleChange(event)}}))
    }

    const markList = ['', '1- לא היתה הכנה כלל', '2- גרועה', '3- טובה', '4- טובה מאוד', '5- מצוינת']
    const markList2 = ['', '1- לא היה שיתוף פעולה כלל', '2- חלקי', '3- סביר', '4- טוב מאוד', '5- מצוין']


    const [managerSignature, setManagerSignature] = useState(counting.feedback?.managerSignature || '')
    const [storeManagerSignature, setStoreManagerSignature] = useState(counting.feedback?.storeManagerSignature || '')

    // useEffect(() => {
    //     // const fetchData = async () => {
    //     //     const result = await getResults({countingId: counting._id})
    //     //     const newValues = {}
    //     //     if (result.feedback) {
    //     //         Object.keys(result.feedback).forEach(key => { 
    //     //             if (values[key] !== undefined) {
    //     //                 newValues[key] = result.feedback[key] ?? ''
    //     //             }
    //     //         })

                

    //     //         result.feedback.managerSignature && 
    //     //             setManagerSignature(result.feedback.managerSignature)
    //     //         result.feedback.storeManagerSignature &&
    //     //             setStoreManagerSignature(result.feedback.storeManagerSignature)
                
    //     //         setValues({...values, ...newValues})
    //     //     }
    //     //     result.manager && setValues({...values, manager: result.manager})
    //     // }

    //     // fetchData()
    //     refreshCounting()
    // }, [])


    return (
        <Card>
            <CardBody style={{ direction: 'rtl' }}>
                <HStack justifyContent='start' alignItems='end' spacing='10vw'>
                    <Stack spacing={4} direction='column' alignItems='start'>
                        {/* <div>שם חברה: <strong>{results.store?.company?.name}</strong></div>
                        <div>שם סניף: <strong>{results.store?.name} {results.store?.code}</strong></div>
                        <div>תאריך ספירה: <strong>{(new Date(results.date_start)).toLocaleDateString('he-IL')}</strong></div> */}

                        <Text fontSize='2xl'>ימולא ע&quot;י מנהל/ת הספירה</Text>
                        <FormControl>
                            <FormLabel>אופן הכנת החנות לספירה (הוצאת טיקטים/ סדר):</FormLabel>
                            <Select name='storePreparation' value={values.storePreparation} onChange={handleChangeExtra} form='getPdf' paddingStart='10px'>
                                {markList.map((mark, index) => <option key={index} value={mark}>{mark}</option>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>אופן הכנת המחסן לספירה (הוצאת טיקטים/ סדר):</FormLabel>
                            <Select name='warehousePreparation' value={values.warehousePreparation} onChange={handleChangeExtra} form='getPdf'>
                                {markList.map((mark, index) => <option key={index} value={mark}>{mark}</option>)}
                            </Select>
                            {/* <Input name='warehousePreparation' value={values.warehousePreparation} onChange={handleChange} form='getPdf'/> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>שיתוף פעולה של צוות החנות:</FormLabel>
                            <Select name='teamWork' value={values.teamWork} onChange={handleChangeExtra} form='getPdf'>
                                {markList2.map((mark, index) => <option key={index} value={mark}>{mark}</option>)}
                            </Select>
                            {/* <Input name='teamWork' value={values.teamWork} onChange={handleChange} form='getPdf'/> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>הערות כלליות:</FormLabel>
                            <Textarea name='generalComments' value={values.generalComments} onChange={handleChangeExtra} form='getPdf'/>
                        </FormControl>
                        <Divider />

                        <Text fontSize='2xl'>ימולא ע&quot;י מנהל/ת החנות:</Text>
                        <FormControl>
                            <FormLabel>הגעה לחנות בזמן שנקבע:</FormLabel>
                            <Select name='arrivalOnTime' value={values.arrivalOnTime} onChange={handleChangeExtra} form='getPdf'>
                                {['כן, הגיעו בזמן', 'לא, איחרו'].map((mark, index) => 
                                    <option key={index} value={mark}>{mark}</option>
                                    )}
                            </Select>
                            {/* <Input name='arrivalOnTime' value={values.arrivalOnTime} onChange={handleChange} form='getPdf'/> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>שירות מנהל הספירה:</FormLabel>
                            <Select name='serviceCountingManager' value={values.serviceCountingManager} onChange={handleChangeExtra} form='getPdf'>
                                {markList2.map((mark, index) => <option key={index} value={mark}>{mark}</option>)}
                            </Select>
                            {/* <Input name='serviceCountingManager' value={values.serviceCountingManager} onChange={handleChange} form='getPdf'/> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>שירות צוות הספירה:</FormLabel>
                            <Select name='serviceCountingTeam' value={values.serviceCountingTeam} onChange={handleChangeExtra} form='getPdf'>
                                {markList2.map((mark, index) => <option key={index} value={mark}>{mark}</option>)}
                            </Select>
                            {/* <Input name='serviceCountingTeam' value={values.serviceCountingTeam} onChange={handleChange} form='getPdf'/> */}
                        </FormControl>
                        <HStack alignItems='flex-end'>
                            <FormControl>
                                <FormLabel>האם בוצעה בדיקת איתורים לאחר ספירה? </FormLabel>
                                <Select name='rowsChecks' value={values.rowsChecks} onChange={handleChangeExtra} form='getPdf'>
                                    {['כן', 'לא'].map((mark, index) => <option key={index} value={mark}>{mark}</option>)}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>כמות איתורים</FormLabel>
                                <Input type='number' name='rowsChecksAmount' value={values.rowsChecksAmount} onChange={handleChangeExtra} form='getPdf' />
                            </FormControl>
                        </HStack>
                        <FormControl>
                            <FormLabel>כמות טעויות ספירה:</FormLabel>
                            <Input type='number' name='countingErrors' value={values.countingErrors} onChange={handleChangeExtra} form='getPdf'/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>הערות כלליות לגבי ביצוע הספירה:</FormLabel>
                            <Input name='generalCommentsCounting' value={values.generalCommentsCounting} onChange={handleChangeExtra} form='getPdf'/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>הצעות לשיפור</FormLabel>
                            <Input name='improvementSuggestions' value={values.improvementSuggestions} onChange={handleChangeExtra} form='getPdf' />
                        </FormControl>

                        <div> 
                            <div>שם מנהל/ת הספירה <strong>{!!counting.manager && `${counting.manager.name} ${counting.manager.last_name}`}</strong></div>
                            <div>חתימה מנהל/ת הספירה</div>
                            <Signature signature={managerSignature} 
                                setSignature={setManagerSignature} 
                                name='feedback.managerSignature'
                                setCounting={data => setCounting(prevState => ({...prevState, 
                                    feedback: { ...prevState.feedback, ...data.feedback}})
                                )}
                                />
                            <div>שם מנהל/ת החנות <strong>{counting.manager_name_manually ?? ''}</strong></div>
                            <div>חתימה מנהל/ת החנות</div>
                            <Signature signature={storeManagerSignature} 
                                setSignature={setStoreManagerSignature} 
                                name='feedback.storeManagerSignature' 
                                setCounting={data => setCounting(prevState => ({...prevState, 
                                    feedback: { ...prevState.feedback, ...data.feedback}})
                                )}
                                />
                        </div>

                        {/* <Button type='button' colorScheme='green' 
                            onClick={() => {
                                updateCounting(counting._id, {feedback: values})
                            }}>
                            שמירה
                        </Button> */}

                        <form id='getPdf' action={`${api.defaults.baseURL}/counting/feedback/${counting._id}`} target='_blank' method='get'>
                            <Button type='submit' colorScheme='yellow'>הורדה</Button>
                        </form>

                    </Stack>
                </HStack>
            </CardBody>
        </Card>
    )
}