import {useCallback, useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'

export const useItemsEdited = (countingId) => {
    const [itemsEdited, setItemsEdited] = useState([])

    const fetchItemsEdited = useCallback(async (id) => {
        const resp = await CountingAPI.getItemsEdited(id)
        // console.log('resp', resp)
        if (resp?.items_edited) {
            let itemsEdited = resp.items_edited.map(item => ({
                ...item,
                fullName: `${item.user.name} ${item.user.last_name}`
            }))
            setItemsEdited(itemsEdited)
        }
    }, [])

    const getItemsEdited = () => {
        fetchItemsEdited(countingId).catch((error) => {
            console.error('Failed to fetch items:', error)
        })
    }
    useEffect(() => {
        getItemsEdited()
    }, [countingId])
    return {itemsEdited, getItemsEdited}
}