import React from 'react'
import ReactPaginate from 'react-paginate'
import {Box} from '@chakra-ui/react'
import './pagination.css'

const Pagination = (props) => {
    const {lastPage, getData} = props
    const handlePageClick = (data) => {
        let selected = data.selected + 1
        getData({ page: selected })
    }
    return (
        <Box display='flex' justifyContent='center' mt={4}>
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={lastPage}  // число страниц
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'disabled'}
            />
        </Box>
    )
}

export default Pagination