import {CountingAPI} from '../../apis/CountingAPI'
import {useState} from 'react'

export const useCounting = (countingId, inputManagerSelectedId) => {
    const [managerSelectedId, setManagerSelectedId] = useState(inputManagerSelectedId)

    const handleManagerChange = (e) => {
        console.log('handleManagerChange', e)
        setManagerSelectedId(e.target.value)
        CountingAPI.update(countingId, {manager_id: e.target.value})
    }


    return {
        managerSelectedId,
        handleManagerChange
    }
}