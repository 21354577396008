import {
    Card,
    CardBody,
    Flex,
    Heading,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import React from 'react'
import {UsersTableRow} from './UsersTableRow/UsersTableRow'
import {UserModal} from '../UserAdd/UserModal/UserModal'

export const UsersTable = (props) => {
    const {users, fetchUsers} = props
    return (
        <Card m={2}>
            <Flex justifyContent='space-between' alignItems='center'>
                <Heading m={3} size='md'> Workers </Heading>
                <UserModal fetchUsers={fetchUsers} mode={'new'}/>
            </Flex>
            <CardBody>
                <TableContainer>
                    <Table variant='striped'>
                        <TableCaption>List of the Countings</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>TZ</Th>
                                <Th>Name</Th>
                                <Th>Roles</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users.map(user=>(
                                <UsersTableRow
                                    key={user._id}
                                    _id={user._id}
                                    tz={user.identify}
                                    firstName={user.name}
                                    lastName={user.last_name}
                                    isCounter={user.is_counter}
                                    isShiftManager={user.is_shift_manager}
                                    isCountingManager={user.is_counting_manager}
                                    isAdmin={user.is_admin}
                                    fetchUsers={fetchUsers}
                                />
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </CardBody>
        </Card>
    )
}