import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent, ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure
} from '@chakra-ui/react'
import {StoresImportModalButton} from './StoresImportModalButton/StoresImportModalButton'
import React from 'react'
import {StoresImportModalForm} from './StoresImportModalForm/StoresImportModalForm'

export const StoresImportModal = (props) => {
	const {id, name, fetchStores} = props
	const {isOpen, onOpen, onClose} = useDisclosure()
	return (
		<>
			<StoresImportModalButton onOpen={onOpen}/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay/>
				<ModalContent>
					<ModalHeader>Shops import {name}</ModalHeader>
					<ModalCloseButton/>
					<ModalBody>
						<StoresImportModalForm
							onClose={onClose}
							id={id}
							fetchStores={fetchStores}
						/>
					</ModalBody>
					<ModalFooter>

					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}