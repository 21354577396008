import { Td, Tr } from '@chakra-ui/react'
import {useRowsDoubleEdit} from '../../../../hooks/useRows/useRowsDoubleEdit'
import {RowsDoubleRowNameCell} from './RowsDoubleRowNameCell/RowsDoubleRowNameCell'
import {RowsDoubleEditableInput} from './RowsDoubleEditableInput/RowsDoubleEditableInput'
import {RowsDoubleActionButtons} from './RowsDoubleActionButtons/RowsDoubleActionButtons'

export const RowsDoubleRow = (props) => {
    const { rowName, deviceName, amount, userName, userId, countingId, getRowsDouble, refreshCounting } = props

    const {
        isEditing,
        editedRowName,
        handleEdit,
        handleSave,
        handleInputChange,
        handleDelete,
    } = useRowsDoubleEdit(rowName, deviceName, amount, userId, countingId, getRowsDouble, refreshCounting)

    return (
        <Tr style={{ height: '32px' }}>
            <RowsDoubleRowNameCell
                rowName={isEditing ? (
                    <RowsDoubleEditableInput value={editedRowName} onChange={handleInputChange} />
                ) : (
                    rowName
                )}
            />
            <Td>{amount}</Td>

            <Td>{deviceName}</Td>
            <Td>{userName}</Td>
            <RowsDoubleActionButtons isEditing={isEditing} onEdit={handleEdit} onSave={handleSave} onDelete={handleDelete} />
        </Tr>
    )
}