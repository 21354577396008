import React, { useState } from 'react'
import { Button, FormLabel, Input } from '@chakra-ui/react'

export const TesterAdd = (props) => {
	const { addTester } = props

	const [inputValue, setInputValue] = useState('')

	const handleInputChange = (e) => {
		setInputValue(e.target.value)
	}

	const handleClick = () => {
		addTester(inputValue)
		setInputValue('')
	}

	return (
		<>
			<FormLabel>Tester Name:</FormLabel>
			<Input
				size={'sm'}
				w={'200px'}
				maxLength={20}
				mr={1}
				value={inputValue}
				onChange={handleInputChange}
			/>
			<Button size={'sm'} onClick={handleClick} type='submit'>Add</Button>
		</>
	)
}
