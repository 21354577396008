import {useEffect, useState, useCallback} from 'react'
import {CompanyAPI} from 'apis/CompanyAPI'
import {useApiToast} from '../useApi/useApiToast'

export const useStores = (companyId) => {
    const [stores, setStores] = useState([])
    const [company, setCompany] = useState({})
    const showToast = useApiToast()
    const fetchStores = useCallback(async () => {
        const resp = await CompanyAPI.getStores(companyId)
        //console.log('stores', resp.stores)
        setStores(resp.stores)
        setCompany(resp.company)
    }, [companyId])

    const deleteStore = async (storeId) => {
        let resp =await CompanyAPI.deleteStore(storeId, companyId)
        fetchStores() // Refresh the store list after deletion
        showToast(resp)
    }

    useEffect(() => {
        fetchStores()
    }, [fetchStores])

    return {stores, fetchStores, setStores, company, deleteStore} // Added deleteStore to returned object
}
