import React from 'react'
import {Card, CardBody} from '@chakra-ui/react'
import {RowsCountedTableOld} from './RowsCountedTable/RowsCountedTableOld'
import {useRowsCountedOld} from '../../../hooks/useRows/useRowsCountedOld'
import {RowsFilterOld} from './RowsFilter/RowsFilterOld'

const RowsCountedOld = (props) => {
    const {countingId} = props
    const { rows, getRowsCounted, totalAmount, totalRows } = useRowsCountedOld({countingId: countingId})
    return (
        <div>
            <Card m={1}>
                <CardBody>
                    <RowsFilterOld getRows={getRowsCounted} countingId={countingId}/>
                </CardBody>
            </Card>
            <Card m={1}>
                <CardBody>
                    <RowsCountedTableOld
                        totalAmount={totalAmount}
                        totalRows={totalRows}
                        rows={rows}
                        getRowsCounted={getRowsCounted}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default RowsCountedOld