import { Swiper, SwiperSlide } from 'swiper/react'
import {  Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, Zoom, EffectFade } from 'swiper/modules'
import { Image, Input, Box } from '@chakra-ui/react'
import { useEffect, useState, useContext } from 'react'
// import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'
import { CountingAPI } from 'apis/CountingAPI'
import { CountingTabsContext } from 'components/Counting/CountingTabs/CountingTabs'
// import 'swiper/css/navigation'
// import 'swiper/css/pagination'
// import 'swiper/css/scrollbar'


const Galery = () => {
  const [ images, setImages ] = useState([])
  // useEffect(() => {
  //   const swiper = new Swiper('.swiper-container', {
  //     modules: [Navigation, Pagination],
  //     navigation: {
  //       nextEl: '.swiper-button-next',
  //       prevEl: '.swiper-button-prev',
  //     },
  //     pagination: {
  //       el: '.swiper-pagination',
  //       clickable: true,
  //     },
  //   })
  // }, [])

  // const [file, setFile] = useState(null)

  const { counting } = useContext(CountingTabsContext)
  const handleFileUpload = (event) => {
    if(!event.target.files.length) return
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    CountingAPI.photoUpload(counting._id, formData).then((response) => {
      setImages(response.file_list)
    })
  }

  useEffect(() => {
    CountingAPI.photoList(counting._id).then((response) => {
      setImages(response.file_list)
    })
  }, [])

  return (
    <Box p='4'  maxW='2xl' w='100%' borderWidth='1px'>
      <Input type='file' accept='image/*' multiple onChange={handleFileUpload}/>
      {images.length > 0 &&
        <Swiper
              width={'300'}
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, Zoom]}
              
              spaceBetween={50}
              slidesPerView={2}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              virtual={false}
              zoom={true}
              // onSlideChange={() => console.log('slide change')}
        >
          {images.map((image, index) => (
            <SwiperSlide key={image} virtualIndex={index} width={'200'}>
              <a href={ `${process.env.REACT_APP_API_BASE_URL}/storage/${image.replace('public/', '')}` } target='_blank' rel='noreferrer'>
                <Image alt={'Logo'}  src={ `${process.env.REACT_APP_API_BASE_URL}/storage/${image.replace('public/', '')}` } loading='lazy' />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      }
    </Box>
  )
}

export default Galery
