import React from 'react'
import useAuthStore from '../../stores/authStore'
import {Route, Routes, Navigate} from 'react-router-dom'
import Countings from '../Countings/Countings'
import Companies from '../Companies/Companies'
import Stores from '../Stores/Stores'
import LoginForm from '../Login/LoginForm/LoginForm'
import RowsMissed from '../Counting/RowsMissed/RowsMissed'
import JsonUpload from '../JsonUpload/JsonUpload'
import RowsCountedOld from '../Counting/RowsCountedOld/RowsCountedOld'
import {RowsDouble} from '../Counting/RowsDouble/RowsDouble'
import {ItemsInvalid} from '../Counting/Items/ItemsInvalid/ItemsInvalid'
import {RowsEmpty} from '../Counting/RowsEmpty/RowsEmpty'
import {Counting} from '../Counting/Counting'
import {Users} from '../Users/Users'
import {WorkingHours} from '../WorkingHours/WorkingHours'
import { Statistics } from '../Statistics'

const AppRouter = () => {
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn)

    return (
        <Routes>
            {isLoggedIn ? (
                <>
                    <Route path='countings' element={<Countings/>}/>
                    <Route path='' element={<Navigate to='/countings'/>}/>
                    <Route path='companies' element={<Companies/>}/>
                    <Route path='stores/:company_id' element={<Stores/>}/>
{/*<Route path="counting/:countingId" element={<CountingSettings />} />*/}
                    <Route path='counting-rows/missing/:countingId' element={<RowsMissed/>}/>
                    <Route path='json/upload' element={<JsonUpload/>}/>
                    <Route path='counting-rows/:countingId' element={<RowsCountedOld/>}/>
                    <Route path='counting-rows/double/:countingId' element={<RowsDouble/>}/>
                    <Route path='counting/items/missing/:countingId' element={<ItemsInvalid/>}/>
                    <Route path='counting-rows/empty/:countingId' element={<RowsEmpty/>}/>
                    <Route path='counting/:countingId' element={<Counting/>}/>
                    <Route path='users' element={<Users/>}/>
                    <Route path='working-hours/user/:userId' element={<WorkingHours/>}/>
                    <Route path='working-hours' element={<WorkingHours/>}/>
                    <Route path='statistics' element={<Statistics/>} />

                </>
            ) : (
                <Route path='*' element={<LoginForm/>}/>
            )}
        </Routes>
    )
}

export default AppRouter