import {useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'
import {useApiToast} from '../useApi/useApiToast'

export const useTesters = (countingId) => {
    const [testers, setTesters] = useState([])
    const [testerDefault, setTesterDefault] = useState(undefined)
    const apiToast = useApiToast()
    const addTester = async (testerName) => {
        const resp = await CountingAPI.createTester(countingId, testerName)
        await getTesters()
        // await refreshCallback()
        apiToast(resp)
    }
    const getTesters = async () => {
        const resp = await CountingAPI.getCounting(countingId)
        setTesters(resp.counting.testers)
        setTesterDefault(resp.counting?.tester_default)
        console.log('useTesters testerDefault', resp.counting?.tester_default)
    }

    useEffect(() => {
        getTesters()
    }, [])

    useEffect(() => {
        console.log('useTesters useEffect testerDefault', testerDefault)
    }, [testerDefault])
    return {
        testers,
        testerDefault,
        addTester,
        getTesters,
    }
}