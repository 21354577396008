import { Input } from '@chakra-ui/react'

export const RowsDoubleEditableInput = ({ value, onChange }) => {
	return (
		<Input
			borderColor='green'
			width={20}
			value={value}
			onChange={onChange}
		/>
	)
}
