import {Button, Td, Tr} from '@chakra-ui/react'
import {UserModal} from '../../UserAdd/UserModal/UserModal'
import {NavLink} from 'react-router-dom'

export const UsersTableRow = (props) => {
    const {tz, lastName, firstName, isCounter, isAdmin, isShiftManager, isCountingManager, _id, fetchUsers} = props
    return (
        <Tr>
            <Td>
                {tz}
            </Td>
            <Td>
                {firstName} {lastName}
            </Td>
            <Td>
                {isCounter ? 'Counter, ' : ''}
                {isShiftManager ? 'Shift Manager, ' : ''}
                {isCountingManager ? 'Counting Manager, ' : ''}
                {isAdmin ? 'Admin' : ''}
            </Td>
            <Td>
                <UserModal
                    mode='edit'
                    _id={_id}
                    tz={tz}
                    lastName={lastName}
                    firstName={firstName}
                    isCounter={isCounter}
                    isAdmin={isAdmin}
                    isShiftManager={isShiftManager}
                    isCountingManager={isCountingManager}
                    fetchUsers={fetchUsers}
                />
                <Button size={'sm'} mr={2} as={NavLink} to={`/working-hours/user/${_id}`} colorScheme='yellow'>Hours</Button>
            </Td>
        </Tr>
    )
}