import React from 'react'
import {Select} from '@chakra-ui/react'

const SelectCountingAdd = (props) => {
    const handleChange = props.handleChange
    const selectedId = props.selectedId
    const items = props.items
    const placeholder = props.placeholder
    return (
        <Select onChange={handleChange} value={selectedId} placeholder={placeholder}>
            {items.map(item => (
                <option key={item._id} value={item._id}>{item.name}</option>
            ))}
        </Select>
    )
}

export default SelectCountingAdd