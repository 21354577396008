import {Card, CardBody, Checkbox, FormLabel, Grid, Input} from '@chakra-ui/react'
import useAuthStore from '../../../../stores/authStore'

export const UserForm = (props) => {
	const {values, handleChange} = props
	const user = useAuthStore((state) => state.user)
	console.log(props)
	return (
		<>
			<Card>
				<CardBody>
					<FormLabel>TZ</FormLabel>
					<Input name='identify' value={values.identify} onChange={handleChange} />

					<FormLabel>First Name</FormLabel>
					<Input name='name' value={values.name} onChange={handleChange}/>

					<FormLabel>Last Name</FormLabel>
					<Input name='last_name' value={values.last_name} onChange={handleChange}/>

					<FormLabel>Password</FormLabel>
					<Input name='password' value={values.password} onChange={handleChange}/>
					<Grid templateColumns='max-content 1fr'>
						<FormLabel mb='0'>Counter</FormLabel>
						<Checkbox isChecked={values.is_counter} name='is_counter' onChange={handleChange} ml='2' />

						<FormLabel mb='0'>Shift manager</FormLabel>
						<Checkbox isChecked={values.is_shift_manager} name='is_shift_manager' onChange={handleChange} ml='2' />

						<FormLabel mb='0'>Counting manager</FormLabel>
						<Checkbox isChecked={values.is_counting_manager} name='is_counting_manager' onChange={handleChange} ml='2' />
						{user?.is_admin && (
							<>
								<FormLabel mb='0'>Admin</FormLabel>
								<Checkbox isChecked={values.is_admin} name='is_admin' onChange={handleChange} ml='2' />
							</>
						)}
					</Grid>
				</CardBody>
			</Card>
		</>
	)
}