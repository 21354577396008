import {api} from './configs/axiosConfigs'
import {defineCancelApiObject} from './configs/axiosUtils'

export const CompanyAPI = {
    getAll: async function (cancel = false) {
        const response = await api.request({
            url: '/company/all-app',
            method: 'GET',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getStores: async function (companyId, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}/stores`,
            method: 'GET',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    addStore: async function (companyId, store, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}/store`,
            method: 'POST',
            data: {store: store},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    addStores: async function (companyId, stores, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}/add-stores`,
            method: 'POST',
            data: {stores: stores},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    addCounting: async function (params, cancel=false) { //storeId, counting, date, cancel = false
        const response = await api.request({
            url: '/counting/add-counting',
            method: 'POST',
            data: params,  //{store_id: storeId, counting: counting, date_start: date},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    downloadCounted: async function (countingId, cancel = false, group_items) {
        const response = await api.request({
            url: `/counting/download/${countingId}`,
            method: 'GET',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
            ...(group_items ? {params: {group_items}} : {} )
        })

        return response.data
    },

    downloadItems: async function(countingId, cancel = false, group_items) {
        const self = this
        const [resp1, resp2] = await Promise.all([
            api.request({
                url: `/counting/download/${countingId}`,
                method: 'GET',
                signal: cancel ? cancelApiObject[self.getAll.name].handleRequestCancellation().signal : undefined,
                ...(group_items ? {params: {group_items}} : {} )
            }),
            api.request({
                url: `/counting/rows/${countingId}`,
                method: 'GET',
                signal: cancel ? cancelApiObject[self.downloadItems.name].handleRequestCancellation().signal : undefined,
            })
        ])
        
        return { ...resp1.data, ...resp2.data }
    },

    update: async function (companyId, data, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}`,
            method: 'PATCH',
            data: {_id: companyId, ...data},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    create: async function (data, cancel = false) {
        const response = await api.request({
            url: `/company/create`,
            method: 'POST',
            data: {...data},
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    deleteStore: async function (storeId, companyId, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}/store/${storeId}`,
            method: 'DELETE',
        })
        return response.data
    },
    uploadFile: async function (companyId, file, isItm, onUploadProgress, cancel = false) {
        let formData = new FormData()
        formData.append('uploadedFile', file)
        formData.append('isItm', isItm)
        const response = await api.request({
            url: `/company/${companyId}/catalog/upload`,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress,
            signal: cancel ? cancelApiObject[this.uploadFile.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    catalogReplaceFile: async function (companyId, tmpFileName, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}/catalog/replace`,
            data: {tmpFileName: tmpFileName},
            method: 'POST',
        })
        return response.data
    },
    uploadStoresFile: async function (companyId, file, onUploadProgress, cancel = false) {
        let formData = new FormData()
        formData.append('uploadedFile', file)
        const response = await api.request({
            url: `/company/${companyId}/stores/upload`,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress,
            signal: cancel ? cancelApiObject[this.uploadFile.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    delete: async function (companyId, cancel = false) {
        const response = await api.request({
            url: `/company/${companyId}`,
            method: 'DELETE',
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    }

}

const cancelApiObject = defineCancelApiObject(CompanyAPI)