import {Spinner, Table, TableCaption, TableContainer, Tbody, Th, Thead, Tr} from '@chakra-ui/react'
import {RowsDoubleRow} from './RowsDoubleRow'
import {RowsDoubleTableCaption} from './RowsDoubleTableCaption/RowsDoubleTableCaption'
import {Captions} from '../../Captions'

export const RowsDoubleTable = (props) => {
    const {rowsDouble, countingId, getRowsDouble, rowsDoubleCount, refreshCounting, loading} = props


    return (
        <TableContainer>
            <Table>
                <TableCaption placement={'top'}>
                    {rowsDoubleCount !==undefined && <Captions total={rowsDoubleCount} />}
                    {/* <RowsDoubleTableCaption total={rowsDoubleCount} /> */}
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>
                            Row name
                        </Th>
                        <Th>
                            Amount
                        </Th>
                        <Th>
                            Device
                        </Th>
                        <Th>
                            Name
                        </Th>
                        <Th>
                            Actions
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    { rowsDouble.map(rowDouble => (
                        <RowsDoubleRow
                            key={rowDouble.row_name + rowDouble.device_number + rowDouble.user._id}
                            rowName={rowDouble.row_name}
                            deviceName={rowDouble.device_number}
                            amount={rowDouble.amount}
                            userName={`${rowDouble?.user?.name} ${rowDouble?.user?.last_name}`}
                            userId={rowDouble?.user?._id}
                            countingId={countingId}
                            getRowsDouble={getRowsDouble}
                            refreshCounting={refreshCounting}
                        />
                    ))}
                </Tbody>
            </Table>
            {loading && <Spinner/>}
        </TableContainer>
    )
}
