import {Button,Text} from '@chakra-ui/react'

export const StoresImportSection = (props) => {
	const { newCount, existedCount, storesToDeleteCount, handleSave } = props
	return (
		<>
			<Text mt={4}>Processing complete</Text>
			<Text>New shops: {newCount}</Text>
			<Text>Existed shops: {existedCount}</Text>
			<Text>Shops to delete: {storesToDeleteCount}</Text>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Button m={2} colorScheme={'blue'} onClick={handleSave}>Import</Button>
			</div>
		</>
	)
}