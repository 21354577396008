import {useEffect, useState} from 'react'
import {CountingAPI} from '../../apis/CountingAPI'

export const useRowsCountedOld = (initialFilters) => {
    const [rows, setRows] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [filters, setFilters] = useState(initialFilters)
    const [loading, setLoading] = useState(false)
    const getRowsCounted = async (countingId, deviceName = null, categoryId = null) => {
        const resp = await CountingAPI.getRowsOld(countingId, deviceName, categoryId)
        setRows(resp.rows)
    }

    useEffect(() => {
        // debugger
        getRowsCounted(initialFilters.countingId)

    }, [initialFilters])

    useEffect(() => {
        let amountShow = 0
        let rowsShow = 0
        rows.map(row => {
            rowsShow++
            amountShow += row.total_amount
        })
        setTotalRows(rowsShow)
        setTotalAmount(amountShow)
    }, [rows])
    
    return {rows, getRowsCounted, totalRows, totalAmount}

}