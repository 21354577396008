import { Box, Progress, Text } from '@chakra-ui/react'

export const FileUploadProgressBar = ({ progress }) => {
	return (
		<>
			<Text mt={4}>Uploading</Text>
			<Box h='2' borderRadius='md' bg='lightgray' mt={4}>
				<Progress hasStripe value={progress} borderRadius='md' bg='green.400' />
			</Box>
		</>
	)
}
