import {create} from 'zustand'

const getUserFromLocalStorage = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
        try {
            return JSON.parse(userString)
        } catch (error) {
            console.log('Error parsing user from localStorage:', error)
            return null
        }
    }
    return null
}

const useAuthStore = create((set) => ({
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    user: getUserFromLocalStorage(),

    setIsLoggedIn: (isLoggedIn) => {
        localStorage.setItem('isLoggedIn', isLoggedIn)
        set({isLoggedIn})
    },

    setUser: (user) => {
        console.log('set user', user)
        localStorage.setItem('user', JSON.stringify(user))
        set({user})
    },
}))

export default useAuthStore
