import {Button} from '@chakra-ui/react'
import React from 'react'

export const StoresImportModalButton = (props) => {
    const {onOpen} = props
    return (
        <>
            <Button mr={2} size='sm' colorScheme='green' onClick={onOpen}>Import</Button>
        </>
    )
}