import {Select} from '@chakra-ui/react'
import React from 'react'

export const RowsFilterSelectOld = (props) => {
    const {items, itemSelected, setItemSelected} = props
    const handleChangeDevice = (event) => {
        setItemSelected(event.target.value)
        console.log(itemSelected)
    }
    return (
        <Select onChange={handleChangeDevice} placeholder='Select Device' value={itemSelected}>
            {items.map(device => (
                <option key={device} value={device}>{device}</option>
            ))}
        </Select>
    )
}