import moment from 'moment-timezone'

export const formatSecondsToTime = (secs) => {
    if(!secs) return '00:00:00'
    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor((secs - hours * 3600) / 60)
    let seconds = secs % 60

    // Ensuring two-digit format
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds

    return `${hours}:${minutes}:${seconds}`
}
export const formatTimeToLocal = (isoString) => {
    if (!isoString) {
        return ''
    }
    const date = new Date(isoString)

    let formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    })

    // Fix a bug with the above function where 24:00:00 shows as 00:00:00
    if (formattedTime.startsWith('24')) {
        formattedTime = '00' + formattedTime.slice(2)
    }

    return formattedTime
}

export const formatDateToLocal = (isoString) => {
    if (!isoString) {
        return ''
    }
    const date = new Date(isoString)
    // Options for the format
    const options = {year: 'numeric', month: 'numeric', day: 'numeric'}

    // This will default to the user's locale if no locale is provided
    return date.toLocaleDateString(undefined, options)
}

export const isoToDateTimeLocal = isoString => {
    if (!isoString) {
        return ''
    }
    return moment.tz(isoString, 'Asia/Jerusalem').format('YYYY-MM-DDTHH:mm')
}

export const hoursFromSeconds = s => [parseInt(s / 60 / 60), parseInt(s / 60 %60)].join(':').replace(/\b(\d)\b/g,'0$1')