import React, { useEffect } from 'react'
import {Card, CardBody, CardHeader, Heading, Button, FormLabel, HStack, VStack, Select, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react'
import useAuthStore from '../../../stores/authStore'
import SelectCountingAdd from './SelectCountingAdd'
import {CountingAddDate} from './CountingAddDate/CountingAddDate'


const CountingAdd = (props) => {
    const {companies, selectedCompanyId, selectedStoreId, handleChangeCompany, handleChangeStoreId,
    setSelectedCompanyId, handleSubmit, setSelectedStoreId, stores, setSelectedCompany, selectedDate, handleDateChange, 
    managerSelectedId, setSelectedManagerId, managers, setSettings, settings } = props
    const user = useAuthStore((state) => state.user)


    // useEffect(() => 
    //     setDefinitions(Object.keys(settings).reduce((acc, item) => ({...acc, [item]: settings[item].value}), {}))
    // ,[settings])

    return (
        <>
        {(user?.is_admin || user?.is_counting_manager) && (
        <div>
            <Card m={2}>
                <CardHeader>
                    <Heading size='md'> Add Counting </Heading>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <HStack spacing={4} alignItems='flex-end'>
                            <VStack>
                                <FormLabel>Company</FormLabel>
                                <SelectCountingAdd
                                    handleChange={handleChangeCompany}
                                    selectedId={selectedCompanyId}
                                    items={companies}
                                    placeholder={'Select company'}
                                />
                            </VStack>
                            <VStack>
                                <FormLabel>Shop</FormLabel>
                                <SelectCountingAdd
                                    handleChange={handleChangeStoreId}
                                    selectedId={selectedStoreId}
                                    items={stores}
                                    placeholder={'Select Shop'}
                                />
                            </VStack>
                            <VStack>
                                <FormLabel>Date</FormLabel>
                                <CountingAddDate selectedDate={selectedDate} handleDateChange={handleDateChange}/>
                            </VStack>
                            <VStack>
                                <FormLabel>Settings</FormLabel>
                                <HStack>
                                    {
                                        Object.keys(settings)
                                            .filter(item => settings[item].value)
                                            .map((item, index) => 
                                                <Tag size='lg' colorScheme='blue' key={index}>
                                                    <TagLabel>{settings[item].name}</TagLabel>
                                                    <TagCloseButton onClick={() => setSettings(prevState => 
                                                        ({...prevState, [item]: { ...prevState[item], value: false} })
                                                        )} />
                                                </Tag>
                                            )
                                    }
                                    <Select placeholder='Select Setting' onChange={(e) => {
                                            const v = e.target.value
                                            if (v) {
                                                setSettings(prevState => 
                                                    ({...prevState, [v]: {...prevState[v], value: !prevState[v].value}}))
                                                e.target && (e.target.value = '')
                                            }
                                        }}>
                                        {Object.keys(settings)
                                            .filter(item => !settings[item].value)  
                                            .map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{settings[item].name}</option>
                                            )
                                        })}
                                    </Select>
                                </HStack>
                            </VStack>
                            <VStack>
                                <FormLabel>Manager</FormLabel>
                                <Select
                                    placeholder='Select Manager'
                                    value={managerSelectedId}
                                    onChange={(e) => setSelectedManagerId(e.target.value)}
                                    //onChange={handleChangeManager}
                                    //value={selectedManagerId}
                                >
                                    <option value={''}>Select manager</option>
                                    {managers.map((manager) => <option key={manager._id} value={manager._id}>{manager.name} {manager.last_name}</option>)}    
                                </Select>
                            </VStack>
                            <Button type='submit'>Add</Button>
                        </HStack>
                    </form>
                </CardBody>
            </Card>
        </div>
        )}
        </>
    )
}

export default CountingAdd