import React, {useEffect, useState} from 'react'
import {CountingAPI} from '../../../apis/CountingAPI'
import { Card, CardBody} from '@chakra-ui/react'
import {RowsMissedTable} from './RowsMissedTable/RowsMissedTable'

const RowsMissed = (props) => {
    const {countingId} = props
    const [rowsMissed, setRowsMissed] = useState([])
    const [categories, setCategories] = useState([])
    const [rowsMissedTotal, setRowsMissedTotal] = useState(0)
    const [filters, setFilters] = useState({})
    const fetchMissingRows = async (countingId) => {
        // console.log('fetch missing rows', countingId)
        const resp = await CountingAPI.getMissingRows(countingId, false, filters)
        setRowsMissed(resp.missing_rows)
        setRowsMissedTotal(resp.missing_rows_total)
        !categories.length && setCategories(resp.missing_rows.reduce(
            (acc, {categoryName}) => !acc.length 
                ? ['', categoryName] 
                : (acc.includes(categoryName) ? acc :  [...acc, categoryName]), [])
        )
    }
    useEffect(() => {
        fetchMissingRows(countingId)
    }, [filters])
    return (

        <Card>
            <CardBody>
              <RowsMissedTable rowsMissed={rowsMissed} rowsMissedTotal={rowsMissedTotal} categories={categories} filters={filters} setFilters={setFilters}/>
            </CardBody>
        </Card>
    )
}

export default RowsMissed