import { useEffect } from 'react'
import {Checkbox, Input, Th} from '@chakra-ui/react'
import {useItemsEditedFilters} from '../../../../../../hooks/useItems/useItemsEditedFilters'

export const ItemsEditedFilters = (props) => {
    const {itemsEdited, setFilteredItems} = props
    const {
        filteredItems,
        setUpcQuery, setUpcExactMatch,
        setMktQuery, setMktExactMatch,
        setRowQuery, setRowExactMatch,
        setDeviceQuery, setDeviceExactMatch,
        setNameQuery, setNameExactMatch,
    } = useItemsEditedFilters(itemsEdited)
    useEffect(() => {
        setFilteredItems(filteredItems)
    }, [filteredItems])
    return (
        <>
            <Th>
                <Input placeholder='UPC' onChange={e => setUpcQuery(e.target.value)}/>
                <Checkbox onChange={e => setUpcExactMatch(e.target.checked)}></Checkbox>
            </Th>
            <Th>
                <Input placeholder='MKT' onChange={e => setMktQuery(e.target.value)}/>
                <Checkbox onChange={e => setMktExactMatch(e.target.checked)}></Checkbox>
            </Th>
            <Th>
                <Input placeholder='Row' onChange={e => setRowQuery(e.target.value)}/>
                <Checkbox onChange={e => setRowExactMatch(e.target.checked)}></Checkbox>
            </Th>
            <Th></Th>
            <Th>
                <Input placeholder='Device' onChange={e => setDeviceQuery(e.target.value)}/>
                <Checkbox onChange={e => setDeviceExactMatch(e.target.checked)}></Checkbox>
            </Th>
            <Th>
                <Input placeholder='Name' onChange={e => setNameQuery(e.target.value)}/>
                <Checkbox onChange={e => setNameExactMatch(e.target.checked)}></Checkbox>
            </Th>
            <Th></Th>
        </>
    )
}