import {useEffect, useState} from 'react'
import {CompanyAPI} from '../../apis/CompanyAPI'
import {CountingAPI} from '../../apis/CountingAPI'
import {UserAPI} from '../../apis/UserAPI'
import useAuthStore from '../../stores/authStore'

export const useCountings = () => {
    const [countings, setCountings] = useState([])
    const [companies, setCompanies] = useState([])
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [selectedStoreId, setSelectedStoreId] = useState('')
    const [, setSelectedCompany] = useState({_id: ''})
    const [stores, setStores] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10))
    const [managers, setManagers] = useState([])
    const [selectedCountingIds, setSelectedCountingIds] = useState([])
    const [isDeleting, setIsDeleting] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState([])
    const [selectedManagerId, setSelectedManagerId] = useState()
    const [settings, setSettings] = useState({
        customRowNames: { name: 'Custom Row Names', value: false },
        dropLeadingZero: { name: 'Drop Leading Zero', value: false },
    })
    

    const user = useAuthStore((state) => state.user)

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value)
    }




    const fetchCountings = async () => {
        
        // console.log('init countings')
        let resp = await CountingAPI.getAll(filters, user)
        // console.log(resp)
        setCountings(resp)
        // debugger
    }

    const fetchCompanies = async () => {
       
        // console.log('init companies')
        let resp = await CompanyAPI.getAll()
        setCompanies(resp.result)
        // console.log(resp)
    }

    const fetchManagers = async () => {
        // console.log('init managers')
        let resp = await UserAPI.getAll(user)
        if(resp.users) {
            setManagers(resp.users.filter(user => user.is_shift_manager === true))
        }
        // setCompanies(resp.result)
        // console.log(resp)
    }

    useEffect(() => {
        fetchCompanies()
        fetchManagers()
    }, [])

    useEffect(() => {
        
        fetchCountings()
    }, [filters])

    const handleChangeCompany = (event) => {
        // console.log(event.target.value)
        setSelectedCompanyId(event.target.value)
    }
    const handleChangeStoreId = (event) => {
        setSelectedStoreId(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        ////{store_id: storeId, counting: counting, date_start: date},
        await CompanyAPI.addCounting({
            store_id: selectedStoreId, 
            counting: {}, 
            date_start: selectedDate, 
            manager_id: selectedManagerId, 
            settings: Object.keys(settings).reduce((acc, item) => ({...acc, [item]: settings[item].value}), {}) })
        fetchCountings()
    }

    useEffect(() => {
        if (selectedCompanyId !== '') {
            const company = companies.find(company => company._id === selectedCompanyId)
            setSelectedCompany(company)
            setStores(company.stores)
            // console.log(company)
        }
    }, [selectedCompanyId])

    const handleSelectCounting = (countingId) => {
        if (selectedCountingIds.includes(countingId)) {
            setSelectedCountingIds(selectedCountingIds.filter(id => id !== countingId))
        } else {
            setSelectedCountingIds([...selectedCountingIds, countingId])
        }
    }

    // useEffect(() => {
    //     console.log('selectedCountingIds', selectedCountingIds)
    // }, [selectedCountingIds])

    const handleDeleteCountings = async () => {
        if (window.confirm('Are you sure you want to delete this countings?')) {
            setIsDeleting(true)
            try {
                for (const countingId of selectedCountingIds) {
                    await CountingAPI.deleteCounting(countingId)
                }
                setSelectedCountingIds([])
                await fetchCountings()
            } finally {
                setIsDeleting(false)
            }
        }
    }

    const handlePageChange = (selectedPage) => {
        getCountings(selectedPage)
        setCurrentPage(selectedPage)
    }

    const getCountings = async (newFilters) => {
        setFilters(oldFilters => ({...oldFilters, ...newFilters}))
    }

    return {
        countings,
        companies,
        fetchCountings,
        selectedCompanyId,
        selectedStoreId,
        handleChangeCompany,
        handleChangeStoreId,
        stores,
        handleSubmit,
        setSelectedStoreId,
        setSelectedCompanyId,
        setSelectedCompany,
        selectedDate,
        handleDateChange,
        managers,
        handleSelectCounting,
        handleDeleteCountings,
        isDeleting,
        currentPage,
        handlePageChange,
        filters,
        setFilters,
        selectedManagerId,
        setSelectedManagerId,
        settings,
        setSettings
    }
}