import {Button} from '@chakra-ui/react'

export const ItemsInvalidCheckInCatalog = (props) => {
	const {checkAllSelected, isChecking} = props
	return (
		<>
			<Button size={'sm'} isLoading={isChecking}
					loadingText='Checking...' onClick={checkAllSelected} colorScheme={'purple'}>Check</Button>
		</>
	)
}