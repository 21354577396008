import {Card, CardBody, Spinner, Table, TableCaption, TableContainer, Tbody, Thead, Checkbox, Stack } from '@chakra-ui/react'
import { ItemsCountedTableRow } from './ItemsCountedTable/ItemsCountedTableRow'
import {ItemsCountedTableHeader} from './ItemsCountedTable/ItemsCountedTableHeader/ItemsCountedTableHeader'
import Pagination from 'components/Pagination/Pagination'
import { useState, useContext } from 'react'
import { Captions } from 'components/Counting/Captions'
import { CountingTabsContext } from 'components/Counting/CountingTabs/CountingTabs'
import {ItemsCountedTable} from './ItemsCountedTable/ItemsCountedTable'
import {useItemsCounted} from '../../../../hooks/useItems/useItemsCounted'

export const ItemsCounted = (props) => {
	const { countingId, refreshCounting } = props
	const {itemsCounted, getItemsCounted, toggleSortOrder, sortConfig, loading, filters}
		= useItemsCounted({counting_id: countingId})


	const [currentPage, setCurrentPage] = useState(1)
	const { counting } = useContext(CountingTabsContext)


    const handlePageChange = (selectedPage) => {
        getItemsCounted(selectedPage)
        setCurrentPage(selectedPage)
    }

    const refreshTable = () => {
        getItemsCounted({ page: currentPage })
        refreshCounting()
    }



	return (
		<Card>
			<CardBody>

			<TableContainer>
            <Table>
                <TableCaption placement={'top'}>
                    <Captions
                        total={itemsCounted.total}
                        totalAmount={itemsCounted.total_amount}
                        totalRows={itemsCounted.total_rows}
                        countingId={countingId}
                        counting={counting}
                        group_items={filters.group_items}
                    />
					<Stack direction='row' spacing={4}>
						<Checkbox onChange={(e) => getItemsCounted({group_items: e.target.checked})}>Grouped Items</Checkbox>
					</Stack>
                </TableCaption>
                <Thead>
                    <ItemsCountedTableHeader
                        toggleSortOrder={toggleSortOrder}
                        sortConfig={sortConfig}
						group_items={filters.group_items}
                        handleFilterChange={(newFilters) => {
							getItemsCounted(newFilters)
						}}
                        countingId={countingId}
                        counting={counting}
                    />
                </Thead>
            
                <Tbody>
                    { itemsCounted.data && ( 
                        itemsCounted.data.map(item => (
                            <ItemsCountedTableRow
                                key={item._id}
                                id={item._id}
                                upc={item.upc}
                                device={item.device_number}
                                row={item.row_name}
                                category={itemsCounted.rows[item.row_name]?.category_row?.name}
                                description={item.description}
                                customRowName={itemsCounted.rows[item.row_name]?.custom_row_name}
                                mkt={item.mkt_1}
                                amount={item.amount}
                                length={item?.mkt_1_length}
                                time={item.scanned_at ? item.scanned_at : item.created_at}
                                name={`${item?.user?.name} ${item?.user?.last_name}`}
                                lastName={`${item?.user?.last_name}`}
                                isFound={item.is_found}
                                isManual={item.is_manual}
                                refreshTable={refreshTable}
                                refreshCounting={refreshCounting}
                                counting={counting}
                                deletedAt={item.deleted_at}
                                group_items={filters.group_items}
                            />
                        )))}
                </Tbody>
            
            </Table> 
            {!loading  || <Spinner /> } <Pagination lastPage={itemsCounted.last_page ?? 1} getData={handlePageChange} />
        </TableContainer>
				{/* <ItemsCountedTable
					itemsCounted={itemsCounted}
					getItemsCounted={getItemsCounted}
					toggleSortOrder={toggleSortOrder}
					sortConfig={sortConfig}
					loading={loading}
					refreshCounting={refreshCounting}
					countingId={countingId}
					counting={counting}
				/> */}
			</CardBody>
		</Card>
	)
}