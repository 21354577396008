import { ArrowUpDownIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'

export const ItemsCountedHeaderSortable = ({ fieldName, title, toggleSortOrder, sortConfig }) => {
	const isSorted = sortConfig.sortField === fieldName

	return (
		<Box onClick={() => toggleSortOrder(fieldName)} style={{cursor: 'pointer'}}>
			{title}
			{isSorted ? (
				sortConfig.sortOrder === 'asc' ? <ChevronDownIcon boxSize={4}/> : <ChevronUpIcon boxSize={4}/>
			) : <ArrowUpDownIcon boxSize={4} />}
		</Box>
	)
}
