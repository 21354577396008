import { Button } from '@chakra-ui/react'

export const ItemsEditedActionButtons = (props) => {
	const {isEditing, onEdit, onSave} = props
	return (
		<>
			{isEditing ? (
				<Button size='sm' colorScheme='green' onClick={onSave} mr={1}>
					Save
				</Button>
			) : (
				<Button size='sm' colorScheme='blue' onClick={onEdit} mr={1}>
					Edit
				</Button>
			)}
		</>
	)
}
