import {Stack, Table, TableCaption, TableContainer, Tag, Tbody, Th, Thead, Tr, Td,  Input, Select, AccordionIcon} from '@chakra-ui/react'
import {RowsMissedRow} from './RowsMissedRow/RowsMissedRow'
import {Captions} from '../../Captions'
import { useState, useMemo } from 'react'


const Filters = ({filtersConfig = [], filters, setFilters }) => { 
    
    return (
        <Tr>
            <Th></Th>
            { filtersConfig.map((filter, idx) =>
                <Th key={idx}>
                    {
					filter.options?.length 
						? <Select
							id={`filter_${filter.type}`} 
							placeholder={filter.placeholder}
							onChange={(e) => setFilters({...filters, [filter.type]: e.target.value || undefined })}
							value={filters ? (filters[filter.type] ?? '') : null}
						>
							{
								filter.options.map((option, idx) =><option key={idx} value={option}>{option}</option>)
							}
						</Select>
						: 
						<Input  
							id={`filter_${filter.type}`} 
							placeholder={filter.placeholder} 
							onChange={(e) => setFilters({...filters, [filter.type]: e.target.value || undefined })} 
							value={filters ? (filters[filter.type] ?? '') : null}
							type={filter.type.startsWith('date') ? 'date' : 'text'}
						/>
					}
                </Th>
            )}
        </Tr>
    )
}

export const RowsMissedTable = ({rowsMissed, rowsMissedTotal, filters, setFilters, categories}) => {
	
	return (
		<TableContainer>
			<Table>
				<TableCaption placement={'top'}>
					<Captions total={rowsMissedTotal} />
					{/* <Stack direction='row' spacing={4}>
						<Tag colorScheme='blue'>Total: {rowsMissedTotal}</Tag>
					</Stack> */}
				</TableCaption>
				<Thead>
					<Filters filtersConfig={[
						{ type: 'category_name', placeholder: 'Category',  options: categories}
					]} 
						filters={ filters } setFilters={ setFilters }
					/>
					<Tr><Th>Row</Th><Th>Category</Th></Tr>
				</Thead>
				<Tbody>
					{rowsMissed.map(({ rowName, categoryName}, idx) => 
						<Tr key={idx} >
							<Td>{rowName}</Td><Td>{categoryName}</Td>
						</Tr>
					)}
				</Tbody>

			</Table>
		</TableContainer>
	)
}
